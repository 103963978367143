import React, {useState, useEffect} from "react";
import ChooseFile from "components/ChooseFile";
import FileName from "components/FileName";
import CustomButton from "admin/administrator/CustomButton";
import UploadedProductsFileModal from "components/UploadedProductsFileModal";
import "components/ChooseFileAndUpload.css"
import alertify from "alertifyjs";
import XLSX from 'xlsx';
import axios from "axios1";
import {GridLoader} from 'react-spinners';


const ChooseFileAndUpload = (props) => {
    const [fileName, setFileName] = useState("No File Selected");
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [updatedData, setUpdatedData] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [currentProducts, setCurrentProducts] = useState([]);
    const [currentPage, setCurrentPage] = useState(null);
    const [totalPages, setTotalPages] = useState(null);
    const [counter, setCounter] = useState(0);
    const [brands, setBrands] = useState([]);
    const [partNumbers, setPartNumbers] = useState([]);
    const [priceLevelValueTypeId, setPriceLevelValueTypeId] = useState(1);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        redrawPagination(filteredData.length, currentPage);
        console.log(fileName);
    }, [counter]);

    useEffect(() => {
        redrawPagination(filteredData.length, currentPage);
    }, [priceLevelValueTypeId]);


    useEffect(() => {
        redrawPagination(filteredData.length, currentPage);
    }, [filteredData])

    function redrawPagination(totalRecords, currentPage, pageLimit = 10) {
        const paginationData = {
            currentPage: currentPage,
            pageLimit: pageLimit,
            totalRecords: totalRecords
        };
        onPageChanged(paginationData);
    }

    function onPageChanged(data) {
        const {currentPage, totalPages, pageLimit} = data;
        const offset = (currentPage - 1) * pageLimit;
        let currentProducts = filteredData.slice(offset, offset + pageLimit);

        if (priceLevelValueTypeId == 2) {
            currentProducts = currentProducts.map((product) => {
                product[2] = product[1] * (1 + (counter / 100)).toFixed(2);
                return product;
            });
        } else {
            currentProducts = currentProducts.map((product) => {
                product[2] = product[1] / (1 - (counter / 100)).toFixed(2);
                return product;
            });
        }
        setCurrentProducts(currentProducts);
        setCurrentPage(currentPage);
        setTotalPages(totalPages);
    };

    function closeModal() {
        setShowModal(false);
        setFilteredData(data);
        setBrands([]);
        setPriceLevelValueTypeId(1);
    }

    function openModal() {
        if (data.length > 0) {
            if (data[0].length > 3) {
                alertify.error("Incorrect file format");
                return;
            }
            setShowModal(true);
        } else {
            alertify.error("Choose file");
        }
    }

    function handleFile(e) {
        let files = e.target.files, f = files[0];
        if (f !== undefined) {
            let regex = new RegExp("(.*?)\.(xls|xlsx|csv)$");
            let reader = new FileReader();
            if (!(regex.test(f.name.toLowerCase()))) {
                alertify.error("Supported only (xls, xlsx, csv) type files!");
                return;
            }
            reader.onload = (e) => {
                let result = new Uint8Array(e.target.result);
                let workbook = XLSX.read(result, {type: 'array'});
                let sheetNames = workbook.SheetNames[0];
                let sheets = workbook.Sheets[sheetNames];
                let products = XLSX.utils.sheet_to_json(sheets, {header: 1});
                products.splice(0, 1);
                setData(products);
                setFilteredData(products);
                setFileName(f.name);
            };
            reader.readAsArrayBuffer(f);
        }
    }

    function increaseCounter() {
        let c = parseInt(counter) + 1;
        if (c > 200) c = 200;
        setCounter(c);
    }

    function saveProducts() {
        props.setProducts(currentProducts);
        props.setInitialProducts(data);
        props.setPercentage(counter);
        props.setFileName(fileName);
        props.setPriceLevelValueTypeId(priceLevelValueTypeId);
        setBrands([]);
        closeModal();
    }

    function checkPartNumberExistence() {
        setLoading(true);
        axios.post("catalogue/suppliers/check-part-number-existence", {
            'params': {'products': JSON.stringify(data)}
        }).then((response) => {
            setLoading(false);

            if (response.data.brands.length > 0) {
                setBrands(response.data.brands);
                setPartNumbers(response.data.partNumbers);
            } else {
                saveProducts();
            }
        });
    }

    function sayYes() {
        saveProducts();
    }

    function sayNo() {
        removeExistedPartNumbers(partNumbers);
    }

    function removeExistedPartNumbers(pn) {
        let dataWithoutExistedPartNumbers = currentProducts.filter(function (el) {
            return pn.indexOf(el[0]) < 0;
        });
        props.setProducts(dataWithoutExistedPartNumbers);
        props.setInitialProducts(dataWithoutExistedPartNumbers);
        props.setPriceLevelValueTypeId(priceLevelValueTypeId);
        setBrands([]);
        closeModal();
    }

    function changeCounter(e) {
        setCounter(e.target.value);
    }

    function handleFilterProducts(e) {
        let filteredProducts = data.filter((product) => {
            return (product[0].toString().toLowerCase().indexOf(e.target.value.toLowerCase()) > -1) ? product : null;
        });
        
        setFilteredData(filteredProducts);
        redrawPagination(filteredProducts.length, currentPage);
    }

    function handleSwitchChange(e) {
        setPriceLevelValueTypeId(e.target.value);
    }

    return (
        <div style={{display: 'flex', alignItems: 'center', marginBottom: '20px', justifyContent: 'space-between'}}>
            <ChooseFile handleFile={handleFile}/>
            <FileName fileName={fileName}/>
            <CustomButton title={"Upload"} type={"blue"} onClick={openModal}/>
            {
                showModal &&
                    <UploadedProductsFileModal showModal={showModal}
                        onPageChanged={onPageChanged}
                        currentProducts={currentProducts}
                        closeModal={closeModal}
                        increaseCounter={increaseCounter}
                        changeCounter={changeCounter}
                        handleFilterProducts={handleFilterProducts}
                        saveProducts={saveProducts}
                        counter={counter}
                        totalProducts={filteredData.length}
                        supplier={props.supplier}
                        checkPartNumberExistence={checkPartNumberExistence}
                        brands={brands}
                        partNumbers={partNumbers}
                        sayYes={sayYes}
                        sayNo={sayNo}
                        handleSwitchChange={handleSwitchChange}
                        priceLevelValueTypeId={priceLevelValueTypeId}/>
            }

        </div>
    )
}

export default ChooseFileAndUpload;