import React, {useState, Fragment} from "react";
import axios from "axios1";
import alertify from 'alertifyjs';
import "./styles/DeleteAccountModal.css";
import {GridLoader} from 'react-spinners';
import {withRouter} from 'react-router-dom';

import {Modal} from "react-bootstrap";

const DeleteAccountModal = props => {
    const [loading, setLoading] = useState(false);

    const deleteFunc = () => {
        setLoading(true);
        axios.post("catalogue/accounts/" + props.id ,{_method: 'DELETE'})
        .then((response) => {
            setLoading(false);
            if(response.data.success){
                alertify.success(response.data.message);
                props.history.push(`/admin/accounts`)
            }else{
                alertify.error("Error occured");
            }
        })
    }

    return (
        <Fragment>
            {
                loading &&
                    <div className='sweet-loading'>
                        <GridLoader
                            sizeUnit={"px"}
                            size={15}
                            color={'#0066b8'}
                            loading={loading}
                        />
                    </div>
            }
            <Modal
                show={props.show}
                {...props}
                size="sm"
                aria-labelledby="catalogueModal"
                onHide={() => props.setShowModal(false)}
                className="userCatalogueModal">
                <Modal.Header>
                    <Modal.Title id="catalogueModal">
                        <div
                            className={"dashboard-page-navigations modal-pref-p-nav user-preferences-header"}>
                            <span className="RedText"> Warning </span>
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="modalText">
                        Are you sure? <br/>
                        All data related to this account will be deleted from the system. This includes ALL history.
                    </div>
                    <hr></hr>
                    <Fragment>
                        <button className="btn btn-danger"
                                style={{float: 'right', margin: "0px 15px"}}
                                onClick={deleteFunc}>yes
                        </button>
                        <button className="btn  btn-success" style={{float: 'right'}}
                                onClick={props.setShowModal(false)}>no
                        </button>
                    </Fragment>
                </Modal.Body>
            </Modal>
        </Fragment>
    )
}

// class DeleteAccountModal extends Component {

//     constructor(props) {
//         super(props);
//         this.state={
//             loadig:false
//         }
//     }

//     modalClose() {
//         this.props.setShowModal(false);
//     }

//     Delete(){
//         this.setState({loading:true});
//         axios.post("catalogue/accounts/" + this.props.id ,{_method: 'DELETE'})
//         .then((response) => {
//             this.setState({loading:false});
//             if(response.data.success){
//                 alertify.success(response.data.message);
//                 this.props.history.push(`/admin/accounts`)
//             }else{
//                 alertify.error("Error occured");
//             }
//         })
//     }

//     render() {
//         return (
//             <React.Fragment>
//                 {
//                     this.state.loading ?
//                         <div className='sweet-loading'>
//                             <GridLoader
//                                 sizeUnit={"px"}
//                                 size={15}
//                                 color={'#0066b8'}
//                                 loading={this.state.loading}
//                             />
//                         </div> : null
//                 }
//                 <Modal
//                     show={this.props.show}
//                     {...this.props}
//                     size="sm"
//                     aria-labelledby="catalogueModal"
//                     onHide={this.modalClose.bind(this)}
//                     className="userCatalogueModal">
//                     <Modal.Header>
//                         <Modal.Title id="catalogueModal">
//                             <div
//                                 className={"dashboard-page-navigations modal-pref-p-nav user-preferences-header"}>
//                                 {/* <i className={"fas fa-user-plus"}></i> */}
//                                 <span className="RedText"> Warning </span>
//                             </div>
//                         </Modal.Title>
//                     </Modal.Header>
//                     <Modal.Body>
//                         <div className="modalText">
//                             Are you sure? <br/>
//                             All data related to this account will be deleted from the system. This includes ALL history.
//                         </div>
//                         <hr></hr>
//                         <React.Fragment>
//                             <button className="btn btn-danger"
//                                     style={{float: 'right', margin: "0px 15px"}}
//                                     onClick={this.Delete.bind(this)}>yes
//                             </button>
//                             <button className="btn  btn-success" style={{float: 'right'}}
//                                     onClick={this.modalClose.bind(this)}>no
//                             </button>
//                         </React.Fragment>
//                     </Modal.Body>
//                 </Modal>
//             </React.Fragment>
//         )
//     };
// }

export default withRouter(DeleteAccountModal);