import React, {Fragment, useState, useEffect} from 'react';
import "./MainSlide.css";

function AddSlide(props) {
    return (
        <div className="add-slide" onClick={() => props.openModal()}>
            <div>+ Add Slide</div>
            <div>At least 1250px wide</div>
        </div>
    )
}

export default AddSlide