import React, {Component} from "react";
import Viewer from 'viewerjs';
import 'viewerjs/dist/viewer.css';
import Slider from "react-slick";
import NoImageLg from "images/no-image-lg.png";
import NoImageRadial from "images/no-image-radial.png";

export default class Images extends Component {
    constructor(props) {
        super(props);
    }

    addDefaultSrc(event) {
        event.target.removeAttribute("src");
    }

    handleImageClick(click) {
        let currentElementIndex = click.target.closest(".slick-slide").dataset.index;
        const gallery = new Viewer(document.getElementById('images'), {
            backdrop: true,
            title: false,
            zIndex: 100000,
            initialViewIndex: currentElementIndex,
            hidden: function () {
                gallery.destroy();
            }
        });
        gallery.show();
    }

    render() {
        let product = this.props.product;
        let settings = {
            dots: true,
            infinite: false,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
            draggable: false
        };
        return (
            <div className="imgs" id="images">
                {
                    product?.part_number?.allImages.length < 1 ?
                        <div className={"no-image"}>
                            <img src={NoImageLg}/>
                        </div> : null
                }
                <Slider {...settings}>
                    {
                        product?.part_number?.allImages.map((image, index) => {
                            return <div>
                                        <img key={index}
                                            className="image imagePreview"
                                            src={image.url}
                                            onError={(e) => {
                                                e.target.onerror = null;
                                                e.target.src = NoImageRadial
                                            }}
                                            width={"100%"}
                                            onClick={this.handleImageClick}/>
                                    </div>
                        })
                    }
                </Slider> 
            </div>
        )
    }
}