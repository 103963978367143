import React, { Component } from "react";
import Menu from "account/user/Menu";
import changePassword from "account/user/ChangePassword";
import "account/index.css";
import Statistics from "admin/administrator/Statistics";
import OrdersDetails from "admin/administrator/OrdersDetails";
import AbandonedCartsDetails from "admin/administrator/AbandonedCartsDetails";
import OrderStatusDetails from "admin/administrator/vehicleManufacturerLookups/OrderStatusDetails";
import Lookups from "admin/administrator/Lookups";
import MyOrders from "account/Orders/MyOrders/MyOrders";
import Orders from "account/Orders/Orders/Orders";
import CustomerOrders from "account/Orders/CustomerOrders/CustomerOrders";
import { Route } from "react-router-dom";

export default class Index extends Component
{
    constructor(props)
    {
        super(props);

    }

    render(){
        return (
            <div className={"admin-routes-wrapper"}>
                <div className={"container"}>
                    <div className={"row admin-dashboard"}></div>
                    <Route path="/account" exact component={Menu} />
                    <Route path="/account/changePassword" exact component={changePassword} />
                    <Route path="/account/statistics/:user" exact component={Statistics} />
                    <Route path="/account/ordersDetails" component={OrdersDetails} />
                    <Route path="/account/abandonedCartsDetails" component={AbandonedCartsDetails} />
                    <Route path="/account/Lookups" component={Lookups} />
                    <Route path="/account/my-orders" component={Orders} />
                    <Route path="/account/customer-orders" component={CustomerOrders} />
                    <Route path="/account/orders" component={Orders} />
                    <Route path="/account/order-status-details/:order_id" component={OrderStatusDetails}/>
                </div>
            </div>
        );
    }
}