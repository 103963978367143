import React, {Fragment, useState, useEffect} from 'react';
import "./SmallSlide.css";
import CustomButton from "admin/administrator/CustomButton";
import UploadModal from "admin/administrator/ImageSlider/UploadModal";
import FormInput from "admin/administrator/FormInput/FormInput.component";
import MessageBox from "components/MessageBox";
import CameraImg from "images/camera.png";
import Loading from "admin/administrator/LoadingComponent/Loading.component";
import axios from "axios1";
import _ from "lodash";
import alertify from 'alertifyjs';
import trashIcon from "images/trash.png";

function SmallSlide(props) {
    const [showModal, setShowModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [messages, setMessages] = useState({
        success: {
            type: "success",
            text: "Published successfully!",
            visible: false
        },
        warning: {
            type: "warning",
            text: "You have unpublished changes!",
            visible: false
        }
    });
    const [images, setImages] = useState([]);
    const [address, setAddress] = useState(null);

    useEffect(() => {
        getSmallSlides(props.position);
    }, []);

    function getSmallSlides(position) {
        axios.get("catalogue/small-slides/" + position)
            .then((response) => {
                if(response.data.length > 0) {
                    setImages(response.data);
                    setAddress(response.data[0].address);
                }
            })
    }

    function openModal() {
        setShowModal(true);
    }

    function closeModal() {
        setShowModal(false);
    }

    function setImagePreview(files) {
        setImages(files);
        showMessageBox("warning");
    }

    function showMessageBox(type) {
        let msgs = {...messages};
        Object.keys(msgs).map((key) => {
            msgs[key].visible = false;
        });
        msgs[type].visible = true;
        setMessages(msgs);
    }

    function publishImage() {
        setLoading(true);
        let imgs = [...images];
        let addr = address;
        if(addr) {
            console.log(addr);
            if(addr.substring(0, 11) !== 'http://www.' && addr.substring(0, 12) !== 'https://www.') {
                addr = "http://www." + addr + '/';
            }
        }
        axios.post("catalogue/small-slides", {images: imgs, address: addr, position: props.position})
        .then((response) => {
            setLoading(false);
            showMessageBox('success');
        });
    }

    function handleAddressChange(e) {
        setAddress(e.target.value);
        showMessageBox('warning');
    }

    function deleteSlide() {
        setImages([]);
        showMessageBox('warning');
    }

    return (
        <Fragment>
            <Loading loading={loading} />
            <UploadModal 
                showModal={showModal} 
                closeModal={() => closeModal()}
                multiple={false}
                setImagePreview={setImagePreview.bind(this)}
            />
            <div className="box">
                {
                    Object.keys(messages).map((key) => {
                        if(messages[key].visible == true) {
                            return <div className="box-message">
                                        <MessageBox type={messages[key].type} text={messages[key].text}/>
                                    </div>
                        }
                    })
                }
                <div className="box__header">
                    <h3>Bottom {props.position} Image</h3>
                    <CustomButton type={"blue"} title={"Publish"} onClick={publishImage}/>
                </div>
                <div className="box__body">
                    <div className="upload-img">
                        {
                            images.length > 0 && images[0].imagePreview != "" ?
                                <div className="img" style={{backgroundImage: "url('"+((images[0].id == undefined) ? images[0].imagePreview : images[0].image)+"')"}}>
                                    <div className="slide-trash" onClick={() => deleteSlide()}>
                                        <img src={trashIcon}/>
                                    </div>
                                </div> : 
                                <div className="img initial"></div>
                        }
                        <button onClick={() => openModal()}>
                            <img src={CameraImg}/>
                            <span>Upload Image</span>
                        </button>
                    </div>
                    <div className="site-address">
                        <span>Site Address (URL)</span>
                        <FormInput
                            type='text'
                            value={address}
                            placeholder={'http://'}
                            onChange={handleAddressChange.bind(this)}
                        />
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default SmallSlide
