import React from "react";
import 'components/AltProducts.css';
import AddToCartBtn from "components/AddToCartBtn";

const AltProducts = props => {
    const generateStockQuantity = stock => {
        let stockVisibility = props.stockVisibility == "quantity" ? true : false;
        return stockVisibility ? stock.quantity : (stock.quantity > 0 ? "In Stock" : "Out of Stock"); 
    }
    return (
        <div className="table mh-270">
            <table className="table__table">
                <thead className="table__thead">
                    <tr className="table__tr">
                        <th className="table__th textLeft">{props.title}</th>
                        {
                            props.stockVisibility != "hidden" && <th className="table__th">Quantity</th>
                        }
                        {
                            (props.settings.enable_shopping_cart && props.title !== "Supplier" ) && <th className="table__th">Action</th>
                        }
                    </tr>
                </thead>
                <tbody className="table__tbody">
                {
                    props.stocks.map((stock, key) => {
                        return <tr key={key} className="table__tr">
                            <td className="table__td textLeft" data-attr={props.title}>
                                <div className={"full-info-account"}>
                                    <div>{stock.account}</div>
                                    {
                                        stock.lastUpdate &&
                                        <div className={"last-supplier-update"}><i class="fas fa-exclamation-triangle"></i> 
                                            <p>
                                                {stock.lastUpdate !== 'Live Stock' ? 'Last Updated:' : ''} {stock.lastUpdate}
                                            </p>
                                        </div>
                                    }
                                    </div>
                            </td>
                            {
                                props.stockVisibility != "hidden" &&
                                    <td className="table__td" data-attr="Quantity">{stock.quantity === null ? (props.stockLoad ? generateStockQuantity(stock) : <div
                                        className={"products-list-loading withLoading loading"}></div>) : generateStockQuantity(stock)}</td>
                            }
                            {
                                props.title !== "Supplier" && (
                                    props.settings.enable_shopping_cart ? (
                                        stock.price ? (
                                            stock.quantity === null ? '' : (
                                                <td className="table__td table__td--center">
                                                    <AddToCartBtn lookup={props.lookup}
                                                                    product={props.product}
                                                                    alternatives={0}
                                                                    section_lookup_id={props.section_lookup_id}
                                                                    stock={stock}
                                                                    disabled={props.defaultStock}
                                                    />
                                                </td>
                                            )
                                        ) : <td className="table__td"></td>
                                    ) : <td className="table__td"></td>
                                )
                            }
                        </tr>
                    })
                }
                </tbody>
            </table>
        </div>
    );
}

export default AltProducts;