import React, {useState, useEffect} from "react";
import axios from "axios1";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import alertify from 'alertifyjs';
import _ from 'lodash';
import Loading from "admin/administrator/LoadingComponent/Loading.component";
import { Fragment } from "react";

function AdvancedSearchMenu(props) {

    const makeOptions = {
        make: {
            name: "Make",
            value: "",
            options: [],
            disabled: true
        },
        model_range: {
            name: "Model Range",
            value: "",
            options: [],
            disabled: true
        },
        bodyStyle: {
            name: "BodyStyle",
            value: "",
            options: [],
            disabled: true
        },
        fuel: {
            name: "Fuel",
            value: "",
            options: [],
            disabled: true
        },
        engine: {
            name: "Engine",
            value: "",
            options: [],
            disabled: true
        },
        model: {
            name: "Model",
            value: "",
            options: [],
            disabled: true
        },
        hp: {
            name: "HP",
            value: "",
            options: [],
            disabled: true
        },
        year: {
            name: "Year",
            value: "",
            options: [],
            disabled: true
        }
    };
    const [loading, setLoading] = useState(false);
    const [gridLoader, setGridLoader] = useState(false);
    const [select, setSelect] = useState(makeOptions);
    const [vehicleIds, setVehicleIds] = useState([]);
    const [enabledBtn, setEnabledBtn] = useState(false);
    const [vehicleName, setVehicleName] = useState("");
    
    useEffect(() => {
        const items = props.searchResult.makeOptions ?? select;
        if(_.filter( items, item => { return item.disabled === false && item.value !== '' }).length > 2)
            getVehiclesRequest(items);
        else
            getMakes();
    }, []);

    useEffect(() => {
        let updateSelect = {...select};
        if(props.clearVehicle) {
            Object.keys(updateSelect).map((key) => {
                // updateSelect['make'].disabled = true;
                updateSelect[key].value = "";
                updateSelect[key].disabled = true;
                if(key !== 'make') {
                    updateSelect[key].options = [];
                }else if(!props.searchResult.nonAutoActive || (props.settings.search_types && props.settings.search_types.split(",").length === 1)) {
                    updateSelect[key].disabled = false;
                }

                setEnabledBtn(false);
            })
        }else {
            updateSelect['make'].disabled = false;
        }
        setSelect(updateSelect);
    }, [props.clearVehicle]);

    useEffect(() => {
        let newSelect = {...select};
        _.map(newSelect, item => {
            if(props.nonAutoActive) {
                item.disabled = true;
            }else {
                item.disabled = false;
            }
        })
        newSelect.make.disabled = props.nonAutoActive ?  (props.settings.search_types && props.settings.search_types.split(",").length > 1 ? true : false) : !props.enable_vehicle_selection;
        setSelect(newSelect);
    }, [props.enable_vehicle_selection, props.nonAutoActive]);
    
    function getMakes() {
        setLoading(true);
        axios.get("/catalogue/vehicles/makes")
            .then((response) => {
                if(response && response.data) {
                    let makes = response.data.map((make) => {
                        return make.Make;
                    });
                    select.make.options = makes;
                    props.saveSearchResult('makeOptions', select)
                    setLoading(false);
                }
            })
    }

    function getVehicles(e, name, index) {
        select[name].value = e.target.value;
        if(select['make'].value == "") {
            alertify.error("please, select at least a make");
            return;
        }
        setLoading(true);
        setEnabledBtn(false);
        let newSelect = nextSelectsEmpty(index);
        getVehiclesRequest(newSelect);
    }

    function getVehiclesRequest(newSelect) {
        axios.post("/catalogue/get-vehicles", { data: newSelect })
            .then((response) => { 
                // let newSelect = {...select};
                let options = response.data.data;
                Object.keys(options).map((key) => {
                    let optionsSorted = options[key].sort();
                    newSelect[key].options = optionsSorted;
                });
                setSelect(newSelect);
                setVehicleIds(response.data.vehicles);
                setLoading(false);
                countVehicles(response.data.vehiclesCount);
                enableButtonAndSelectors(response.data.vehiclesCount, newSelect);
                props.setClearVehicle(false);
                props.setSelectedAtLeastOne(true);
            });
    }

    function nextSelectsEmpty(index) {
        let newSelect = {...select};
        Object.keys(newSelect).map((key, i) => {
            if(i > index) {
                newSelect[key].disabled = true; 
                newSelect[key].value = "";
            }
            
            if( !(newSelect.length > (index + 1)) && i == index + 1) {
               newSelect[key].disabled = false;
               newSelect[key].value = ""; 
            }            
        });
        setSelect(newSelect);
        return newSelect;
    }

    function countVehicles(count) {
        props.setVehiclesCount(count);
    }

    function showResults() {
        if(select['make'].value == "") {
            alertify.error("please, select at least a make");
            return;
        }
        setGridLoader(true);
        axios.post("catalogue/search/by-vehicle", {vehicleIds})
            .then((response) => {
                if(response.data.success) {
                    let groups = response.data.groups;
                    let newSelect = {...select};
                    makeHpLastElementOfVehicleName(newSelect);
                    let vN = Object.keys(newSelect).map((key) => {
                        let value = newSelect[key].value;
                        if(key == "year" && value != "") {
                            if(newSelect[key].options[0].length == 0) {
                                value = newSelect[key].value; 
                            } else {
                                value = newSelect[key].options[0] + " - " + newSelect[key].options.slice(-1)[0];
                            }
                        }
                        if(key == "hp" && value != "") {
                            value = newSelect[key].value + "hp";
                        }
                        return value + " ";
                    });
                    
                    if (!Object.keys(groups).length) {
                        alertify.error("products not found");
                    }
                    setVehicleName(vN);
                    props.setClearRegNumber(true);
                    props.setClearPartNumber(true);
                    props.setClearVinNumber(true);
                    props.saveSearchResult("vehicleName", vN);
                    workOnResponse(groups, response.data.lookup_id);
                } else {
                    alertify.error("An error accured");
                }
                setGridLoader(false);
            });
    }

    function makeHpLastElementOfVehicleName(select) {
        let hp = select['hp'];
        delete select['hp'];
        select['hp'] = hp;
    }

    function workOnResponse(groups, lookup_id) { 
        props.saveSearchResult("products", []);
        props.saveSearchResult("sections", []);
        props.saveSearchResult("groups", groups);
        props.saveSearchResult("lookup_id", lookup_id);
        props.saveSearchResult("selectedGroups", []);
        props.saveSearchResult("selectedSections", []);
        props.saveSearchResult("selectedBrands", []);
        props.saveSearchResult("filteredProducts", []);
        props.saveSearchResult("productsQuantity", 0);
        props.saveSearchResult("showProducts", false);
        props.saveSearchResult("hideForPartNumber", false);
        props.saveSearchResult("selectedAxlesSection", []);
    }

    function enableButtonAndSelectors(vehiclesCount, newSelect) {
        let valuesArr = [];
        Object.keys(newSelect).map((key, index) => {
            if(newSelect[key].value != "" && index < 5) {
                valuesArr.push(true);
            }
        });
        if(valuesArr.every(value => value === true) && (valuesArr.length > 4 || vehiclesCount == 1) && vehiclesCount > 0) {

            if(vehiclesCount === 1) {
                newSelect['bodyStyle'].disabled = false;
                newSelect['fuel'].disabled = false;
                newSelect['engine'].disabled = false;
            }

            newSelect['model'].disabled = false;
            newSelect['hp'].disabled = false;
            newSelect['year'].disabled = false;
            setEnabledBtn(true);
            setSelect(newSelect);
        }
    }

    return (
        <Fragment>
            <Loading loading={gridLoader}/>
            <div className="vehicle-selectors">
                {   
                    Object.keys(select).map((key, index) => {
                        return (
                            <div className="selector vehicle-selectors__selector ">
                                <select value={select[key].value} 
                                        selected={select[key].value}
                                        disabled={ (!props.enable_vehicle_selection || select[key].disabled || select[key].options.length == 0 ) ? true : false }
                                        onChange={(e) => getVehicles(e, key, index)} 
                                        className={"selector__selector " + ((!props.enable_vehicle_selection || select[key].disabled || select[key].options.length == 0 ) ? ' selector__selector--disabled' : '')}>
                                    <option key={key} value={""}>{select[key].name}</option>
                                    {
                                        select[key].options.map((option) => {
                                            return <option key={option} value={option}>{option}</option>;
                                        })
                                    }
                                </select>
                            </div>
                        );
                    })
                }
                <button onClick={showResults} disabled={!enabledBtn} className={"vehicle-selectors__btn btn btn--primary " + (!enabledBtn || ( props.nonAutoActive && (props.settings.search_types && props.settings.search_types.split(",").length > 1)) ? 'btn--disabled' : '')}>Show Results</button>
            </div>
        </Fragment>
    );
}

const mapStateToProps = (state) => ({
    token: state.auth.token,
    options: state.products,
    searchResult: state.searchResult
})

const mapDispatchToProps = dispatch => {
    return {
        saveSelectedOptions: (options) => dispatch({type: 'SAVE_SELECTED_OPTIONS', payload: options}),
        saveSearchResult: (key, value) => dispatch({
            type: 'SAVE_SEARCH_RESULTS',
            payload: {key, value}
        })
    }
}

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(AdvancedSearchMenu));