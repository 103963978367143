import React from 'react';
import './FtpCredentials.css';

function FtpCredentials({credentials}) {
    return (
        <ul className={"ftp-credentials"}>
            {
                credentials.map((credential) => {
                    return(
                        <li key={credential.key}>
                            <span>{credential.key + ": "}</span>
                            <span>{credential.value}</span>
                        </li>
                    )
                })
            }
        </ul>
    )
}

FtpCredentials.defaultProps = {
    credentials: []
}

export default FtpCredentials;