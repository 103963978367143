import React, {Component} from "react";
import Notes from "components/FullInfo/Notes";
import Slider from 'react-slick';
import {clearCompareListElement} from "redux/actions/compareList";
import Viewer from 'viewerjs';
import Warranty from "components/FullInfo/Warranty";
import Price from "components/FullInfo/Price";
import AddToCartBtn from "components/AddToCartBtn";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import NoImageRadial from "images/no-image-radial.png";

class ComparedProduct extends Component {
    constructor(props) {
        super(props);
    }

    addDefaultSrc(event) {
        event.target.removeAttribute("src");
    }

    handleImageClick(click) {
        const viewer = new Viewer(click.target.parentNode, {
            backdrop: true,
            title: false,
            zIndex: 100000,
            hidden: function () {
                viewer.destroy();
            }
        });
        viewer.show();
    }

    render() {
        var settings = {
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: false,
            arrows: false,
            // adaptiveHeight: true
        };
        let product = this.props.product;
        return (
            <div className="compare__item" key={product.id}>
                <div className="compare__item-row">
                    <i class="fas fa-times compare__remove" onClick={() => this.props.clearCompareListElement(product)}></i>
                    {
                        !product.product.main_image && product.product.part_number.allImages.length < 1 ?
                            <div className={"no-image-found"}>No images available for the product</div> :
                            <Slider {...settings}>
                                {
                                    product.product.main_image ?
                                        <div className={"carusel-image image imagePreview"}><img
                                            key={product.product.main_image} onError={this.addDefaultSrc.bind(this)}
                                            onClick={this.handleImageClick} className="image imagePreview"
                                            src={product.product.main_image}></img></div> : null
                                }
                                {
                                    product.product.part_number.allImages.map((image, index) => {
                                        return <div className={"carusel-image image imagePreview"}>
                                                    <img key={product.id + index} 
                                                         className="image imagePreview" 
                                                         src={image.url} 
                                                         onError={(e) => {
                                                            e.target.onerror = null;
                                                            e.target.src = NoImageRadial
                                                        }}
                                                        onClick={this.handleImageClick}/>
                                                </div>
                                    })
                                }
                            </Slider>
                    }
                    <div className="text-center">
                        <div className="fw-700 fs-16 text-capitalize">{product.product.part_number.section.name}</div>
                        <div className="fs-12 mt-8">P.N. - {product.product.part_number.part_number}</div>
                    </div>
                    {
                        this.props.settings.enable_shopping_cart && product.product.quantity > 0 ?
                            <AddToCartBtn product={product.product} section_lookup_id={product.section_lookup_id}/> : null
                    }
                </div>
                {
                    (this.props.settings && this.props.settings.show_prices) ?
                        <div className="compare__item-row item-price">
                            <Price product={product.product} settings={this.props.settings} />
                        </div>
                    : null
                }
                <div className="compare__item-row compare__item-row--brand">
                    {
                        product.product.part_number.brand.image ?
                            <img src={product.product.part_number.brand.image} alt={product.product.part_number.brand.name}/> : product.product.part_number.brand.name
                    }
                </div>
                <div className="compare__item-row item-warranty">
                    <Warranty product={product.product}/>
                </div>
                <div className="compare__item-row compare__item-row--notes">
                    <Notes product={product}/>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        clearCompareListElement: (product) => dispatch(clearCompareListElement(product)),
    }
}

const mapStateToProps = (state) => ({
    // settings: state.settings,
})

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(ComparedProduct));
