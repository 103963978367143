import React, {useState, useEffect} from "react";
import Stock from "components/FullInfo/Stock";
import Images from "components/FullInfo/Images";
import _ from "lodash";
import {connect} from "react-redux";
import "components/FullInfo/Main.css";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

const Main = props => {
    const [product, setProduct] = useState([]);
    const [branches, setBranches] = useState([]);
    const [suppliers, setSuppliers] = useState([]);
    const defaultStock = props.product.stock[0].quantity > 0 ? true : false;

    useEffect(() => {        
        const newProduct = JSON.parse(JSON.stringify(props.product));
        newProduct.stock.shift();
        setProduct(newProduct);
        setBranches(filterStock(newProduct, item => item.business_type === "Branch"));
        setSuppliers(filterStock(newProduct, item => item.business_type === "Supplier"));        

    }, [])

    const filterStock = (item, callBack) => {
        if(item.stock.length > 0) {
            return _.filter(item.stock, (item) => {
                return callBack(item);
            });
        }
        return item.stock;
    }

    return (
        <tr className="table__tr">
            <td className="table__td table__td--entire-row" colSpan={props.columns}>
                <div className="grid">
                    <div className="grid__row">
                        <div className="grid__column grid__column--8">
                            <Tabs>
                                <TabList>
                                    <Tab>Branch Stock</Tab>
                                    {
                                        (props.settings.show_supplier_stock_info && props.auth.business_type_id == 3) ? <Tab>Supplier Stock</Tab> : null
                                    }
                                </TabList>
                                <TabPanel>
                                    <Stock  stockLoad={props.stockLoad}
                                            stocks={branches}
                                            stockVisibility = {props.settings.stock_visibility}
                                            title="Branch"
                                            section_lookup_id={props.section_lookup_id}
                                            shoppingCart={props.shoppingCart}
                                            lookup={props.lookup}
                                            settings = {props.settings}
                                            product = {product}
                                            defaultStock = {defaultStock}/>
                                </TabPanel>
                                {
                                    (props.settings.show_supplier_stock_info && props.auth.business_type_id == 3) ? 
                                        <TabPanel>
                                            <Stock  stockLoad={props.stockLoad}
                                                    stocks={suppliers}
                                                    stockVisibility = {props.settings.stock_visibility}
                                                    title="Supplier"
                                                    section_lookup_id={props.section_lookup_id}
                                                    shoppingCart={props.shoppingCart}
                                                    lookup={props.lookup}
                                                    settings = {props.settings}
                                                    product = {product}
                                                    defaultStock= {defaultStock}/>                                        
                                        </TabPanel>
                                    : null
                                }
                            </Tabs>
                        </div>
                        <div className="grid__column grid__column--4">
                            <Tabs>
                                <TabList>
                                    <Tab>Images</Tab>
                                </TabList>
                                <TabPanel>
                                    <Images product={product}/>
                                </TabPanel>
                            </Tabs>
                        </div>
                    </div>
                </div>
            </td>
        </tr>
    );
}

const mapStateToProps = state => ({
    products: state.shoppingCart,
    settings: state.settings,
    auth: state.auth.user.account
})

export default connect(mapStateToProps)(Main);