import React, {useState, useEffect} from "react";
import CustomButton from "admin/administrator/CustomButton.js";
import Badge from "components/Badge";
// import "admin/administrator/CustomStatus.css";

const CustomStatus = ({item, isAccount = false}) => {
    const [activeStatus, setActiveStatus] = useState({title: 'Pending', type: 'statuspending'});

    const statuses = {
        active: {title: 'Active', type: 'success'},
        inactive: {title: 'Inactive', type: 'neutral'},
        pending: {title: 'Pending', type: 'warning'},
        suspended: {title: 'Suspended', type: 'purple'},
        deleted: {title: 'Deleted', type: 'danger'},
    }

    useEffect(() => {
        if(item.approved) {
            setActiveStatus(statuses.active)
        }else{
            if(item.suspend == true) {
                setActiveStatus(statuses.suspended)
            }else {
                setActiveStatus(statuses.inactive)
            }
        }

        if(isAccount) {
            if( !(item.logins_count > 0 || item.accountType == "Supplier")) {
                setActiveStatus(statuses.pending)
            }
        }

        if( typeof item.deleted_at != 'undefined' && item.deleted_at !== null) {
            setActiveStatus(statuses.deleted)
        }

    }, [])
    
    return <Badge title={activeStatus.title} type={activeStatus.type}/>
}

export default CustomStatus;