import axios from "axios1";
var moment = require('moment');

export const cleanCompare = () => {
    return function(dispatch) {
        axios.delete("/catalogue/clear-compare-list").then(() => {
            dispatch({type: 'CLEAR_COMPARE_LIST'});
        });
    }
}

export const clearCompareListElement = (product) => {
    return function(dispatch) {
        axios.delete("/catalogue/clear-compare-item/" + product.id).then(() => {
            dispatch({ type: "CLEAR_COMPARE_LIST_ELEMENT", product:product });
        });
    }
}

export const addRemoveCompare = (product, section_lookup_id) => {
    return function(dispatch) {
        let product2 = {
            id: product.product_price_id,
            product: product,
            section_lookup_id: section_lookup_id,
            created_at: moment().format('YY-MM-DD hh:mm:ss'),
        }
        axios.put("/catalogue/add-to-compare", product2).then((response) => {
            dispatch({ type: "ADD_COMPARE", product: product2, section_lookup_id: section_lookup_id })
       });
    }
}