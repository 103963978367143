import React from "react";
import PricingOption from "admin/administrator/PricingOption/PricingOption.component";
// import "admin/administrator/ChargingOptions/MainChargingOptionsContainer.css";

const MainChargingOptionsContainer = ({ pricingOpions, handleChange }) => (
    <div className={'price-setting-group'}>
        <div className="pricing-option">
            <PricingOption 
                title={'cost'}
                handleChange={() => {return;}}
                disabled={true}
                className='bordered'
                inputProps = {[
                    {
                        label: 'login licence fee',
                        name: 'login_fee',
                        value: pricingOpions.login_fee,
                    },
                    {
                        label: 'VRM lookup fee',
                        name: 'vrm_lookup_fee',
                        value: pricingOpions.vrm_lookup_fee,
                    }
                ]} 
            />
        </div>
        <div className="pricing-option" style={{margin: '16px 0'}}>
            <PricingOption 
                title={'branch pricing'}
                handleChange={handleChange}
                className='bordered'
                disabled={false}
                inputProps = {[
                    {
                        label: 'Branch login licence fee',
                        name: 'branch_login_fee',
                        value: pricingOpions.branch_login_fee,
                    },
                    {
                        label: `Branch VRM lookup fee`,
                        name: 'branch_vrm_lookup_fee',
                        value: pricingOpions.branch_vrm_lookup_fee,
                    }
                ]} 
            />
        </div>
        <div className="pricing-option">
            <PricingOption 
                title={'branch customer pricing'}
                handleChange={handleChange}
                className='bordered'
                disabled={false}
                inputProps = {[
                    {
                        label: 'Customer login licence fee',
                        name: 'costumer_login_fee',
                        value: pricingOpions.costumer_login_fee,
                    },
                    {
                        label: `Customer VRM lookup fee`,
                        name: 'costumer_vrm_lookup_fee',
                        value: pricingOpions.costumer_vrm_lookup_fee,
                    }
                ]} 
            />     
        </div>
    </div>
);

export default MainChargingOptionsContainer;