import React, { Component } from "react";
// import "admin/administrator/GeneralClientOrBranchStatistics/index.css";
import _ from "lodash";

export default class CustomerAccounts extends Component
{
    constructor(props)
    {
        super(props);
    }

    render()
    {
        return (
            <div className="flex mt-24">
                <div className="flex">
                    <i className={'fas fa-user-circle fs-24'}></i>
                    <div className="ml-8">Customer Accounts</div>
                </div>
                <div className="fw-700">{this.props.customerAccounts}</div>
            </div>
        );
    }
}