import React from "react";
import CustomButton from "admin/administrator/CustomButton.js";
import SearchCategory from "components/groups/SearchCategory";
import GroupsItem from "components/groups/GroupsItem";
import Slider from 'react-slick';
import _ from 'lodash';

const Groups = ({
    groups,
    fromPartNumber,
    clearSearch,
    chooseGroup,
    chooseGroupBySection,
    setLookupId,
    selectedGroups
}) => {
    const settings = {
        slidesToShow: 7,
        slidesToScroll: 3,
        infinite: false,
        arrows: true,
        prevArrow: <button className="slick-custom-prev"><i class="fas fa-chevron-left"></i></button>,
        nextArrow: <button className="slick-custom-next"><i class="fas fa-chevron-right"></i></button>,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 320,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
        groups.length > 1 &&
            <div className={"selections mb-16"}>
                <div className="selections__header">
                    <div className="selections__title">
                        <span>Product Groups</span>
                    </div>
                    <div className="selections__search">
                        <SearchCategory 
                            groups={groups}
                            chooseGroup={chooseGroup}
                         chooseGroupBySection={chooseGroupBySection}
                            setLookupId={setLookupId}
                        />                        
                    </div>
                    {
                        fromPartNumber && groups.length > 1 &&
                            <CustomButton title={"Clear Search"} onClick={() => clearSearch()} type={"blue"}/>
                    }
                </div>
                <div className="selections__content">
                    <div className="groups">
                        <Slider {...settings}>
                            {groups.map( group => <GroupsItem group={group} selectedGroups={selectedGroups} chooseGroup={chooseGroup} /> )}
                        </Slider>
                    </div>
                </div>
            </div>
    );
}

export default Groups;