import React, {createRef, Fragment, useState, useEffect} from "react";
// import "admin/administrator/CustomTable.css";
// import "admin/administrator/CustomElements.css";
import {addProduct, removeProduct} from "redux/actions/shoppingCart";
import ChangeQuantity from "admin/administrator/ChangeQuantity/ChangeQuantity.js";
import alertify from "alertifyjs";
import _ from "lodash";
import {connect, useDispatch} from "react-redux";
import QuantityCheck from "components/checkout/QuantityCheck";
import NoImage from "images/no-img.png";
// import "admin/administrator/CustomTable.css";
// import "admin/administrator/CustomElements.css";

const CustomTable = ({ data, shoppingCart, settings }) =>  {
    const dispatch = useDispatch()
    const [quantity, setQuantity] = useState(0);
    const [product, setProduct] = useState(null);
    const inputRef = createRef();
    
    useEffect(() => {
        if(quantity) {
            product.cart.quantity = product.quantity;
            dispatch(addProduct(product.cart, quantity));
        }
    }, [quantity]);

    function handleQuantityChange(productId, event) {

        let newValue = 0;
        const isnum = /^\d+$/.test(event.target.value)
        let products = shoppingCart;
        let product = _.find(products, {id: productId});

        if(isnum){
            newValue = parseInt(event.target.value);
            if (parseInt(product.product.quantity) < newValue) {
                alertify.error("Out of stock!");
                return;
            }
            
            if (newValue == 0) {
                alertify.error("Quantity must be greater than zero!");
                return;
            }

            setQuantity(Number(newValue));
            setProduct(product);

        }else if(!event.target.value){
            alertify.error("Quantity must be greater than zero!");
            return;
        }
    }

    function handleSubtractClick(productId, event) {
        let newValue = parseInt(event.target.value) - 1;
        let products = shoppingCart;
        let product = _.find(products, {id: productId});
        if (newValue == 0) {
            alertify.error("Quantity must be greater than zero!");
            return;
        }
        setQuantity(newValue);
        setProduct(product);
    }

    function handleAddClick(productId, event) {
        let newValue = parseInt(event.target.value) + 1;
        let products = shoppingCart;
        let product = _.find(products, {id: productId});
        if (product.product.quantity < newValue) {
            alertify.error("Out of stock!");
            return;
        }
        setQuantity(newValue);
        setProduct(product);
    }

    function findIndex(products, product) {
        return _.findIndex(products, (element) => {
            return element.id == product.id && ( element?.cart?.account_id || element?.account_id) == ( product?.cart?.account_id || product?.account_id);
        });
    }

    function handleRemoveProduct(product) {
        dispatch(removeProduct(product));
    }

    function copyPartNumber(event) {
        let text = document.querySelector('.copy-pn-' + event.target.value);
        text.select();
        document.execCommand('copy');
    }
    data = _.sortBy(data, 'lookup');
    let totalProductsPrices = 0;
    return (
        <div className={"custom-table-wrapper"}>
            <table className={"table table-striped"}>
                <thead className={"custom-table-header"}>
                <tr>
                    <th scope="col">Part Number</th>
                    {
                        settings.images_in_shopping_cart ?
                        <th scope="col">Image</th> : null
                    }
                    <th scope="col">Lookup</th>
                    <th scope="col">Section</th>
                    <th scope="col">Location</th>
                    <th scope="col">Qty</th>
                    {
                        settings.show_prices ?  
                            <Fragment>
                                <th scope="col">Unit Price</th>
                                <th scope="col">Total Price</th>
                            </Fragment>
                        :null
                    }
                    <th scope="col">Action</th>
                </tr>
                </thead>
                <tbody className={"custom-table-body"}>
                {

                    data.length ? _.map(data, (product) => {
                        let totalPrice = Math.round(1000 * (parseFloat(product.price) * product.quantity)) / 1000;
                        totalProductsPrices += parseFloat(totalPrice).toFixed(2);
                        let index = findIndex(shoppingCart, product);
                        let productInState = (shoppingCart.length) ? shoppingCart[index] : [];
                        return <tr key={product.id}>
                            <td>
                                <input type={"text"}
                                    className={"copy-pn copy-pn-" + product.part_number}
                                    readOnly
                                    onClick={copyPartNumber}
                                    value={product.part_number}
                                />
                            </td>
                            {
                                    settings.images_in_shopping_cart ?
                                    <td>
                                        <div className="custom-table-image">{ product.images && product.images.length > 0 ? <img className="cart-product_img" src={ product.images[0].url } /> :  <img className="cart-product_img"  width={"54px"} src={NoImage} /> }</div>
                                </td>
                                    : null
                            }
                            <td className={"td-to-upper-case"}>{product.lookup}</td>
                            <td>{product.section}</td>
                            <td>{product?.cart?.account?.name || product?.account}</td>
                            <td>
                                <ChangeQuantity initialValue={productInState.quantity} 
                                                product={product}
                                                products={shoppingCart}
                                                setQuantity={setQuantity}
                                                setProduct={setProduct}
                                                />
                                {
                                    // props.forShoppingCart ?
                                    //     <div className="cart-product-body-footer-quantity line-w-17">
                                    //         <button className="cart-product-body-footer-quantity__subract"
                                    //                 onClick={e => handleSubtractClick(product.id, e)}
                                    //                 value={productInState.quantity}
                                    //         >-
                                    //         </button>
                                    //         <div className="cart-product-body-footer-quantity__quantity">
                                    //             <input
                                    //                 step="1"
                                    //                 min='1'
                                    //                 value={String(productInState.quantity)}
                                    //                 onChange={e => handleQuantityChange(product.id, e)}
                                    //                 type="text"
                                    //             />
                                    //         </div>
                                    //         <button className="cart-product-body-footer-quantity__add"
                                    //                 onClick={e => handleAddClick(product.id, e)}
                                    //                 value={productInState.quantity}
                                    //         >+
                                    //         </button>
                                    //     </div>
                                    //     :
                                    //     <Fragment>
                                    //         {product.quantity}
                                    //         {/* <QuantityCheck data={product}></QuantityCheck> */}
                                            
                                    //     </Fragment>
                                }
                            </td>
                            {
                               settings.show_prices ?  
                                    <Fragment> 
                                        <td>{product.symbol + parseFloat( product.price).toFixed(2) }</td>
                                        <td>{product.symbol + totalPrice.toFixed(2)}</td>
                                    </Fragment>
                                : null
                            }
                            <td>
                                <span className="custom-table-delete" onClick={() => handleRemoveProduct(product)}>
                                    <i className="icon-Trash-icon"></i>
                                </span>
                            </td>
                        </tr>
                    }) : null
                }
                </tbody>
            </table>
        </div>
    );

}

const mapStateToProps = state => ({
    shoppingCart: state.shoppingCart,
    settings: state.settings
})

export default connect(mapStateToProps)(CustomTable);