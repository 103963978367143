import React, { Fragment, useState, useEffect, useCallback} from "react";
import CustomReactTable from "admin/administrator/CustomReactTable.js";
import CustomContainer from 'custom_components/CustomContainer.js';
import OrderComponent from "admin/administrator/OrderComponent.js";
import CustomModal from "admin/administrator/customModal.js";
import axios from "axios1";
import _ from "lodash";
// import "admin/administrator/BranchLoginsComponent.css";

const moment = require('moment');

const CustomOrdersWithoutModal = (props) => {    

    const [data, setData] = useState([]);
    const [page, setPage] = useState(1);
    const [pages, setPages] = useState(0);
    const [companyInfo, setCompanyInfo] = useState({});

    useEffect(() => {
        axios.get("catalogue/get-owner")
            .then((response) => {
                if (response.data.success) {
                    setCompanyInfo({
                        "name": response.data.owner.name,
                        "address_1": response.data.owner.address_1,
                        "town": response.data.owner.town,
                        "county": response.data.owner.county,
                        "postal_code": response.data.owner.postal_code
                    });
                }
            })
    }, []);

    const columns = React.useMemo(
        () => [
            {
                Header: "Date/Time",
                accessor: "date",
                disableSortBy: false,
                className: "textLeft",
            },
            {
                Header: "No Of Items",
                accessor: "quantity",
                disableSortBy: false,
                className: "textRight",
            },
            {
                Header: "Total Price",
                accessor: "total",
                disableSortBy: false,
                className: "textRight",
            },
            {
                Header: "Orders",
                accessor: "orderId",
                disableSortBy: false,
                className: "textRight",
                Cell: ({value, row}) => (
                    <OrderComponent value={value}
                        title={`#${value}`}
                        original={row.original}
                        name={props.branch.name}
                        branch={props.branch} date={props.date}
                        companyInfo={companyInfo}
                        printable={false}
                    />
                )
            }
        ]
    );
    
    const getOrders = useCallback((user_id, date, sortBy = false, search='', page = 1) => {
        //Remove server sort
        let id = sortBy ? sortBy.id : '';
        let desc = sortBy ? sortBy.desc : '';
        axios.get("stats/accounts/" + user_id + "/orders" + "?page=" + page + "&search=" + search + "&sort=" + id + "&desc=" + desc, {params: {date: date}} )
        .then((response) => {
            if (response.data.success) {
                setData(response.data.data.logins);
                setPage(page);
                setPages(response.data.data.pages);
            }
        })
    }, []);
    
    useEffect(() => {
        getOrders(props.branch.id, props.date)
    },props.date);

    return  ( <CustomReactTable columns={columns}
                pagination={true}
                page={page}
                pages={pages}
                getData={getOrders.bind(this, props.branch.id, props.date)}
                data={data} 
                searchDisabled={false}
                dsiabledSort={false}
            /> );
}

export default CustomOrdersWithoutModal;
