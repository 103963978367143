import React, {Fragment, useState, useEffect, useCallback} from 'react';
import {NavLink} from "react-router-dom";
import CustomReactTable from "admin/administrator/CustomReactTable.js";
import CustomButton from "admin/administrator/CustomButton";
// import "admin/administrator/CustomerAccounts.css";
import axios from "axios1";
import _ from "lodash";

const CustomerAccounts = (props) => {
    const [data, setData] = useState([]);
    const [page, setPage] = useState(1);
    const [pages, setPages] = useState(0);

    const columns = React.useMemo(
        () => [
            {
                Header: "Account Number",
                accessor: "accNumber",
                className: "textLeft",
                disableSortBy: true,
            },
            {
                Header: "Account Name",
                accessor: "account",
                className: "textLeft",
                disableSortBy: true,
                Cell: ({row, value}) => (
                    value.replace(`_deleted_${row.original.id}`, "")
                )
            },
            {
                Header: "Type",
                accessor: "businessType",
                className: "textLeft",
                disableSortBy: true,
            },
            {
                Header: "Total Logins",
                accessor: "logins",
                className: "textRight",
                disableSortBy: false
            },
            {
                Header: "VRM Lookups",
                accessor: "lookups",
                className: "textRight",
                disableSortBy: false
            },
            {
                Header: "Orders",
                accessor: "amount",
                className: "textRight",
                disableSortBy: false
            },
            {
                Header: "COST",
                accessor: "cost",
                className: "textRight",
                disableSortBy: false,
                Cell: ({value}) => (value.toFixed(2))
            },
            {
                Header: "Action",
                accessor: "id",
                className: "textCenter",
                disableSortBy: true,
                Cell: ({value, row}) => ( 
                    <NavLink to={"/admin/account/" + value + "/" + row.original.account.replace(/\//g, '-') + "/dashboard"} className={"table-nav-link"}
                        exact>
                        <CustomButton title={"View"} type={"blue"}/>
                    </NavLink>
                )
            },
        ]
    );

    const getCustomerAccounts = useCallback( (branch_id, date, sortBy = false, search='', page = 1) => {
        //Remove server sort
        let id = sortBy ? sortBy.id : '';
        let desc = sortBy ? sortBy.desc : '';
        axios.get("stats/accounts/" + branch_id + "/customer-accounts? page=" + page + "&search=" + search + "&sort=" + id + "&desc=" + desc, {params: {date: date}} )
        .then((response) => {
            if (response.data.success) {
                console.log('response.data.data.customerAccounts', response.data.data.customerAccounts)
                setData(response.data.data.customerAccounts);
                setPage(page);
                setPages(response.data.data.pages);
            }
        })
    }, []);

    useEffect(() => {
        getCustomerAccounts(props.branch.id, props.date);
    },props.date);

    return  (<div className={"branch-logins-component-wrapper"}>
                <CustomReactTable columns={columns}
                    pagination={true}
                    page={page}
                    pages={pages}
                    getData={getCustomerAccounts.bind(this, props.branch.id, props.date)}
                    data={data}
                    searchDisabled={false}
                    dsiabledSort={false}
                />
            </div>);
}

export default CustomerAccounts;