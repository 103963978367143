import React, {Fragment, useState, useEffect} from 'react';
import "./MainSlider.css";
import CustomButton from "admin/administrator/CustomButton";
import MainSlide from "admin/administrator/ImageSlider/MainSlide";
import AddSlide from "admin/administrator/ImageSlider/AddSlide";
import UploadModal from "admin/administrator/ImageSlider/UploadModal";
import MessageBox from "components/MessageBox";
import {SortableContainer, SortableElement, sortableHandle} from 'react-sortable-hoc';
import Loading from "admin/administrator/LoadingComponent/Loading.component";
import arrayMove from 'array-move';
import axios from "axios1";
import _ from "lodash";
import alertify from 'alertifyjs';

function MainSlider(props) {
    const [images, setImages] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [messages, setMessages] = useState({
        success: {
            type: "success",
            text: "Published successfully!",
            visible: false
        },
        warning: {
            type: "warning",
            text: "You have unpublished changes!",
            visible: false
        }
    });

    useEffect(() => {
        getMainSlides();
    }, []);

    const SortableItem = SortableElement(({value}) => <MainSlide image={value} 
                                                                removeSlide={removeSlide.bind(this)} 
                                                                changeVisibilityStatus={changeVisibilityStatus.bind(this)}/>);

    const SortableList = SortableContainer(({items}) => {
        return (
            <div className="slides-container">
                {
                    items.map((value, index) => {
                        let v = {
                            index: index,
                            image: value
                        }
                        return <SortableItem key={`item-${index}`} index={index} value={v} />
                    })
                }
                <AddSlide openModal={() => openModal()}/>
            </div>
        );
    });

    function removeSlide(index) {
        let imgs = [...images];
        imgs.splice(index, 1);
        setImages(imgs);
        showMessageBox("warning");
    }

    function changeVisibilityStatus(index) {
        let imgs = [...images];
        let image = imgs[index];
        image.is_visible = (image.is_visible == 1) ? 0 : 1;
        setImages(imgs);
        showMessageBox("warning");
    }

    function getMainSlides() {
        axios.get("catalogue/main-slides")
            .then((response) => {
                console.log(response.data);
                setImages(response.data);
            })
    }

    function openModal() {
        setShowModal(true);
    }

    function closeModal() {
        setShowModal(false);
    }

    function setImagePreview(files) {
        let imagesArr = [...images];
        if(files.length > 0) {
            files.map((file) => {
                imagesArr.push(file);
            });
            setImages(imagesArr);
            showMessageBox("warning");
        }
    }

    function showMessageBox(type) {
        setMessages({});
        let msgs = {...messages};
        Object.keys(msgs).map((key) => {
            msgs[key].visible = false;
        });
        msgs[type].visible = true;
        setTimeout(() => {
            setMessages(msgs);
        }, 100)
    }

    function publishSlider() {
        let imgs = [...images];
        setLoading(true);
        console.log('imgs', imgs)
        axios.post("catalogue/main-slides", {images: imgs})
        .then(() => {
            setLoading(false);
            showMessageBox('success');
        });
    }

    const onSortEnd = ({oldIndex, newIndex}) => {
        setImages(arrayMove(images, oldIndex, newIndex));
        showMessageBox('warning');
    };

    return(
        <Fragment>
            <Loading loading={loading} />
            <UploadModal 
                showModal={showModal} 
                closeModal={() => closeModal()}
                multiple={true}
                setImagePreview={setImagePreview.bind(this)}/>
            <div className="box">
                {
                    Object.keys(messages).map((key) => {
                        if(messages[key].visible == true) {
                            return <div className="box-message">
                                        <MessageBox type={messages[key].type} text={messages[key].text}/>
                                    </div>
                        }
                    })
                }
                <div className="box__header">
                    <h3>Hero Image Slider</h3>
                    <CustomButton type={"blue"} title={"Publish"} onClick={publishSlider}/>
                </div>
                <div className="box__body">
                    <SortableList items={images} onSortEnd={onSortEnd} axis={"xy"} useDragHandle/>
                </div>
            </div>
        </Fragment>
    )
}

export default MainSlider