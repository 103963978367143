import React, {useState, useEffect} from "react";

import UserPreferences from 'admin/administrator/UserPreferences.js';
import "admin/administrator/TableActions.css";
import active_icon from "images/active_icon.svg";
import deactivate_icon from "images/deactivate_icon.svg";
import delete_icon from "images/delete_icon.svg";
import edit_icon from "images/edit_icon.svg";
import suspend_icon from "images/suspend_icon.svg";
import preferences_icon from "images/preferences_icon.svg";
import Icon_material_restore from "images/Icon_material-restore.svg"; 
import { Fragment } from "react";
import Notify from "components/Notify";

const TableActions = (props) => {
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    return <div className={"action-popup-wrapper"}>
        <div className={`action-popup-btn ${props.showActions === ( props.isAccount ? props.id : props.email ) ? 'active' : ''}`} onClick={() => props.showActionPopup()}><i class=" action-popup-btn-i fas fa-ellipsis-h"></i></div>
        {
            props.showActions === ( props.isAccount ? props.id : props.email ) ?
            <Fragment>
                <div className={"action-backdrop"} onClick={() => props.showActionPopup()}></div>
                <div className={"action-popup-content"}>
                    <ul className={"action-popup-cont-ul"}> 
                    {
                        typeof props.deleted != 'undefined' && props.deleted !== null ?
                            <li className={'action-popup-cont-li'} onClick={() => props.onRestore()}><img src={Icon_material_restore}/><span>Restore</span></li>                        
                        :<Fragment>
                            <li className={'action-popup-cont-li'} onClick={ () => {  props.showActionPopup(); props.onEditClick(); }}><img src={edit_icon}/><span>View/Edit</span></li>
                            { 
                                props.activeChecked ? 
                                    <li className={` action-popup-cont-li ${props.activeDisabled ? 'action-li-disabled' : ''}`} onClick={() => { if(!props.activeDisabled) { props.showActionPopup(); props.onActiveChange(false) }}}><img src={deactivate_icon}/><span>Deactivate</span></li> 
                                : 
                                    <li className={` action-popup-cont-li ${props.activeDisabled ? 'action-li-disabled' : ''}`} onClick={() => { if(!props.activeDisabled) { props.showActionPopup(); props.onActiveChange(true) }}}><img src={active_icon}/><span>Activate</span></li>
                            }
                            {
                                props.activeChecked && !props.suspendChecked ?
                                    <li className={` action-popup-cont-li ${props.activeDisabled ? 'action-li-disabled' : ''}`} onClick={() => { if(!props.activeDisabled ) { props.showActionPopup(); props.onSuspendClick(true)}}}><img src={suspend_icon}/><span>Suspended</span></li>
                                : null
                            }
                            { props.withPref ? <li className={'action-popup-cont-li'} onClick={() => {  props.showActionPopup(); props.onPreferencesClick()}}><img src={preferences_icon}/><span>Preferences</span></li> : null }
                            <li className={' action-popup-cont-li action-popup-delet'} onClick={() => setShowDeleteModal(true)}><img src={delete_icon}/><span>Delete</span></li>
                        </Fragment>
                    }
                    </ul>
                </div>
            </Fragment>
            : null
        }
        {
            props.withPref && 
                <UserPreferences
                    show={props.show}
                    adminSetting={props.adminSetting}
                    currentElement={props.currentElement}
                    preferences={props.preferences}
                    getSections={props.getSections}
                    getBrands={props.getBrands}
                    branches={props.branches}
                    showActions={props.showActions}
                    onHide={props.onHide}
                    isCustomer={props.isCustomer}
                    isBranch={props.isBranch}
                    updatePreferencesValues={props.updatePreferencesValues}
                />
        }

        <Notify type={"danger"}
                visible={showDeleteModal}
                close={() => setShowDeleteModal(false)}
                primaryText={"Are you sure?"}
                secondaryText={`The ${props.isAccount ? props.accountType : 'user'} "${props.name}" will be permanently deleted.`}
                actions={[
                    {
                        name: "cancel",
                        action: setShowDeleteModal.bind(this, false),
                        type: "lightgray"
                    },
                    {
                        name: "delete",
                        action: props.delete.bind(this),
                        type: "red"
                    }
                ]}/>
    </div>
}

export default TableActions;