import React from 'react';
// import "./Fees.css";

function Fees({licenceFee, lookupFee}) {
    return (
        <div className={"mt-24"}>
            <div className={"fw-700 mb-8"}>Fees</div>
            <div>
                <div className={"flex mb-4"}>
                    <span>Licence Fee</span>
                    <span>{licenceFee}</span>
                </div>
                <div className={"flex"}>
                    <span>VRM Lookup Fee</span>
                    <span>{lookupFee}</span>
                </div>
            </div>
        </div>
    );
}

export default Fees;