import React, {useState} from 'react';
import './FtpSendEmail.css';
import CustomButton from "admin/administrator/CustomButton";
import {GridLoader} from 'react-spinners';
import axios from "axios1";
import { Fragment } from 'react';
import ApproveToggleComponent from "admin/administrator/ApproveToggleComponent.js";
import Notify from "components/Notify";

function FtpSendEmail({credentials, supplier}) {
    const [loading, setLoading] = useState(false);
    const [approveModal, setApproveModal] = useState({
        show: false,
        name: '',
        title: 'This will send the FTP details to ' + supplier.email,
        message: 'Are you sure?'
    });

    function sendEmail(status) {
        if(!status) {
            closeModal(); return;
        } 

        setLoading(true);
        axios.post("catalogue/sendFtpCredentials", {credentials: credentials, accountId: supplier.id})
            .then((response) => {
                setLoading(false);
                closeModal();
            });
    }

    function showModal() {
        let modal = {...approveModal};
        modal.show = true;
        setApproveModal(modal);
    }

    function closeModal() {
        let modal = {...approveModal};
        modal.show = false;
        setApproveModal(modal);
    }

    const closeNotify = () => setApproveModal({...approveModal, show: false});

    return (
        <Fragment>
            <div className={"send-email-btn"}>
                {
                    loading ?
                        <div className='sweet-loading'>
                            <GridLoader
                                sizeUnit={"px"}
                                size={15}
                                color={'#0066b8'}
                                loading={loading}
                            />
                        </div> : null
                }
                <CustomButton
                    title={"Email Credentials to Supplier"}
                    type={"blue"}
                    onClick={() => showModal()}
                />
            </div>
            <Notify type={"primary"}
                    visible={approveModal.show}
                    close={closeNotify.bind(this)}
                    primaryText={approveModal.title}
                    secondaryText={approveModal.message}
                    actions={[
                        {
                            name: "no",
                            action: closeNotify.bind(this),
                            type: "lightgray"
                        },
                        {
                            name: "yes",
                            action: sendEmail.bind(this, true),
                            type: "blue"
                        }
                    ]}/>
        </Fragment>
    )
}

FtpSendEmail.defaultProps = {
    credentials: []
}

export default FtpSendEmail;