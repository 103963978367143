import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import { connect } from "react-redux";
import { LogoutUser } from "redux/actions/authActions";
import 'components/Login/LogoutBtn.css';
import { reloadSettings } from "redux/actions/settings";
import { Fragment } from "react";

class LogoutBtn extends Component
{
    constructor(props)
    {
        super(props);
    }

    logOut = () => {
        this.props.setStateFromChild("loading", true);
        this.props.LogoutUser().then(() => {
            this.props.reloadSettings().then(() => {
                this.props.setStateFromChild("loading", false);       
                this.props.history.push("/Login");
            })
        });
    }

    render() {
        return (
            <Fragment>
                <div className="subnav__link" onClick={this.logOut} style={this.props.size ? this.props.size : ''}>
                    Logout
                </div>
            </Fragment>
        );
    }
}

const mapDispatchToProps = {
    reloadSettings,
    LogoutUser
};


export default withRouter(connect(
    null,
    mapDispatchToProps
)(LogoutBtn));