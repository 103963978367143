import React from "react";
// import "./FormSelect.style.css";

const FormSelect = ({ handleChange, error, options, selectedId, defaultValue, ...props }) => (
    <div className="selector">
        <select className={"selector__selector " + (error ? "selector__selector--error" : "")} {...props} onChange={ handleChange } >
            {  defaultValue ? ( <option value={""}>{ defaultValue }</option>) : null }
            {  
                options.map( ({ id, name }) => <option  key={ id } value={ id } selected={ selectedId == id }> { name } </option> )
            }
        </select>
        {
            error ? ( <span className={"selector__error"}>{ error }</span> ) : null
        }
    </div>
)

export default FormSelect;