import React from "react";
import FormInput from "admin/administrator/FormInput/FormInput.component";

const SettingDetails = ({ form, handleChange, errors = {} }) => (
        <div className='setting-details'>
            <FormInput
                name='name'
                type='text'
                placeholder='Company / Account Name *'
                error={ errors.name }
                value={ form.name || '' }
                onChange={ handleChange }
                required
                className="bordered"
            />

            <FormInput
                name='contact_name'
                type='text'
                placeholder='Contact Name *'
                error={ errors.contact_name} 
                value={ form.contact_name || '' }
                onChange={ handleChange }
                required
                className="bordered"
            />
            <FormInput
                name='address_1'
                type='text'
                placeholder='Address Line 1 *'
                error={ errors.address_1 }
                value={ form.address_1 || ''}
                onChange={ handleChange }
                required
                className="bordered"
            />

            <FormInput
                name='address_2'
                type='text'
                placeholder='Address Line 2'
                error={ errors.address_2 }
                value={ form.address_2 || ''}
                onChange={ handleChange }
                className="bordered"
            />
            <FormInput
                name='town'
                type='text'
                placeholder='Town *'
                error={ errors.town }
                value={ form.town || ''}
                onChange={ handleChange }
                required
                className="bordered"
            />
            <FormInput
                name='county'
                type='text'
                placeholder='County'
                error={ errors.county }
                value={ form.county || ''}
                onChange={ handleChange }
                className="bordered"
            />

            <FormInput
                name='postal_code'
                type='text'
                placeholder='Post Code / Zip'
                error={ errors.postal_code }
                value={ form.postal_code || '' }
                onChange={ handleChange }
                className="bordered"
            />
            <FormInput
                name='phone'
                type='text'
                placeholder='Phone Number *'
                error={ errors.phone }
                value={ form.phone || ''}
                onChange={ handleChange }
                required
                className="bordered"
            />
            <FormInput
                name='email'
                type='text'
                placeholder='Email *'
                error={ errors.email }
                value={ form.email || ''}
                onChange={ handleChange }
                required
                className="bordered"
            />
        </div>
    );

export default SettingDetails;