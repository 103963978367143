import React, {Fragment, useState, useEffect} from 'react';
// import './BespokePricesBody.css';
import ChooseFile from "components/ChooseFile";
import FileName from "components/FileName";
import XLSX from 'xlsx';
import alertify from "alertifyjs";
import Pagination from "components/Pagination";
import {CSVLink, CSVDownload} from "react-csv";
import FormInput from "admin/administrator/FormInput/FormInput.component";

function BespokePricesBody(props) {
    const [fileName, setFileName] = useState("");
    const [partNumbers, setPartNumbers] = useState([]);
    const [filteredPartNumbers, setFilteredPartNumbers] = useState([]);
    const [currentPartNumbers, setCurrentPartNumbers] = useState([]);
    const [currentPage, setCurrentPage] = useState(null);
    const [totalPages, setTotalPages] = useState(null);
    const [filterValue, setFilterValue] = useState(null);
    const [bespokeName, setBespokeName] = useState(null);

    useEffect(() => {
        redrawPagination(filteredPartNumbers.length, currentPage);
    }, [filteredPartNumbers]);

    useEffect(() => {
        setFilteredPartNumbers(props.partNumbers);
        setPartNumbers(props.partNumbers);
        setBespokeName(props.bespokeName);
    }, [props.partNumbers]);

    function handleFile(e) {
        let files = e.target.files, f = files[0];
        if (f !== undefined) {
            let regex = new RegExp("(.*?)\.(xls|xlsx|csv)$");
            let reader = new FileReader();
            if (!(regex.test(f.name.toLowerCase()))) {
                alertify.error("Supported only (xls, xlsx, csv) type files!");
                return;
            }
            reader.onload = (e) => {
                let result = new Uint8Array(e.target.result);
                let workbook = XLSX.read(result, {type: 'array'});
                let sheetNames = workbook.SheetNames[0];
                let sheets = workbook.Sheets[sheetNames];
                let partNumbers = XLSX.utils.sheet_to_json(sheets, {header: 1});
                partNumbers.splice(0, 1);
                setPartNumbers(partNumbers);
                setFilteredPartNumbers(partNumbers);
                setFileName(f.name);
                props.setPartNumbers(partNumbers);
            };
            reader.readAsArrayBuffer(f);
        }
    }

    function redrawPagination(totalRecords, currentPage, pageLimit = 10) {
        const paginationData = {
            currentPage: currentPage,
            pageLimit: pageLimit,
            totalRecords: totalRecords
        };
        onPageChanged(paginationData);
    }

    function onPageChanged(data) {
        const {currentPage, totalPages, pageLimit} = data;
        const offset = (currentPage - 1) * pageLimit;
        let partNumbers = filteredPartNumbers.slice(offset, offset + pageLimit);

        setCurrentPartNumbers(partNumbers);
        setCurrentPage(currentPage);
        setTotalPages(totalPages);
    };

    function handleFilterPartNumbers(e) {
        let filteredPartNumbers = partNumbers.filter((partNumber) => {
            return (partNumber[0].toString().toLowerCase().indexOf(e.target.value.toLowerCase()) > -1) ? partNumber : null;
        });
        setFilteredPartNumbers(filteredPartNumbers);
        redrawPagination(filteredPartNumbers.length, currentPage);
        setFilterValue(e.target.value);
    }

    function handleBespokeNameChange(e) {
        setBespokeName(e.target.value);
        props.setBespokeName(e.target.value);
    }

    function handlePartNumberPriceChange(e, index) {
        let value = e.target.value;
        let valueAfterDot = value.split('.')[1];
        if(valueAfterDot && valueAfterDot.length > 2) {
            return;
        }
        let partNumbers = filteredPartNumbers;

        const offset = (currentPage - 1) * 10;
        partNumbers = partNumbers.slice(offset, offset + 10);
        partNumbers[index][1] = value;
        redrawPagination(partNumbers.length, currentPage);
    }

    return(
        <Fragment>
            <div>
                <div className="flex">
                    <div>Name: </div>
                    <div>
                        <FormInput
                            type='text'
                            placeholder='Enter bespoke name'
                            error={props.errors.name}
                            value={bespokeName}
                            onChange={(e) => handleBespokeNameChange(e)}
                        />
                    </div>
                </div>
                <div className="flex mt-16">
                    <div>File: </div>
                    <div>
                        <div>
                            <FileName fileName={fileName}/>
                            <ChooseFile handleFile={handleFile}/>
                        </div>
                    </div>
                </div>
            </div>
            <div className="grayline"></div>
            {
                filteredPartNumbers.length > 0 || filterValue ?
                <Fragment>
                    <div className="table mt-24">
                        <div className="table__header">
                            <FormInput
                                type='text'
                                placeholder='Enter part number'
                                error={""}
                                value={filterValue}
                                onChange={(e) => handleFilterPartNumbers(e)}
                            />
                            {
                                props.isEdit ?
                                <CSVLink
                                    className={"btn btn--secondary"}
                                    data={props.dbPartNumbersForExport}
                                    filename={"PartNumbers.csv"}
                                >
                                    <i class="fas fa-sign-out-alt"></i> Export
                                </CSVLink> : null
                            }
                        </div>
                        <table className={"table__table"}>
                            <thead className="table__thead">
                                <tr className="table__tr">
                                    <th className="table__th">Part Number</th>
                                    <th className="table__th">Price</th>
                                </tr>
                            </thead>
                            <tbody className="table__tbody">
                            {
                                currentPartNumbers.map((partNumber, i) => {
                                    return <tr key={i} className="table__tr">
                                        <td className="table__td">{partNumber[0]}</td>
                                        <td className="table__td">
                                            <FormInput
                                                type='number'
                                                placeholder='Enter Price'
                                                error={""}
                                                value={partNumber[1]}
                                                onChange={(e) => handlePartNumberPriceChange(e, i)}
                                            />
                                        </td>
                                    </tr>
                                })
                            }
                            </tbody>
                        </table>
                    </div>
                    <Pagination
                        totalRecords={filteredPartNumbers.length}
                        pageLimit={10}
                        pageNeighbours={1}
                        onPageChanged={onPageChanged}
                    />
                </Fragment> : null
            }
        </Fragment>
    )
}

export default BespokePricesBody;