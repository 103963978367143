import React, {Component} from "react";
import {connect} from "react-redux";
import {addRemoveCompare, clearCompareListElement} from "redux/actions/compareList";
import _ from "lodash";
// import "components/AddToCompareBtn.css";
import alertify from "alertifyjs";
import Icon from 'components/Icon';

class AddToCompareBtn extends Component {
    constructor(props) {
        super(props);
    }

    addToCompare(product) {
        console.log(product);
        if(this.props.compareList.length > 6) {
            alertify.error("Compare list is full!");
            return;
        }
        this.props.dispatch(addRemoveCompare(product, this.props.section_lookup_id))
    }

    render() {
        let compareList = this.props.compareList;
        let product = this.props.product;
        product.id = product.product_price_id;
        let comparing = _.some(compareList, (element) => {
            return element.id == product.product_price_id
        });
        return (
            <span title={(!comparing ? "Add to" : "Remove from") + " compare list"}
                  className={"compare-btn ml-8" + (!comparing ? "" : " compare-btn--active")}
                  onClick={e => {
                      !comparing ? this.addToCompare(product) : this.props.dispatch(clearCompareListElement(product))
                  }}>
                  {/* {!comparing ? "+":"-"}  */}
                <Icon file="compare.svg" type={!comparing ? "active" : "light"} width="20px" height="20px"/>
                <span className={"compare-btn__check"}><i class="fas fa-check"></i></span>
            </span>
        );
    }
}

const mapStateToProps = state => ({
    compareList: state.compareList
})

export default connect(mapStateToProps)(AddToCompareBtn);
