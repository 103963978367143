var store = require('store');

let initState = {
    token: defineToken(),
    user: defineUser(),
    previousUser: store.get("previousUser") || null, // user value is stored here when user is switched
    previousToken: store.get("previousToken") || null // token value is stored here when user is switched
}

function defineToken(){
    // return store.get("token") || null
    let token = store.get("token") || null
    let fakeToken = "";
    return process.env.NODE_ENV == "test" ? fakeToken : token;
}

function defineUser(){
    // return store.get("user") || null;
    let user = store.get("user") || null;
    let fakeUser = {"account_id":1,"name":"Nika","email":"tsogiaidze@yahoo.com","admin":1,"password":"$2y$10$5vJqaklcgIsGvVpY0Wgul.jl8jCpHMhqYTRZ3BVmOfIyjcAtACqYS","created_at":null,"updated_at":"2019-09-17 14:25:24","approved":1,"deleted_at":null,"account":{"id":1,"branch_id":null,"business_type_id":6,"price_level_id":null,"communication_email_id":1,"communication_ftp_id":1,"orders_type":"Email","name":"Nordic","contact_name":"Damon","address_1":"Varketili2","address_2":"Varketili","town":"Telavi","county":"Kakheti","postal_code":"234234","phone":"234234","email":"tsogiaidze@yahoo.com","email_verified_at":null,"active":1,"approved":0,"remember_token":null,"created_at":null,"updated_at":"2019-10-29 06:54:10","deleted_at":null}}
    return process.env.NODE_ENV == "test" ? fakeUser : user;
}

const auth = (state = initState, action) => {
    switch (action.type) {
        case 'LOGGED_USER':
            let token = action.payload.token;
            let user = action.payload.user;
            return {
                ...state,
                token: token,
                user: user
            }
        case 'SWITCH_USER':
            token = action.payload.token;
            user = action.payload.user;
            store.set("token", token);
            store.set("user", user);
            store.set("previousUser", state.user);
            store.set("previousToken", state.token);
            return {
                ...state,
                previousToken: state.token,
                previousUser: state.user,
                token: token,
                user: user
            }
        case 'RESET_SWITCH_USER':
            store.set("token", state.previousToken);
            store.set("user", state.previousUser);
            store.set("previousUser", null);
            store.set("previousToken", null);
            return {
                ...state,
                previousToken: null,
                previousUser: null,
                token: state.previousToken,
                user: state.previousUser
            }
        case 'CHANGE_TOKEN':
            token = action.payload;
            return {
                ...state,
                token: token
            }
        case 'LOGGED_OUT_USER':
            return logOut(state);
        case 'AFTER_REGISTRATION':
            let userToken = action.payload
            return {
                ...state,
                token: userToken
            }
        default:
            return state;
    }
}

let logOut = (state) => {
    return { ...state, token: null, user: null, options:null, regNumber:null, portNumber:null }
}

export default auth;
