import React, {useState, useEffect} from "react";
import CustomStatus from "admin/administrator/CustomStatus";
import TableActions from "admin/administrator/TableActions";
import CustomReactTable from "admin/administrator/CustomReactTable.js";
import Loading from "admin/administrator/LoadingComponent/Loading.component";
import _ from "lodash";

const LoginsTable = (props) => {
    const [loading, setLoading] = useState(false);
    const [items, setItems] = useState([]);
    const [dataFilter, setDataFilter] = useState('all');
    const [showActions, setShowActions] = useState(false);
    
    useEffect(() => {
        //setLoading(true);
        setItems(props.items);
        handleDataFilter(dataFilter);
    }, [props.items]);

    // useEffect(() => {
    //     if(items.length > 0 || !props.edit)
    //         setLoading(false);
    // }, [items])

    const columns = React.useMemo(
        () => [
            {
                Header: 'Name',
                accessor: 'name',
                disableSortBy: false,
                className: 'textLeft'
            },
            {
                Header: 'Email',
                accessor: 'email',
                className: 'textLeft',
                Cell: ({row}) => (
                    row.values.email.replace(`_deleted_${row.original.id}`, "")
                ),
            },
            {
                Header: 'Status',
                Cell: ({row}) => (
                   <CustomStatus item={row.original} />
                ),
            },
            {
                Header: 'Actions',
                className: "textCenter",
                Cell: (row) => (
                        <TableActions
                            key={row.row.original.id}
                            id={row.row.original.id}
                            email={row.row.original.email}
                            showActions={showActions}
                            showActionPopup={showActionPopup.bind(this, row.row.original.email)}
                            // restore
                            deleted={row.row.original.deleted_at}
                            onRestore={onRestore.bind(this, row.row.original.id)}
                            // edit
                            onEditClick={props.handeEditLogin.bind(this, row.row.original.id, row.row.original.email)}
                            // approve
                            activeChecked={row.row.original.approved}
                            onActiveChange={props.handleSwitchChange.bind(this, "approved", row.row.original.email)}
                            // suspend
                            suspendChecked={row.row.original.suspend}
                            onSuspendClick={props.handleSuspendClick.bind(this, "suspend", row.row.original.email)}
                            // preferences
                            withPref={true}
                            onPreferencesClick={props.handlePreferencesClick.bind(this, row.row.original.id, row.row.original.email)}
                            show={(row.row.original.preferences) ? row.row.original.preferences.modalShow : false}
                            adminSetting={true}
                            currentElement={{
                                name: row.row.original.name,
                                title: "Catalogue preferences for user -"
                            }}
                            preferences={props.getPreferences.bind(this, row.row.original.id, row.row.original)}
                            getSections={props.getSections.bind(this, row.row.original.id)}
                            getBrands={props.getBrands.bind(this, row.row.original.id)}
                            onHide={props.modalClose.bind(this, row.row.original.id, row.row.original.email)}
                            isCustomer={props.isCustomer}
                            isBranch={props.isBranch}
                            branches={props.branches}
                            updatePreferencesValues={props.updatePreferencesValues.bind(this, row.row.original.id, row.row.original.email)}
                            //delete
                            isAccount={false}
                            name={row.row.original.name}
                            delete={ status => { showActionPopup(); props.handleDeleteLogin(row.row.original.id, row.row.original.email, status); }}
                        />
                ),
            }
        ]
    );
        
    function showActionPopup(email) {
        if(showActions !== email) {
            setShowActions(email);    
        }else {
            setShowActions(false);
        }
    }

    function onRestore(id) {
        props.restoreLogin(id);
        showActionPopup();
    }
        
    const filtres = {
        all: item => !item.deleted_at,
        active: item => !item.deleted_at && item.approved,
        inactive: item => !item.deleted_at && !item.approved && item.suspend != true,
        suspended: item => !item.deleted_at && item.suspend,
        deleted: item => typeof item.deleted_at != 'undefined' && item.deleted_at !== null
    }

    const handleSort = ( sortBy = false, dataFilter ) => {
        let id = sortBy ? sortBy.id : '';
        let newItems = [];
        newItems = _.sortBy(items, (product) => product[id]);
        newItems = sortBy && sortBy.desc ? newItems.reverse() : newItems;
        setItems(newItems);
    };

    const handleDataFilter = filter => {
        setDataFilter(filter);
        let filtered = _.filter(props.items, item => filtres[filter](item));
        setItems(filtered);
        // props.setLogins(filtered);
    };

    return <div className="react-table-7">
        <Loading loading={loading}/>
        <CustomReactTable columns={columns} data={items} dsiabledSort={false} getData={handleSort.bind(this)} setFilter={handleDataFilter.bind(this)} dataFilter={dataFilter}/>
    </div>
}

export default LoginsTable;