import React, {Fragment, useState, useEffect} from 'react';
import _ from "lodash";
import CustomLoading from "admin/administrator/CustomLoading";
import CustomSortable from "admin/administrator/CustomSortable";
import CustomSelectAllAble from "admin/administrator/CustomSelectAllAble";
import CustomCheckBox from "admin/administrator/CustomCheckBox";
import PreferencesForm from "admin/administrator/PreferencesForm/PreferencesForm.component";
import Loading from "admin/administrator/LoadingComponent/Loading.component";
import {userPreferencesForm} from "utils/preferenceFormsConfig";
import alertify from 'alertifyjs';
import 'jquery-ui/ui/widgets/sortable';
import CustomButton from "admin/administrator/CustomButton";
import Modal from "components/Modal";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Block from "components/Block";

const UserPreferences = props => {
    const [loading, setLoading] = useState(false);
    const [sectionLoad, setSectionLoad] = useState(false);
    const [brandsLoad, setBrandsLoad] = useState(false);
    const [isCustomer, setIsCustomer] = useState(false);
    const [currentElement, setCurrentElement] = useState([]);
    const [brandsHidden, setBrandsHidden] = useState(true);
    const [manufacturersAll, setManufacturersAll] = useState(true);
    const [groups, setGroups] = useState([]);
    const [sections, setSections] = useState([]);
    const [showBrands, setShowBrands] = useState([]);
    const [form, setForm] = useState({
        search_types: [],
        encrypt_part_numbers: false,
        enable_shopping_cart: false,
        enable_request_call_btn: true,
        images_in_shopping_cart: false,
        show_brand_logos: false,
        show_prices: false,
        show_full_info: false,
        show_xrefs: false,
        stockOption: 'hidden',
        stock_visibility: "quantity",
        search_type: "advanced",
        multi_select_groups: false,
        enable_vehicle_selection: false,
        vrm_vehicle_confirmation: false,
        search_filter:false,
        lost_sale: true,
        show_supplier_stock_info: true,
    });
    const [stockOptions, setStockOptions] = useState([
        {name: "In/Out of stock", value: "inOut"},
        {name: "Actual stock quantity", value: "quantity"},
        {name: "Do not display stock", value: "hidden"}
    ])
    const [additionalElements, setAdditionalElements] = useState([
        {value: "warranty", name: "Warranty", checked: false},
        {value: "notes", name: "Notes", checked: false},
        {value: "images", name: "Product Image", checked: false}
    ]);
    const [searchTypes, setSearchTypes] = useState([
        {name: "Vehicle registration (vrm)", value: "vrm", checked: false},
        {name: "VIN (chassis number)", value: "vin", checked: false},
        {name: "NON vehicle related parts", value: "non", checked: false}
    ]);
    const [manufacturers, setManufacturers] = useState([
        {name: "ALFA ROMEO", checked: true},
        {name: "BMW", checked: true},
        {name: "MERCEDES-BENZ", checked: true},
        {name: "VAUXHALL", checked: true}
    ]);
    const [selected, setSelected] = useState({
        groupId: 0,
        sectionId: 0,
    });

    useEffect(() => {
        if(props.show && groups.length === 0) {
            setLoading(true);
            setStateValues();
        }else {
            return;
        }
    }, [props.show]);

    useEffect(() => {
        updateBrandsOrder(showBrands)
    }, [showBrands])

    const setStateValues = () => {
        let awaitPref = props.preferences();
        awaitPref.then((preferences) => {
            setForm(preferences.form);
            setGroups(preferences.groups);
            setIsCustomer( preferences.isCustomer);
            setAdditionalElements(preferences.form.additional_elements);
            setSearchTypes(preferences.form.search_types);
            setManufacturers(preferences.form.manufacturers);
            setCurrentElement(props.currentElement);
            setLoading(false);
        })
    }

    const handleSaveClick = () => {
        setLoading(true);
        props.onHide();
        props.updatePreferencesValues({ groups: [...groups], form: {...form} })
        setSelected({
            groupId: 0,
            sectionId: 0
        });
        setBrandsHidden(true);
        setLoading(false);
    }

    const handleSwitchChange = (value, title, property) => {
        let newForm = {...form};
        if(property) {
            let element = _.find(newForm[title], { value: property });
            element.checked = value;
        } else {
            value = typeof value === "boolean" ? ( value ? "1" : "0" ) : value;
            newForm[title] = value;
        }

        setForm(newForm);
    }

    const handleValueChange = (property, event) => {
        let newForm = {...form};
        newForm[property] = event.target.value;
        setForm(newForm);
    }

    const handleAdditionalElementChange = (property, event) => {
        let newForm = {...form};
        let element = _.find(newForm.additional_elements, { value: property });
        element.checked = event.target.checked;
        setForm(newForm);
    }

    const handleBranchesElementChange = (property, event) => {
        let newForm = {...form};
        let branchElements = newForm.branches_elements ?? [];

        if(event.target.checked) {
            branchElements.push(property);
        }else {
            branchElements = _.filter(branchElements, item => parseInt(item) !== property);
        }
     
        newForm.branches_elements = branchElements;
        setForm(newForm);
    }

    const handleSearchTypeChange = (property, event) => {
        let newForm = {...form};
        let type = _.find(newForm.search_types, { value: property });
        type.checked = event.target.checked;
        setForm(newForm);
    }

    const handleManufacturersChange = manufacturers => { 
        setForm({...form, manufacturers: manufacturers});
        setManufacturers(manufacturers); 
    }

    const updateAdditionalElementsOrder = items => setForm({...form, additional_elements: items});
    const updateSearchTypesOrder = items => setForm({...form, search_types: items});

    const updateManufacturersOrder = items => {
        let checkAll = true;

        _.find(items,function(element) {
            if(!element.checked) {
                checkAll = false;
            }
        })
        setForm({...form, manufacturers: items});
        setManufacturersAll(checkAll);
    }

    const updateBrandsOrder = items => {
        let newGroups = groups;
        newGroups = _.map(newGroups,function(group){
            if(group.id == parseInt(selected.groupId)){
                group.sections = _.map(group.sections,function(section){
                    if(section.id == parseInt(selected.sectionId)) {
                        let checkedElemetns = _.find(items, (element) => {return element.checked == true ? element: null}) ? true : false;
                        section.checked = checkedElemetns ? true : false;
                        section.brands = items;
                    }
                    return section;
                })
            }
            return group;
        });
        setGroups(newGroups);
        setShowBrands(items);
    }
    //  ???
    const onCheckAllBrands = (property, event) => {
        let elements = {...this.state[property]};
        let elementName = property + "All";
        let result = [];
        if (elements) {
            elements = _.map(elements, function (element) {
                element.checked = event.target.checked;
                return element;
            });

            result[elementName] = event.target.checked;
            result[property] = elements;
            this.setState(result);
        }
        if (property == "showBrands") {
            this.updateBrandsOrder();
        }
        if (property == "manufacturers") {
            this.updateManufacturersOrder();
        }
    }

    const onCheckAllManufactures = items => setForm({...form, manufacturers: items});

    const handleGroupClick = groupId => {       
        let group = _.find(groups, {id: groupId});
        if(group.sections && group.sections.length > 0) {
            setSections(group.sections);
        }else {
            getSections(groupId);
        }

        setBrandsHidden(true);
        setSelected({
            groupId: groupId,
            sectionId: 0
        });
    }

    const getSections = group_id => {
        setSectionLoad(true);
        props.getSections(group_id).then((response) => {
            setSectionLoad(false);
            if (response.status == 200) {
                let newSections = response.data;
                let newGroups = {...groups};
                newGroups = _.map(groups, function (element) {
                    if (element.id == group_id) {
                        element.sections = newSections;
                    }
                    return element;
                })
                setSections(newSections);
                setGroups(newGroups);
            }
        });
    }

    const handleSectionClick = sectionId => {
        let newSelected = {...selected};
        if(newSelected.sectionId != sectionId) {
            newSelected.sectionId = sectionId;
            let group = _.find({...groups},{id: newSelected.groupId});
            if(group.sections && group.sections.length > 0) {
                let section = _.find(group.sections,{id: newSelected.sectionId});
                if(section.brands)
                    setShowBrands(section.brands)
                else
                    getBrandsForSection(newSelected.sectionId)
            }
            setSelected(newSelected);
            setBrandsHidden(false);
        }else{
            newSelected.sectionId = 0;
            setBrandsLoad(true);
            setSelected(newSelected);
        }
    }

    const getBrandsForSection = section_id => {
        let newGroups = JSON.parse(JSON.stringify(groups));
        setBrandsLoad(true);
        props.getBrands(section_id).then((response) => {
            setBrandsLoad(false);
            if (response.status == 200) {
                let brands = response.data;
                if (brands && brands.length > 0) {
                    newGroups = _.map(newGroups, function (element) {
                        if (element.id == selected.groupId) {
                            let sections = element.sections;
                            sections = _.map(sections, function (section) {
                                if (section.id == section_id) {
                                    section.checked = _.find(brands, (element) => {return element.checked == true ? element: null}) ? true : false;
                                    section.brands = brands;
                                }
                                return section;
                            })
                            element.sections = sections;
                        }
                        return element;
                    })
                }
                setShowBrands(brands);
                setGroups(groups);
            }
        });
    }

    const updateGroupsOrder = items => setGroups(items);

    const updateSectionOrder = items => {
        let newGroups = [...groups];
        newGroups = _.map(newGroups, function (group) {
            if (group.id == parseInt(selected.groupId)) {
                let orderedSections = [];
                _.map(items, function(item){
                    orderedSections.push(_.find(group.sections, {id : item.id}));
                });
                group.sections = orderedSections;
            }
            return group;
        })
        setGroups(newGroups);
        setSections(items);
    }

    const closeModal = () => {
        props.onHide();
        setBrandsHidden(true);
        setSelected({
            groupId: 0,
            sectionId: 0
        });
    }

    alertify.defaults = {
        notifier: {
            position: 'top-right',
            delay: 5
        }
    }

    const groupsList = _.filter(groups, element => true);
        
    const newGroups = _.filter(_.find(groups, {id: selected.groupId}) ? _.find(groups, {id: selected.groupId}).sections : {}, function () {
        return true;
    });

    let found = "";
    if (selected.groupId && newGroups.length > 0) {
        found = _.find(newGroups, {id: selected.sectionId});
        found = found ? `for ${found.name}` : ""
    }

    const modalTitle = currentElement ? currentElement.title + " " + currentElement.name : '';

    return (
        <Fragment>
            <Loading loading={loading}/>
            <Modal size="lg" title={modalTitle} type="dark" active={props.show} closeModal={closeModal}>
                <Tabs>
                    <TabList>
                        <Tab>General</Tab>
                        <Tab>Group/Section/Brand</Tab>
                        <Tab>Vehicle Manufacturers</Tab>
                    </TabList>

                    <TabPanel>
                        <div className="grid">
                            <div className="grid__row">
                                <div className="grid__column grid__column--7">
                                    <PreferencesForm 
                                        handleSwitchChange={handleSwitchChange}
                                        form={form}
                                        isBranch={props.isBranch}
                                        showElements={
                                            userPreferencesForm(() => {
                                                let disabled = [];
                                                if(!(typeof props.adminSetting === 'boolean' && props.adminSetting === true) ) 
                                                    disabled.push('admin');
                                                if(!(typeof props.isCustomer === 'boolean' && props.isCustomer === true))
                                                    disabled.push('enable_request_call_btn');                                                    
                                                return disabled;
                                            })
                                        } 
                                    />
                                </div>
                                <div className="grid__column grid__column--5">
                                    <Block type="4" title="Additional elements to show in search results table">
                                        <CustomSortable items={form.additional_elements}
                                                        disabled={false}
                                                        callBack={updateAdditionalElementsOrder}
                                                        onChangeCheckBox={handleAdditionalElementChange}/>
                                    </Block>
                                    <Block type="4" title="Search types" style={{marginTop: "24px"}}>
                                        <CustomSortable items={form.search_types}
                                                        disabled={false}
                                                        callBack={updateSearchTypesOrder}
                                                        onChangeCheckBox={handleSearchTypeChange}/>
                                    </Block>
                                    {
                                        ( props.isBranch && form.account_selection == "1" ) &&
                                        <Block type="4" title="Select Branches" style={{marginTop: "24px"}}>
                                            <ul className="sortable">
                                                {
                                                    _.map(props.branches, (branch) => {
                                                        return <li key={branch.id} className={"sortable__item"}>
                                                                    <span className="sortable__name">{branch.name}</span>
                                                                    {
                                                                        // selectedGroup
                                                                        <CustomCheckBox
                                                                            name={branch.id}
                                                                            checked={(_.find(form?.branches_elements, item => parseInt(item) === branch.id) ? true : false)}
                                                                            onChange={handleBranchesElementChange.bind(this, branch.id)}
                                                                        />
                                                                    } 
                                                                </li>
                                                    })
                                                }
                                            </ul>
                                        </Block>
                                    }
                                </div>
                            </div>
                        </div>
                    </TabPanel>
                    <TabPanel>
                        <div className="grid">
                            <div className="grid__row">
                                <div className="grid__column grid__column--4">
                                    <Block type="4" title="Groups">
                                        <CustomSortable items={groupsList.length ? groupsList : []}
                                                        disabled={false}
                                                        selectedId={selected.groupId}
                                                        callBack={updateGroupsOrder}
                                                        onClick={handleGroupClick}/>
                                    </Block>
                                </div>
                                <div className="grid__column grid__column--4">
                                    <Block type="4" title="Sections">
                                        {
                                            selected.groupId && sections ?
                                                <CustomSortable
                                                    items={sections}
                                                    disabled={false}
                                                    // maxHeight = {"570px"}
                                                    selectedId={selected.sectionId}
                                                    callBack={updateSectionOrder}
                                                    onClick={handleSectionClick}
                                                />
                                            : <div className={"dragable-list-elements-wrapper-limb"}></div>
                                        }
                                    </Block>
                                </div>
                                <div className="grid__column grid__column--4">
                                    <Block type="4" title="Brands">
                                        {
                                            brandsHidden ?
                                                <div className={"dragable-list-elements-wrapper-limb"}></div>
                                            :   <div>
                                                <CustomLoading loading={brandsLoad} color={''}/>
                                                {
                                                    <CustomSelectAllAble
                                                        // ===> Custom Check All Props Begin
                                                        cheeck_all_name={"showBrands"}
                                                        onCheckAll={updateBrandsOrder}
                                                        // <=== Custom Check All Props End

                                                        // ===> Custom Sortable Props Begin
                                                        items={showBrands}
                                                        disabled={false}
                                                        callBack={updateBrandsOrder}
                                                        onChangeCheckBox={setShowBrands}
                                                        // <=== Custom Sortable Props End
                                                    />
                                                }
                                            </div>
                                        }
                                    </Block>
                                </div>
                            </div>
                        </div>
                    </TabPanel>
                    <TabPanel>
                        <div className="grid">
                            <div className="grid__row">
                                <div className="grid__column grid__column--4">
                                    <Block type="4" title="Manufacturers">
                                    <CustomSelectAllAble
                                        // ===> Custom Check All Props Begin
                                        cheeck_all_name={"manufacturers"}
                                        onCheckAll={onCheckAllManufactures}
                                        // <=== Custom Check All Props End

                                        // ===> Custom Sortable Props Begin
                                        items={form.manufacturers}
                                        disabled={false}
                                        callBack={updateManufacturersOrder} 
                                        onChangeCheckBox={handleManufacturersChange}
                                        // <=== Custom Sortable Props End
                                    />
                                    </Block>
                                </div>
                            </div>
                        </div>
                    </TabPanel>
                    <div className="modal__actions">
                        <CustomButton
                            title={"Cancel"}
                            type={"lightgray"}
                            onClick={closeModal}
                            margins={['mr-8']}/>
                        <CustomButton
                            title={"Save"}
                            type={"blue"}
                            onClick={handleSaveClick} />
                    </div>
                </Tabs>
            </Modal>
        </Fragment>
    );
}

export default UserPreferences