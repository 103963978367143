import React, { Component } from "react";
// import "admin/administrator/GeneralClientOrBranchStatistics/index.css";
import _ from "lodash";

export default class VrmLookups extends Component
{
    constructor(props)
    {
        super(props);
     }

    render()
    {
        return (
            <div className="flex mt-24">
                <div className="flex">
                    <i className={'icon icon-car-front fs-24'}></i>
                    <div>VRM Lookups</div>
                </div>
                <p className="fw-700">{this.props.vrmLookups}</p> 
            </div>
        );
    }

}