import React from 'react';
import "components/DownloadSamplePriceFile.css";
import {CSVLink, CSVDownload} from "react-csv";

const exampleProducts = [
    ["Part Number", "Price"],
    ["123321SS", 122],
    ["DFS22212", 150],
    ["DFS22212", 120]
]

const DownloadSamplePriceFile = (props) => {
    return (
        <div className={"sample-wrap"}>
            <i className="fas fa-download"></i>
            <CSVLink
                className={"sample-btn"}
                data={exampleProducts}
                filename={"SampleFile.csv"}
            >
                Download Sample Price File
            </CSVLink>
        </div>
    )
}

export default DownloadSamplePriceFile;