import React, {useState, useEffect, Fragment} from "react";
import CustomCheckBox from "admin/administrator/CustomCheckBox";
import CustomModal from "admin/administrator/customModal";
import CustomButton from "admin/administrator/CustomButton";
import CustomLoading from "admin/administrator/CustomLoading";
// import "admin/administrator/AddPriceLevelExceptions.css";
import axios from "axios1";
import _ from "lodash";
import Modal from "components/Modal";
import Block from "components/Block";

const AddPriceLevelExceptions = (props) => {
    const [loading, setLoading] = useState({
        groups: false,
        sections: false,
        brands: false,
        priceLevels: false
    })
    const [show, setShow] = useState(false);
    const [sections, setSections] = useState([]);
    const [brands, setBrands] = useState([]);
    const [priceLevels, setPriceLevels] = useState([]);
    const [groups, setGroups] = useState([]);
    const [selectedGroup, setSelectedGroup] = useState(null);
    const [selectedSection, setSelectedSection] = useState(null);
    const [selectedBrand, setSelectedBrand] = useState(null);
    const [selectedPricingLevel, setSelectedPricingLevel] = useState(null);
    const [defaultPriceLevelName, setDefaultPriceLevelName] = useState(null);

    const closeModal = () => {
        setShow(false);
        setSections([]);
        setBrands([]);
        setPriceLevels([]);
        setSelectedGroup(null);
        setSelectedSection(null);
        setSelectedBrand(null);
        setSelectedPricingLevel(null);
    }

    const getGroups = () => {
        if(groups && groups.length > 0) return;
        setLoading({...loading, groups: true});
        axios.get("catalogue/groups")
            .then((response) => {
                setGroups(response.data);
                setLoading({...loading, groups: false});
            });
    }

    //---> Select handlers Begin <---

    const selectGroup = (id) => {
        if (selectedGroup != id || selectedGroup == null) {
            setSelectedGroup(id);
            setSelectedSection(null);
            setSelectedBrand(null);
            setSelectedPricingLevel(null);
            setBrands(null);
            getSectionByGroup(id);
        }
    }

    const selectSection = (section) => {
        if (!selectedSection || selectedSection.id != section.id) {
            setSelectedSection(section)
            setSelectedBrand(null);
            setSelectedPricingLevel(null);
            getBrandsBySection(section.id);
        }
    }

    const selectBrand = (brand) => {
        if (!selectedBrand || selectedBrand.id != brand.id) {
            setSelectedBrand(brand);
            setSelectedPricingLevel(null);
            setPriceLevels([]);
            getPriceLevels(brand.id);
        }
    }

    const selectPricingLevel = (priceLevel, e) => {
        e.preventDefault();
        let updatedPricingLevel = {};
        if (selectedPricingLevel == null) {
            priceLevel.price_level_id = priceLevel.id;
            updatedPricingLevel = priceLevel;
        } else {
            updatedPricingLevel = selectedPricingLevel.price_level_id == priceLevel.id ? null : priceLevel;
            if(updatedPricingLevel != null) {
                updatedPricingLevel.price_level_id = priceLevel.id;
            }
        }
       setSelectedPricingLevel(updatedPricingLevel);
    }

    
    const savePriceLevelExceptions = () => {
        if (!selectedPricingLevel) {
            return false;
        }

        let data = {
            "section_id": selectedSection.id,
            "brand_id": selectedBrand.id,
            "price_level_id": selectedPricingLevel.price_level_id,
            "brand": selectedBrand.name,
            "section": selectedSection.name,
            "price_level": selectedPricingLevel.name,
        }
        props.save(data)
        closeModal();
    }

    //<--- Select handlers End --->

    //---> Get Data Begin  <---

    const getSectionByGroup = (id) => {
        setLoading({...loading, sections: true});
        axios.get("catalogue/sections-by-group/" + id)
            .then((response) => {
                setSections(response.data.sections);
                setLoading({...loading, sections: false});
            });
    }

    const getBrandsBySection = (id) => {
        setLoading({...loading, brands: true});
        axios.get("catalogue/brands-by-section/" + id)
            .then((response) => {
                setBrands(response.data.brands)
                setLoading({...loading, brands: false});
            });
    }

    const getPriceLevels = (brand_id) => {
        setLoading({...loading, priceLevels: true});
        // let savedPriceLevels = props.savedPriceLevels;
        // let selectedPricingLevel = _.find(savedPriceLevels, function (element) {
        //     return (element.section_id == selectedSection.id && element.brand_id == brand_id) ? true : false;
        // })
        axios.get("catalogue/price-levels")
            .then((response) => {
                setPriceLevels(response.data);
                let defaultPriceLevelName = _.find(priceLevels, {default: 1});
                defaultPriceLevelName = defaultPriceLevelName ? defaultPriceLevelName.name : "";
                // if (selectedPricingLevel) {
                //     setSelectedPricingLevel(selectedPricingLevel)
                // }
                setDefaultPriceLevelName(defaultPriceLevelName);
                setLoading({...loading, priceLevels: false});
            });
    }

    //<--- Get Data End --->

    return (
        <Fragment>
            <CustomButton title={[<i class="fas fa-plus"></i>, " Add Pricing Level Exception"]} onClick={() => {
                setShow(true)
                getGroups()
            }} type={"blue"}/>
            <Modal size="xl" title={"add pricing level exceptions"} type="light" active={show} closeModal={() => closeModal()}>
                <div className="grid">
                    <div className="grid__row">
                        <div className="grid__column grid__column--3">
                            <Block type="4" title="Groups">
                                <ul className="sortable">
                                    {
                                        _.map(groups, (group) => {
                                            return <li key={group.id} onClick={selectGroup.bind(this, group.id)} className={"sortable__item"}>
                                                        <img src={group.image} className="sortable__image"/>
                                                        <span className="sortable__name">{group.name}</span>
                                                        {
                                                            <CustomCheckBox name={group.name} checked={group.id == selectedGroup ? true : false}/>
                                                        } 
                                                    </li>
                                        })
                                    }
                                </ul>
                            </Block>
                        </div>
                        <div className="grid__column grid__column--3">
                            {
                                sections && sections.length > 0 ?
                                    <Block type="4" title="Sections">
                                        <ul className="sortable">
                                            {
                                                _.map(sections, (section) => {
                                                    return <li key={section.id} onClick={selectSection.bind(this, section)} className={"sortable__item"}>
                                                            <span className="sortable__name">{section.name}</span>
                                                            {
                                                                <CustomCheckBox name={section.name} checked={selectedSection && section.id == selectedSection.id ? true : false}/>
                                                            } 
                                                    </li>
                                                })
                                            }
                                        </ul>
                                    </Block> : null
                            }
                        </div>
                        <div className="grid__column grid__column--3">
                            {
                                brands && brands.length > 0 ?
                                    <Block type="4" title="Brands">
                                        <ul className="sortable">
                                            {
                                                _.map(brands, (brand) => {
                                                    return <li key={brand.id} onClick={selectBrand.bind(this, brand)} className={"sortable__item"}>
                                                            <span className="sortable__name">{brand.name}</span>
                                                            {
                                                                <CustomCheckBox name={brand.name} checked={selectedBrand && brand.id == selectedBrand.id ? true : false}/>
                                                            } 
                                                    </li>
                                                })
                                            }
                                        </ul>
                                    </Block> : null
                            }
                        </div>
                        <div className="grid__column grid__column--3">
                            {
                                priceLevels && priceLevels.length > 0 ?
                                    <Block type="4" title="Price Levels">
                                        <ul className="sortable">
                                            {
                                                _.map(priceLevels, (priceLevel) => {
                                                    return <li key={priceLevel.id} onClick={selectPricingLevel.bind(this, priceLevel)} className={"sortable__item"}>
                                                            <span className="sortable__name">{priceLevel.name}</span>
                                                            {
                                                                <CustomCheckBox
                                                                    name={priceLevel.name}
                                                                    checked={selectedPricingLevel && selectedPricingLevel.price_level_id == priceLevel.id ? true : false}
                                                                />
                                                            } 
                                                    </li>
                                                })
                                            }
                                        </ul>
                                    </Block> : null
                            }
                        </div>
                    </div>
                </div>
                <div className="modal__actions">
                    <CustomButton title={"Cancel"} type={"lightgray"} margins={['mr-8']} onClick={() => closeModal()}/>
                    <CustomButton title={"Save"} type={"blue"} onClick={savePriceLevelExceptions.bind(this)}/>
                </div>
            </Modal>
        </Fragment>
    )
}

export default AddPriceLevelExceptions;