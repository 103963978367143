import React, { Fragment, useState, useEffect, useCallback} from "react";
import CustomReactTable from "admin/administrator/CustomReactTable.js";
import CustomTotalLogins from "admin/administrator/CustomTotalLogins.js";
import CustomVRMLookups from "admin/administrator/CustomVRMLookups.js";
import CustomOrders from "admin/administrator/CustomOrders.js";
import axios from "axios1";
import _ from "lodash";
// import "admin/administrator/BranchLoginsComponent.css";

const BranchLoginsComponent = (props) => {
    const [data, setData] = useState([]);
    const [page, setPage] = useState(1);
    const [pages, setPages] = useState(0);
        
    const columns = React.useMemo(
        () => [
            {
                Header: "Users",
                accessor: "login",
                className: "textLeft",
                disableSortBy: true,
            },
            {
                Header: "Total Logins",
                accessor: "logins",
                className: "textRight",
                disableSortBy: false,
                Cell: ({value, row}) => (<CustomTotalLogins value={value} original={row.original} branch={props.branch} date={props.date}/>)
            },
            {
                Header: "VRM Lookups",
                accessor: "lookups",
                className: "textRight",
                disableSortBy: false,
                Cell: ({value, row}) => (<CustomVRMLookups value={value} original={row.original} branch={props.branch} date={props.date}/>)
            },
            {
                Header: "Orders",
                accessor: "amount",
                className: "textRight",
                disableSortBy: false,
                Cell: ({value, row}) => (<CustomOrders value={value} original={row.original} branch={props.branch} date={props.date} />)
            },
            {
                Header: "Cost",
                accessor: "cost",
                className: "textRight",
                disableSortBy: false,
                Cell: ({value}) => (value.toFixed(2))
            },
        ]
    );

    const getBranchLogins = useCallback( ( branch_id, date, sortBy = false, search = '', page = 1) => {
        //Remove server sort
        let id = sortBy ? sortBy.id : '';
        let desc = sortBy ? sortBy.desc : '';
        axios.get("stats/accounts/" + branch_id + "/logins? page=" + page + "&search=" + search + "&sort=" + id + "&desc=" + desc, {params: {date: date}} )
        .then((response) => {
            if (response.data.success) {
                setData(response.data.data.logins);
                setPage(page);
                setPages(response.data.data.pages);
            }
        })
    }, []);

    useEffect(() => {
        getBranchLogins(props.branch.id, props.date);
    }, props.date);

    return  (<div className={"branch-logins-component-wrapper"}>
                <CustomReactTable columns={columns}
                    pagination={true}
                    page={page}
                    pages={pages}
                    getData={getBranchLogins.bind(this, props.branch.id, props.date)}
                    data={data}
                    searchDisabled={false}
                    dsiabledSort={false}
                />
            </div>);
}

export default BranchLoginsComponent;