import React, {useState, useEffect} from "react";
import FormInput from "admin/administrator/FormInput/FormInput.component";
import FormSelect from "admin/administrator/FormSelect/FormSelect.component";
import axios from "axios1";
import Block from "components/Block";

export default function BranchDetails(props) {
    const [branch, setBranch] = useState({
        name: "",
        contact_name: "",
        address_1: "",
        address_2: "",
        town: "",
        county: "",
        postal_code: "",
        phone: "",
        email: "",
        account_number: "",
        vrm_country: ""
    });

    const [vrmCountries, setVrmCountries] = useState();

    const validateConf = {
        name: (value) => {
            if(value === "") {
                return "The branch name field is required.";
            }
            
            let format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?]+/;
            if(format.test(value)) {
                return "Invalid Character(s)";
            }
            
            validateByRow('name').then( messages => props.setErrors({...props.errors, name: messages[0]}));
        },
        contact_name: value => {
            if (!value) {
                return "The contact name field is required.";
            }
        },
        address_1: value => {
            if (!value) {
                return "The address 1 field is required.";
            }
        },
        town: value => {
            if (!value) {
                return "The town field is required.";
            }
        },
        phone: value => {
            if (!value) {
                return "The phone field is required.";
            }
        },
        email: (value) => {
            const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            
            if (value === "") {
                return "";
            }
            
            if(!re.test(String(value).toLowerCase())) {
                return "The email address is not valid!";
            }
                
            validateByRow('email').then( messages => props.setErrors({...props.errors, email: messages[0]}));
        }        
    };

    const handleFromBlur = (property, value) => {
        const errors = { ...props.errors };
        if(property) {
            errors[property] = validateConf[property](value) ?? "";
        }
        
        props.setErrors(errors)
    }

    const validateByRow = async (property) => {
        return await axios.post("catalogue/validate-account", { account: props.branch, properties: [property] } )
        .then((response) => {
            return (response.data.success && response.data?.errors[property]) ? response.data.errors[property] : "";
        });
    }

    const handleInputChange = (name, event) => {
        let branch2 = {...branch};
        branch2[name] = event.target.value;
        if (typeof branch2.vrm_country === 'undefined' || branch2.vrm_country === "") {
            branch2.vrm_country = 1;
        }
        setBranch(branch2);
        props.setBranch(branch2);
    }

    useEffect(() => {
        if(props.branch != branch) {
            setBranch(props.branch);
        }else {
            return;
        }
    }, [props.branch]);

    useEffect(() => {
        axios.get("catalogue/get-vrm-countries")
            .then((response) => {
                if (response.statusText === "OK") {
                    setVrmCountries(response.data);
                }
            })
    }, []);

return <Block title={"branch details"} type={"1"}>
                <div className="branch-details">
                    <FormInput
                        name='name'
                        type='text'
                        placeholder='Branch Name*'
                        error={ props.errors.name }
                        value={branch.name}
                        onChange={handleInputChange.bind(null, "name")}
                        handleBlur={e => handleFromBlur("name", e.target.value)}
                        required
                        className="bordered"
                    />
                    <FormInput
                        name='account_number'
                        type='text'
                        placeholder='Account No.'
                        error={""}
                        value={branch.account_number}
                        onChange={handleInputChange.bind(this, "account_number")}
                        className="bordered"
                    />
                    <FormInput
                        name='contact_name'
                        type='text'
                        placeholder='Contact Name*'
                        error={ props.errors.contact_name }
                        value={branch.contact_name}
                        onChange={handleInputChange.bind(null, "contact_name")}
                        handleBlur={e => handleFromBlur("contact_name", e.target.value)}
                        required
                        className="bordered"
                    />
                    <FormInput
                        name='address_1'
                        type='text'
                        placeholder='Address Line 1*'
                        error={ props.errors.address_1 }
                        value={branch.address_1}
                        onChange={handleInputChange.bind(null, "address_1")}
                        handleBlur={e => handleFromBlur("address_1", e.target.value)}
                        required
                        className="bordered"
                    />
                    <FormInput
                        name='address_2'
                        type='text'
                        placeholder='Address Line 2'
                        error={ props.errors.address_2 }
                        value={branch.address_2}
                        onChange={handleInputChange.bind(null, "address_2")}
                        className="bordered"
                    />
                    <FormInput
                        name='town'
                        type='text'
                        placeholder='Town*'
                        error={ props.errors.town }
                        value={branch.town}
                        onChange={handleInputChange.bind(null, "town")}
                        handleBlur={e => handleFromBlur("town", e.target.value)}
                        className="bordered"
                        required
                    />
                    <FormInput
                        name='county'
                        type='text'
                        placeholder='County'
                        error={ props.errors.county }
                        value={branch.county}
                        onChange={handleInputChange.bind(null, "county")}
                        className="bordered"
                    />
                    <FormInput
                        name='postal_code'
                        type='text'
                        placeholder='Post Code / Zip'
                        error={ props.errors.postal_code }
                        value={branch.postal_code}
                        onChange={handleInputChange.bind(null, "postal_code")}
                        className="bordered"
                    />
                    <FormInput
                        name='phone'
                        type='text'
                        placeholder='Phone Number*'
                        error={ props.errors.phone }
                        value={branch.phone}
                        onChange={handleInputChange.bind(null, "phone")}
                        handleBlur={e => handleFromBlur("phone", e.target.value)}
                        className="bordered"
                        required
                    />
                    <FormInput
                        name='email'
                        type='email'
                        placeholder='Email'
                        error={ props.errors.email }
                        value={branch.email}
                        onChange={handleInputChange.bind(null, "email")}
                        handleBlur={e => handleFromBlur("email", e.target.value)}
                        className="bordered"
                    />
                    {vrmCountries ?
                        <div>
                            <label>VRM Country</label>
                            <FormSelect
                                options={vrmCountries}
                                selectedId={branch.vrm_country ?? 1}
                                handleChange={handleInputChange.bind(null, "vrm_country")}
                            />
                        </div> : null
                    }
                </div>
            </Block>
}
