import _ from "lodash";
var store = require('store');
var moment = require('moment');

const compareList = (state = [], action) => {
    switch (action.type) {
        case 'SET_COMPARE':
            return action.payload
        case 'SET_COMPARE_AFTER_LOGIN':
            return action.payload;
        case 'ADD_COMPARE':
            return addCompare(state, action)
        case 'CLEAR_COMPARE_LIST':
            return clearCompareList(state, action);
        case 'CLEAR_COMPARE_LIST_ELEMENT':
            return clearCompareListElement(state, action);
        default:
            return state
    }
}

let addCompare = (state, action) => {
    let newArr = _.slice(state);
    
    let index = _.findIndex(newArr, (element) => {
        return element.id == action.product.id;
    });
    if(index == -1) {
        newArr.push({
            id: action.product.id,
            product: action.product.product,
            section_lookup_id: action.section_lookup_id,
            created_at: moment().format('YY-MM-DD hh:mm:ss'),
        })
    }
    return newArr;
}

let clearCompareList = (state, action) => {
    return [];
}

let clearCompareListElement = (state, action) => {
    let newArr = state.filter((key) => {
        return !(key.id === action.product.id)
    })

    return newArr;
}

export default compareList