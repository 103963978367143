import React, {Fragment} from "react";
import CustomContainer from "custom_components/CustomContainer";
import CustomButton from "admin/administrator/CustomButton";
import PartNumberPrefix from "admin/administrator/account/PartNumberPrefix";
import Pagination from "components/Pagination";
import CustomToggleRadioButton from "custom_components/CustomToggleRadioButton";
import FormInput from "admin/administrator/FormInput/FormInput.component";

import Modal from "components/Modal";

const UploadedProductsFileModal = (props) => {
    return (
        <Fragment>
            <Modal size="sm" title={"calculate base price"} type="light" active={props.showModal} closeModal={() => props.closeModal()} scrollable={true}>
                <div className={"inputs-wrapper flex mb-16"}>
                    <div className="priceUploadFile">
                        <CustomToggleRadioButton checked={parseInt(props.priceLevelValueTypeId)}
                                                    handleSwitchChange={props.handleSwitchChange}
                                                    items={[
                                                        {title: "Margin", value: 1},
                                                        {title: "Mark Up", value: 2}
                                                    ]}/>
                    </div>
                    <div className="flex">
                        <i onClick={props.increaseCounter} className="fas fa-plus mr-8"></i>
                        <div style={{width: "70px"}}>
                            <FormInput
                                name='name'
                                type='number'
                                placeholder=''
                                value={props.counter}
                                error={""}
                                onChange={props.changeCounter}/>
                        </div>
                        <i className="fas fa-percent ml-8 cursor-pointer"></i>
                    </div>
                </div>
                <hr/>
                <div className={"flex flex-justify-end mb-16 mt-16"}>
                    <FormInput
                            name='name'
                            type='text'
                            placeholder='Enter Part Number'
                            error={""}
                            onChange={ e => props.handleFilterProducts(e)}/>
                </div>
                {
                    props.brands && props.brands.length > 0 ?
                        <div className={"existed-brands mb-24"}>
                            <p>The following brands are already in the system:</p>
                            <ul>
                                {
                                    props.brands.map((brand) => {
                                        return <li><strong>{brand}</strong></li>
                                    })
                                }
                            </ul>
                            <p>Do you wish to overwrite these prices?</p>
                            <div style={{marginTop: '10px'}}>
                                <CustomButton type={"green"} title={"Yes"} margins={['mr-8']}
                                                onClick={props.sayYes}/>
                                <CustomButton type={"lightgray"} title={"No"} onClick={props.sayNo}/>
                            </div>
                        </div> : null
                }
                <div className="table">
                    <div className="table__content">
                        <table className={"table__table"}>
                            <thead className="table__thead">
                                <tr className="table__tr">
                                    <th className="table__th">Part Number</th>
                                    <th className="table__th">Price</th>
                                </tr>
                            </thead>
                            <tbody className="table__tbody">
                            {
                                props.currentProducts.map((product) => {
                                    return <tr className="table__tr">
                                        <td className="table__td">{product[0]}</td>
                                        <td className="table__td">{parseFloat(product[2]).toFixed(2)}</td>
                                    </tr>
                                })
                            }
                            </tbody>
                        </table>
                    </div>
                </div>
                <Pagination
                    totalRecords={props.totalProducts}
                    pageLimit={10}
                    pageNeighbours={1}
                    onPageChanged={props.onPageChanged}
                />
                <div className="modal__actions">
                    <CustomButton title={"Cancel"} type={"lightgray"} onClick={props.closeModal}/>
                    <CustomButton title={"Save"} type={"blue"} onClick={props.checkPartNumberExistence} margins={['ml-8']}/>
                </div>
            </Modal>
            {/* <Modal
                {...props}
                show={props.showModal}
                size="sm"
                centered
                onHide={props.closeModal}
                onEnter={""}
            >
                <Modal.Header>
                    <Modal.Title>
                        <div className={"dashboard-page-navigations modal-pref-p-nav user-preferences-header"}>
                            <span> {"Calculate Base Price"}</span>
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className={"inputs-wrapper"}>
                        <div style={{width: '74%'}} className="priceUploadFile">
                            <CustomToggleRadioButton checked={parseInt(props.priceLevelValueTypeId)}
                                                     handleSwitchChange={props.handleSwitchChange}
                                                     items={[
                                                         {title: "Margin", value: 1},
                                                         {title: "Mark Up", value: 2}
                                                     ]}/>
                        </div>
                        <span>
                                <i onClick={props.increaseCounter} className="fas fa-plus fa-2x"></i>
                            </span>
                        <span>
                            <input type={"number"} value={props.counter} className={"perc-input form-control"}
                                   onChange={props.changeCounter}/>
                        </span>
                        <span>
                                <i className="fas fa-percent fa-2x"></i>
                            </span>
                    </div>
                    <hr/>
                    <div className={"pnp-wrap"}>
                        <div class="form-group row text-right">
                            <label for="SEARCH" class="col-sm-5 col-form-label">SEARCH</label>
                            <div class="col-sm-7">
                                <input type={"text"}
                                       placeholder={"Enter Part Number"}
                                       style={{marginLeft: 'auto'}}
                                       className={"form-control"}
                                       onChange={props.handleFilterProducts}
                                />

                            </div>
                        </div>

                    </div>
                    <hr/>
                    {
                        props.brands && props.brands.length > 0 ?
                            <div className={"existed-brands"}>
                                <p>The following brands are already in the system:</p>
                                <ul>
                                    {
                                        props.brands.map((brand) => {
                                            return <li><strong>{brand}</strong></li>
                                        })
                                    }
                                </ul>
                                <p>Do you wish to overwrite these prices?</p>
                                <div style={{marginTop: '10px'}}>
                                    <CustomButton type={"green"} title={"Yes"} margins={['mr-2']}
                                                  onClick={props.sayYes}/>
                                    <CustomButton type={"lightgray"} title={"No"} onClick={props.sayNo}/>
                                </div>
                                <hr/>
                            </div> : null
                    }
                    <table className={"custom-table table table-bordered products-from-file"}>
                        <thead>
                        <tr>
                            <th>Part Number</th>
                            <th>Price</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            props.currentProducts.map((product) => {
                                return <tr>
                                    <td>{product[0]}</td>
                                    <td>{parseFloat(product[2]).toFixed(2)}</td>
                                </tr>
                            })
                        }
                        </tbody>
                    </table>
                    <Pagination
                        totalRecords={props.totalProducts}
                        pageLimit={10}
                        pageNeighbours={1}
                        onPageChanged={props.onPageChanged}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <CustomButton title={"Cancel"} type={"lightgray"} onClick={props.closeModal}/>
                    <CustomButton title={"Save"} type={"blue"} onClick={props.checkPartNumberExistence}/>
                </Modal.Footer>
            </Modal> */}
        </Fragment>
    )
}

export default UploadedProductsFileModal;