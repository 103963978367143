import React, {Fragment, useState, useEffect} from 'react';
import "./SliderSettings.css";
import CustomButton from "admin/administrator/CustomButton";
import FormInput from "admin/administrator/FormInput/FormInput.component";
import Loading from "admin/administrator/LoadingComponent/Loading.component";
import MessageBox from "components/MessageBox";
import axios from "axios1";
import alertify from 'alertifyjs';

function SliderSettings() {
    const [duration, setDuration] = useState(0);
    const [format, setFormat] = useState("hours");
    const [formats, setFormats] = useState(['hours', 'minutes', 'seconds']);
    const [durations, setDurations] = useState([]);
    const [loading, setLoading] = useState(false);
    const [messages, setMessages] = useState({
        success: {
            type: "success",
            text: "Published successfully!",
            visible: false
        },
        warning: {
            type: "warning",
            text: "You have unpublished changes!",
            visible: false
        }
    });

    useEffect(() => {
        getSettings();
    }, [])

    function handleDurationChange(e) {
        setDuration(e.target.value);
        showMessageBox("warning");
    }

    function handleFormatChange(e) {
        let hours = 23;
        let minutes = 59;
        let seconds = 50;
        let durationsArr = [];

        setDurations([]);

        if(e.target.value == "hours") {
            for (let i = 1; i <= hours; i++) {
                durationsArr.push(i);
            }
        }

        if(e.target.value == "minutes") {
            for (let i = 1; i <= minutes; i++) {
                durationsArr.push(i);
            }
        }

        if(e.target.value == "seconds") {
            for (let i = 10; i <= seconds; i += 10) {
                durationsArr.push(i);
            }
        }
        
        setFormat(e.target.value);
        setDurations(durationsArr);
        setDuration(durationsArr[0]);
        showMessageBox("warning");
    }

    function getSettings() {
        axios.get("catalogue/get-slider-settings")
            .then((response) => {
                if(response.data) {
                    let d = response.data.duration;
                    let f = response.data.format;
                    let hours = 23;
                    let minutes = 59;
                    let seconds = 50;
                    let durationsArr = [];

                    if(f == "hours") {
                        for (let i = 1; i <= hours; i++) {
                            durationsArr.push(i);
                        }
                    }

                    if(f == "minutes") {
                        for (let i = 1; i <= minutes; i++) {
                            durationsArr.push(i);
                        }
                    }

                    if(f == "seconds") {
                        for (let i = 10; i <= seconds; i += 10) {
                            durationsArr.push(i);
                        }
                    }

                    setDuration(d);
                    setFormat(f);
                    setDurations(durationsArr);
                }
            });
    }

    function saveSettings() {
        if(duration == 0) {
            alertify.error("Please, choose duration!");
            return;
        }
        setLoading(true);
        axios.post("catalogue/save-slider-settings", {d: duration, f: format})
            .then((response) => {
                setLoading(false);
                showMessageBox("success");
            });
    }

    function showMessageBox(type) {
        let msgs = {...messages};
        Object.keys(msgs).map((key) => {
            msgs[key].visible = false;
        });
        msgs[type].visible = true;
        setMessages(msgs);
    }

    return (
        <div className="box">
            <Loading loading={loading} />
            {
                Object.keys(messages).map((key) => {
                    if(messages[key].visible == true) {
                        return <div className="box-message">
                                    <MessageBox type={messages[key].type} text={messages[key].text}/>
                                </div>
                    }
                })
            }
            <div className="box__header">
                <h3>Slider Settings</h3>
                <CustomButton type={"blue"} title={"Save"} onClick={() => saveSettings()}/>
            </div>
            <div className="box__body">
                <div className="set-settings">
                    <span>Transition Duration:</span>
                    <div className="formats-select">
                        <div className="selector">
                            <select value={format} onChange={handleFormatChange.bind(this)} className="selector__selector">
                                {
                                    formats.map((format) => {
                                        return <option value={format}>{format}</option>
                                    })
                                }
                            </select>
                        </div>
                    </div>
                    <div className="durations-select">
                        <div className="selector">
                            <select value={duration} onChange={handleDurationChange.bind(this)} className="selector__selector">
                                {
                                    durations.map((duration) => {
                                        return <option value={duration}>{duration}</option>
                                    })
                                }
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SliderSettings