import React from "react";
// import "admin/administrator/UserAddress.css";
// import "admin/administrator/CustomElements.css";
import _ from "lodash";

function UserAddress(props) {  
    if (props.withOrderInfo) {
        return (
            <div>
                <div className="flex flex-justify-between mb-24">
                    <p><span className={"fw-700"}>Order Number: {props.orderId}</span></p>
                    <p><span className={"fw-700"}>Order Date:</span> {props.orderDate}</p>
                </div>

                <div className="flex flex-align-start mb-24">
                    <div>
                        <div className="fw-700 text-left">{props.data.from}</div>
                        <ul className="text-left">
                            {
                                _.map(props.companyInfo, function (element, index) {
                                    return <li key={index} className="text-left">{element}</li>
                                })
                            }
                        </ul>
                    </div>

                    <div>
                        <div className="fw-700 text-right">{props.data.title}</div>
                        <ul>
                            {
                                _.map(props.data.info, function (element, index) {
                                    return <li key={index} className="text-right">{element}</li>
                                })
                            }
                        </ul>
                    </div>
                </div>
            </div>
        )
    } else {
        return (
            <div className="flex flex-align-start mb-24">
                <div>
                    <div className="fw-700 text-left">{props.data.title}</div>
                    <ul>
                        {
                            _.map(props.data.info, function(element, index) {
                                return <li key={index} className="text-left mt-4">{element}</li>
                            })
                        }
                    </ul>
                </div>
                {props.withOrderInfo && ( 
                    <div className="text-left">
                        <p><span className={"fw-700"}>Order Date:</span> {props.orderDate}</p>
                        <p><span className={"fw-700"}>Order ID:</span> #{props.orderId}</p>
                        <p><span className={"fw-700"}>Ordered By:</span> {props.userName}</p>
                    </div>
                )}
            </div>
        );
    }
}

export default UserAddress;