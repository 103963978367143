import React, {Component, Fragment} from "react";
import axios from "axios1";
import _ from "lodash";
import PropTypes from "prop-types";
import FormInput from "admin/administrator/FormInput/FormInput.component";
import FormSelect from "admin/administrator/FormSelect/FormSelect.component";

class AccountDetails extends Component {

    constructor(props) {
        super(props);

        this.state = {
            form: {
                orders_type: "Email",
                // price_level_id: "",
                communication_ftp_id: "1",
                communication_email_id: "1",
                name: "",
                contact_name: "",
                address_1: "",
                address_2: "",
                town: "",
                county: "",
                postal_code: "",
                phone: "",
                email: "",
                business_type_id: "",
                branch_id: "",
                account_number: "",
                vrm_country: "",
            },
            userTypes: [],
            priceLevels: [],
            ordersEmails: [],
            ordersFtps: [],
            ordersTypes: [
                {id: 1, name: "Email"},
                {id: 2, name: "Ftp"},
            ],
            branches: [],
            selectBranches: true
        }

        this.validateConf = {
            name: (value) => {
                if(value === "") {
                    return "The account name field is required.";
                }

                let format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?]+/;
                if(format.test(value)) {
                    return "Invalid Character(s)";
                }
                
                this.validateByRow('name').then( messages => this.props.setErrors({...this.props.errors, name: messages[0]}));
            },           
            contact_name: value => {
                if (!value) {
                    return "The contact name field is required.";
                }
            },
            address_1: value => {
                if (!value) {
                    return "The address 1 field is required.";
                }
            },
            town: value => {
                if (!value) {
                    return "The town field is required.";
                }
            },
            phone: value => {
                if (!value) {
                    return "The phone field is required.";
                }
            },
            email: (value) => {
                const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                
                if (value === "") {
                    return "";
                }
                
                if(!re.test(String(value).toLowerCase())) {
                    return "The email address is not valid!";
                }
                    
                this.validateByRow('email').then( messages => this.props.setErrors({...this.props.errors, email: messages[0]}));
            }
        }
    }

    handleFromBlur = (property, value) => {
        const errors = { ...this.props.errors };
        if(property) {
            errors[property] = this.validateConf[property](value) ?? "";
        }

        this.props.setErrors(errors)
    }

    validateByRow = async (property) => {
        const account = this.props.account;
        account.isAccount = true;
        return await axios.post("catalogue/validate-account", { account: account, properties: [property] } )
        .then((response) => {
            return (response.data.success && response.data?.errors[property]) ? response.data.errors[property] : "";
        });
    }

    componentDidMount() {
        this.getProperties();
    }

    componentDidUpdate(prevProps) {
        if (this.props.account !== prevProps.account) {
            this.setState({form: this.props.account})
        }
    }

    getProperties() {
        let userTypes = [
            {id: 0, name: "Select Customer Type"}
        ];

        let priceLevels = [
            {id: 0, name: "Select Price Level"}
        ];

        axios.get("catalogue/user-properties")
            .then((response) => {
                this.setState({
                    branches: response.data.branches,
                    userTypes: userTypes.concat(response.data.userTypes),
                    priceLevels: priceLevels.concat(response.data.priceLevels),
                    ordersEmails: response.data.ordersEmails,
                    ordersFtps: response.data.ordersFtps,
                }, () => {
                    this.checkBusinessType();
                });
            });
    }

    handleInputChange(input, event) {
        let form = {...this.state.form};
        form[input] = event.target.value;
        if (input == "name" && form.business_type_id == "3") {
            this.props.setCurrentBranch(event.target.value);
        }
        if (typeof form.vrm_country === 'undefined' || form.vrm_country === "") {
            form.vrm_country = 1;
        }
        this.setState({form: form}, () => {
            this.props.setAccount(this.state.form);
        });
    }

    handleSelectChange(input, event) {
        this.props.setIsSupplier(event.target.options[event.target.selectedIndex].text);
        this.setState({selectBranches: true});
        if (event.target.options[event.target.selectedIndex].text == "Branch") {
            this.setState({selectBranches: false});
        }
        let form = {...this.state.form};

        form[input] = event.target.value;

        this.setState({form: form}, () => {
            this.props.setAccount(this.state.form);
        });
    }

    checkBusinessType() {
        let id = this.state.form.business_type_id;
        let userTypes = {...this.state.userTypes}
        let selectBranches = true;
        if (selectBranches = _.find(userTypes, {id: id})) {
            selectBranches = selectBranches.name == "Branch" ? false : true;
            this.setState({selectBranches: selectBranches});
        }
    }

    render() {
        let width50 = this.props.isSupplier ? {width: '49%'} : null;
        return (
            <Fragment>
                <div className="acc-details">
                    <FormSelect options={ this.state.userTypes }
                                selectedId={ this.state.form.business_type_id }
                                defaultValue={ 'Select' }
                                handleChange={ this.handleSelectChange.bind(this, "business_type_id") }
                                error={this.props.errors.business_type_id}/>
                    {
                        this.state.selectBranches &&
                            <FormSelect options={ this.state.branches }
                                        selectedId={ this.state.form.branch_id }
                                        defaultValue={ 'Select Branch' }
                                        handleChange={ this.handleInputChange.bind(this, "branch_id") }
                                        error={this.props.errors.branch_id}/>
                    }
                    {/* {
                        this.state.selectBranches ?
                            <select value={this.state.form.branch_id}
                                    onChange={this.handleInputChange.bind(this, "branch_id")}
                                    style={{marginTop: '16px', marginBottom: '16px'}} className={"selector"}>
                                <option value='' selected>Select Branch</option>
                                {
                                    this.state.branches.map((branches) => {
                                        return <option value={branches.id}
                                                    key={branches.id}>{branches.name}</option>
                                    })
                                }
                            </select> : null
                    } */}
                    <FormInput
                        name='name'
                        type='text'
                        placeholder='Account Name*'
                        error={this.props.errors.name}
                        value={this.state.form.name}
                        onChange={this.handleInputChange.bind(this, "name")}
                        handleBlur={e => this.handleFromBlur("name", e.target.value)}
                        required
                    />
                    <FormInput
                        name='account_number'
                        type='text'
                        placeholder='Account No.'
                        error={this.props.errors.account_number}
                        value={this.state.form.account_number}
                        onChange={this.handleInputChange.bind(this, "account_number")}
                    />
                    <FormInput
                        name='contact_name'
                        type='text'
                        placeholder='Contact Name*'
                        error={this.props.errors.contact_name}
                        value={this.state.form.contact_name}
                        onChange={this.handleInputChange.bind(this, "contact_name")}
                        handleBlur={e => this.handleFromBlur("contact_name", e.target.value)}
                        required
                    />
                    <FormInput
                        name='address_1'
                        type='text'
                        placeholder='Address Line 1*'
                        error={this.props.errors.address_1}
                        value={this.state.form.address_1}
                        onChange={this.handleInputChange.bind(this, "address_1")}
                        handleBlur={e => this.handleFromBlur("address_1", e.target.value)}
                        required
                    />
                    <FormInput
                        name='address_2'
                        type='text'
                        placeholder='Address Line 2'
                        error={this.props.errors.address_2}
                        value={this.state.form.address_2}
                        onChange={this.handleInputChange.bind(this, "address_2")}
                    />
                    <FormInput
                        name='town'
                        type='text'
                        placeholder='Town*'
                        error={this.props.errors.town}
                        value={this.state.form.town}
                        onChange={this.handleInputChange.bind(this, "town")}
                        handleBlur={e => this.handleFromBlur("town", e.target.value)}
                        required
                    />
                    <FormInput
                        name='county'
                        type='text'
                        placeholder='County'
                        error={this.props.errors.county}
                        value={this.state.form.county}
                        onChange={this.handleInputChange.bind(this, "county")}
                    />
                    <FormInput
                        name='postal_code'
                        type='text'
                        placeholder='Post Code / Zip'
                        error={this.props.errors.postal_code}
                        value={this.state.form.postal_code}
                        onChange={this.handleInputChange.bind(this, "postal_code")}
                    />
                    <FormInput
                        name='phone'
                        type='text'
                        placeholder='Phone Number*'
                        error={this.props.errors.phone}
                        value={this.state.form.phone}
                        onChange={this.handleInputChange.bind(this, "phone")}
                        handleBlur={e => this.handleFromBlur("phone", e.target.value)}
                        required
                    />
                    <FormInput
                        name='email'
                        type='email'
                        placeholder='Email'
                        error={this.props.errors.email}
                        value={this.state.form.email}
                        onChange={this.handleInputChange.bind(this, "email")}
                        handleBlur={e => this.handleFromBlur("email", e.target.value)}
                        required
                    />
                    {this.props.vrmCountries ?
                        <div>
                            <label>VRM Country</label>
                            <FormSelect
                                options={this.props.vrmCountries}
                                selectedId={this.state.form.vrm_country ?? 1}
                                handleChange={this.handleInputChange.bind(this, "vrm_country")}
                            />
                        </div> : null
                    }
                </div>              
                <div className="send-orders-via-wrapper">
                    {/* <select style={{width: "50%"}} value={this.state.form.orders_type}
                                        onChange={this.handleInputChange.bind(this, "orders_type")}>
                                    {
                                        this.state.ordersTypes.map((ordersType) => {
                                            return <option key={ordersType.id} value={ordersType.name}>
                                                {ordersType.name}
                                            </option>
                                        })
                                    }
                                </select> */}
                    {/* <div style={{width: "45%"}} className={"add-user-email-ftp-wrapper"}>
                        {
                            this.state.form.orders_type == "Email" ?
                                <div className={"add-user-email-ftp-item"}>
                                    <select style={{width: "100%"}}
                                            className={"add-user-email-ftp-item-select form-control"}
                                            value={this.state.form.communication_email_id}
                                            onChange={this.handleInputChange.bind(this, "communication_email_id")}>
                                        {
                                            this.state.ordersEmails.map((ordersEmail) => {
                                                return <option key={ordersEmail.id}
                                                               value={ordersEmail.id}>{ordersEmail.name}</option>
                                            })
                                        }
                                    </select>
                                </div>
                                :
                                <div className={"add-user-email-ftp-item"}>
                                    <select style={{width: "100%"}}
                                            className={"add-user-email-ftp-item-select form-control"}
                                            value={this.state.form.communication_ftp_id}
                                            onChange={this.handleInputChange.bind(this, "communication_ftp_id")}>
                                        {
                                            this.state.ordersFtps.map((ordersFtp) => {
                                                return <option key={ordersFtp.id}
                                                               value={ordersFtp.id}> {ordersFtp.name} </option>
                                            })
                                        }
                                    </select>
                                </div>
                        }
                    </div> */}
                </div>
            </Fragment>
        )
    }
}

AccountDetails.propTypes = {
    setAccount: PropTypes.func,
    account: PropTypes.object
}

export default AccountDetails;