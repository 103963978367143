import React from 'react';
// import "./Totals.css";

function Totals({title, licencesUsed, licencesCost, lookupsUsed, lookupsCost, total}) {
    return (
        <div className={"mt-24"}>
            <div className={"fw-700 mb-8"}>{title} Totals</div>
            <div>
                <div className={"flex mb-4"}>
                    <span>Licences used x {licencesUsed}</span>
                    <span>{licencesCost}</span>
                </div>
                <div className={"flex mb-4"}>
                    <span>VRM Lookups x {lookupsUsed}</span>
                    <span>{lookupsCost}</span>
                </div>
            </div>
            <div className={"flex fw-700 text-uppercase"}>
                <span>Total</span>
                <span>{total}</span>
            </div>
        </div>
    );
}

export default Totals;