import React, {useState} from 'react';
// import "admin/administrator/AccountDashboard/AccountDashboard.css";
import CustomContainer from 'custom_components/CustomContainer.js';
import TopProductSections from "admin/administrator/TopProductSections";
import TopVehicleMakes from "admin/administrator/TopVehicleMakes";
import AccountDetails from "admin/administrator/AccountDashboard/AccountDetails";
import AccountLoginsComponent from "admin/administrator/AccountLoginsComponent.js";
import CustomOrdersWithoutModal from "admin/administrator/CustomOrdersWithoutModal";
import {connect} from "react-redux";
import Block from "components/Block";

const AccountDashboard = (props) => {
    var moment = require('moment');
    return (
        <div className={'page-content admin-wrapper'}>
            <div className={"dashboard"}>
                <div className={"dashboard__left"}>
                    <AccountDetails id={props.match.params.id}></AccountDetails>
                </div>
                <div className={"dashboard__right"}>
                    <Block type={"1"} title={ `${props.match.params.name ? props.match.params.name.replace(`_deleted_${props.match.params.id}`, "")   : props.match.params.name  } users `} style={{marginBottom: "24px"}}>
                        <AccountLoginsComponent branch={props.match.params}
                                                name={props.match.params.name}
                                                date={moment(props.date).format('YYYY-MM')}/>
                    </Block>
                    <Block type={"1"} title={"orders"}>
                        <CustomOrdersWithoutModal branch={props.match.params}
                                                    date={moment(props.date).format('YYYY-MM')}/>
                    </Block>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    date: state.dashboardDate.date
})

export default connect(mapStateToProps, null)(AccountDashboard);