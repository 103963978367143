import Default from "images/default.jpg";
import LoginSmallDefault from "images/q-bg.jpg";
import LoginSmallNK from "images/nk-login-small-bg.jpg";
import LoginSmallNewNordic from "images/nordicLoginLeft.jpg";
import LoginSmallViking from "images/viking-login-small-bg.jpg";
import LoginSmallSpartan from "images/spartan-login-cover.jpg";
import LoginSmallGT from "images/gt-exhaust-logo-cover.jpg";
import LoginSmallStoneacre from "images/stoneacre_bg.jpg";
import LoginSmallEurobrake from "images/eurobrake-logo-cover.jpg";
import LoginSmallTitan from "images/titan-logo-cover.jpg";
import LoginSmallWessling from "images/wessling-login-small-bg.jpg";

let bgSmallImage = Default;
switch(process.env.REACT_APP_CLIENT){
    case "nk": 
        bgSmallImage = LoginSmallNK; 
    break;
    // case "newNordic": 
    //     bgSmallImage = LoginSmallNewNordic; 
    // break;
    // case "spartan": 
    //     bgSmallImage = LoginSmallSpartan; 
    // break;
    // case "gt": 
    //     bgSmallImage = LoginSmallGT; 
    // break;
    case "stoneacre": 
        bgSmallImage = LoginSmallStoneacre; 
    break;
    // case "eurobrake": 
    //     bgSmallImage = LoginSmallEurobrake; 
    // break;
    // case "viking": 
    //     bgSmallImage = LoginSmallViking; 
    // break;
    case "wessling": 
        bgSmallImage = LoginSmallWessling; 
    break;
    // default: break;
}

export default bgSmallImage;