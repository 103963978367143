import _ from 'lodash';
var store = require('store');
var moment = require('moment');

const shoppingCart = (state = [], action) => {
    switch (action.type) {
        case 'SET_CART':
            return action.payload
        case 'SET_CART_AFTER_LOGIN':
            return action.payload;
        case 'ADD_PRODUCT':
            return addProduct(state, action)
        case 'INCREASE_QUANTITY':
            return increaseQuantity(state, action)
        case 'DECREASE_QUANTITY':
            return decreaseQuantity(state, action)
        case 'REMOVE_PRODUCT':
            return removeProduct(state, action)
        case 'CLEAN_CART':
            return cleanCart()
        default:
            return state
    }
}

let removeProduct = (state, action) => {
    let newArr = state.filter((key) => {
        return !(key.id === action.product.id && (key?.cart?.account_id || key?.account_id) === action.product.account_id );
    })
    return newArr;
}

let isNormalInteger = (str) => {
    return /^\+?(0|[1-9]\d*)$/.test(str);
}

let addProduct = (state, action) => {
    let cart = _.slice(state);
    let lookup = action.data ? _.find(action.data, element => { return element.id == action.product.id } ).lookup : "";
    let description = action.data ? _.find(action.data, element => { return element.id == action.product.id } ).description : "";
    let index = _.findIndex(cart, (element) => {
        return element.id == action.product.id && element.account_id == action.product.account_id;
    });
    if (index != -1) {
        let quantity = cart[index].quantity + parseInt(action.product.quantity);
        cart[index].quantity = quantity > 0 ? quantity : 0;
    }else {
        cart.push({
            id: action.product.id,
            account: action.product.account,
            account_id: action.product.account_id,
            branch_id: action.product.branch_id,
            lookup:  lookup,
            section_lookup_id: parseInt(action.section_lookup_id),
            quantity: parseInt(action.product.quantity),
            stock: action.product.stock,
            description: description,
            images: "",
            created_at: moment().format('YY-MM-DD hh:mm:ss'),
            product: action.product
        });
    }
    return cart;
}

let increaseQuantity = (state, action) => {
    let cart = _.slice(state);
    let lookup = action.data ? _.find(action.data, element => { return element.id == action.product.id } ).lookup : "";
    let description = action.data ? _.find(action.data, element => { return element.id == action.product.id } ).description : "";
    let index = _.findIndex(cart, (element) => {
        return element.id == action.product.id && element.account_id == action.product.account_id;
    });
    if (index != -1) {
        // let quantity = cart[index].quantity + 1;
        // cart[index].quantity = quantity > 0 ? quantity : 0;
    }else {
        cart.push({
            id: action.product.id,
            account: action.product.account,
            account_id: action.product.account_id,
            branch_id: action.product.branch_id,
            lookup:  lookup,
            section_lookup_id: parseInt(action.section_lookup_id),
            quantity: parseInt(action.product.quantity),
            stock: action.product.stock,
            description: description,
            images: "",
            created_at: moment().format('YY-MM-DD hh:mm:ss'),
            product: action.product
        });
    }
    return cart;
}

let decreaseQuantity = (state, action) => {
    let cart = _.slice(state);
    let lookup = action.data ? _.find(action.data, element => { return element.id == action.product.id } ).lookup : "";
    let description = action.data ? _.find(action.data, element => { return element.id == action.product.id } ).description : "";
    let index = _.findIndex(cart, (element) => {
        return element.id == action.product.id && element.account_id == action.product.account_id;
    });
    if (index != -1) {
        // let quantity = cart[index].quantity - 1;
        // cart[index].quantity = quantity > 0 ? quantity : 0;
    }else {
        cart.push({
            id: action.product.id,
            account: action.product.account,
            account_id: action.product.account_id,
            branch_id: action.product.branch_id,
            lookup:  lookup,
            section_lookup_id: parseInt(action.section_lookup_id),
            quantity: parseInt(action.product.quantity),
            stock: action.product.stock,
            description: description,
            images: "",
            created_at: moment().format('YY-MM-DD hh:mm:ss'),
            product: action.product
        });
    }
    return cart;
}

let cleanCart = () => {
    return [];
}

export default shoppingCart