import React, {Component} from "react";
import {connect} from "react-redux";
import {clearCompareListElement, cleanCompare} from "redux/actions/compareList";
import {BrowserRouter as Router, Route, NavLink} from "react-router-dom";
import ComparedProduct from "components/ComparedProduct";
import CustomButton from "admin/administrator/CustomButton.js";
import Slider from "react-slick";
import { Fragment } from "react";

class CompareItemsContent extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        let compareList = this.props.compareList;
        let items = [];

        const settings = {
            dots: false,
            infinite: false,
            speed: 500,
            slidesToShow: 7,
            slidesToScroll: 1,
            arrows: false,
            draggable: false,
            autoplay: false,
            responsive: [
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        arrows: true,
                        autoplay: true,
                        draggable: true,
                        prevArrow: <button className="slick-custom-prev"><i class="fas fa-chevron-left"></i></button>,
                        nextArrow: <button className="slick-custom-next"><i class="fas fa-chevron-right"></i></button>,
                    }
                }
            ]
        };
        
        for(let i = 0; i < 8; i++) {
            items.push(<div className="compare__item compare__item--empty">
                <div className="compare__item-row"></div>
                {
                    (this.props.settings && this.props.settings.show_prices) ? <div className="compare__item-row"></div> : null
                }
                <div className="compare__item-row"></div>
                <div className="compare__item-row"></div>
                <div className="compare__item-row compare__item-row--notes"></div>
            </div>);
        }
        
        return (
            <Fragment>
                {
                compareList.length > 0 ?
                    <div className="container">
                        <div className="compare">
                            <div className="compare__header">
                                <span className="compare__heading">Compare</span>
                                <span className="compare__clear" onClick={this.props.clearCompareList}><i class="fas fa-times"></i> Remove all</span>
                            </div>
                            <div className="compare__body">
                                <div className="compare__stripes">{items}</div>
                                <div className="compare__item compare__item--main">
                                    <div className="compare__item-row">
                                        <span>Product Specifications</span>
                                    </div>
                                    {
                                         (this.props.settings && this.props.settings.show_prices) ? 
                                            <div className="compare__item-row">
                                                <span>Price</span>
                                            </div>
                                        : null
                                    }
                            
                                    <div className="compare__item-row">
                                        <span>Brand</span>
                                    </div>
                                    <div className="compare__item-row">
                                        <span>Warranty</span>
                                    </div>
                                    <div className="compare__item-row compare__item-row--notes">
                                        <span>Notes</span>
                                    </div>
                                </div>
                                <div className="compare__slider">
                                    <Slider {...settings}>
                                    {
                                        compareList.map((product) => {
                                            return <ComparedProduct settings={this.props.settings} key={product.id} product={product}/>
                                        })
                                    }
                                    </Slider>
                                </div>
                            </div>
                        </div>
                        <NavLink to="/Search">
                            <CustomButton title={"Back to Catalogue"} type={"gray"} margins={['mt-16']}/>
                        </NavLink>
                    </div> 
                    : 
                    <div className="empty-page">
                        <h1>Compare list is empty</h1>
                        <NavLink to="/Search">
                            <CustomButton title={"Back to Catalogue"} type={"gray"}/>
                        </NavLink>
                    </div>
                }
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    compareList: state.compareList,
    settings: state.settings,
})

const mapDispatchToProps = dispatch => {
    return {
        clearCompareList: () => dispatch(cleanCompare()),
        clearCompareListElement: (product) => dispatch({type: 'CLEAR_COMPARE_LIST_ELEMENT', product: product}),
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(CompareItemsContent);