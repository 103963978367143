import React, {Component} from "react";
// import "admin/administrator/CustomProductPrices.css";
// import "admin/administrator/CustomElements.css";
import _ from "lodash";

function CustomProductPrices(props) {

    return ( 
        <div className="text-right ml-auto">
            <p className="fw-700">Total: {props.total}</p>
            <p>All prices are excluding VAT</p>
        </div>
    );
}

export default CustomProductPrices;