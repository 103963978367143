import React from 'react';
// import './RegInput.css';
import SearchByRegNumberImage from '../images/regNumberSearch.png';
import FormInput from "admin/administrator/FormInput/FormInput.component";
import CustomButton from "admin/administrator/CustomButton";

const RegInput = props => {
    return (
        <div className={`flex ${props && props.disabled ? 'disabled' : ''} `}>
            <FormInput
                name='regNumber'
                type='text'
                placeholder='Enter Reg Number'
                error={""}
                value={props.regNumber}
                onChange={props.handleRegNumberChange}
                onKeyDown={props._handleKeyDown}
                maxLength={9}
                autoComplete="off"
                className={"input--reg"}
            />
            <CustomButton title={"Go"} 
                        type={"blue"} 
                        onClick={ () => props.handleFindButtonClick(props.regNumber)}
                        style={{borderTopLeftRadius: "0", borderBottomLeftRadius: "0", textTransform: "uppercase"}}/>
        </div>
    );
}

export default RegInput;