import React, { Component } from "react";
// import "admin/administrator/ManageHubs.css";
import $ from "jquery";
import _ from "lodash";
import 'jquery-ui/ui/widgets/sortable';
import axios from "axios1";
import { GridLoader } from 'react-spinners';
import alertify from "alertifyjs";
import Information from "images/information.svg";
export default class ManageHubs extends Component
{
    constructor(props)
    {
        super(props);

        this.state = {
            branches: [],
            loading: true
        }
    }

    componentDidMount() {
        this.retrieveBranches();
    }

    handleSaveClick()
    {
        let branches = this.defineHubsOrder();

        this.updateBranchHubs(branches);
    }

    render()
    {

        alertify.defaults = {
            notifier: {
                position: 'top-right',
                delay: 5
            }
        }
        return (
            <div className={"dashboard-page-wrapper"}>
                {
                    this.state.loading ?
                        <div className='sweet-loading'>
                            <GridLoader
                                sizeUnit={"px"}
                                size={15}
                                color={'#0066b8'}
                                loading={this.state.loading}
                            />
                        </div> : null
                }
                <div className={"dashboard-page-navigations"}>
                    <div>
                        <i className="fab fa-hubspot"></i>
                        <span> Manage Branches</span>
                    </div>
                    <div>
                        <button className="back-btn" onClick={() => this.props.history.goBack()}>
                            Go back
                        </button>
                    </div>
                </div>
                <div className={"manage-hubs-wrapper"}>
                    <div className={"manage-hubs-header"}>
                        <div> <img src={Information} style={{width:"36px", marginRight:"10px"}} /> On this page you can set order and disable/enable branches</div>
                    </div>
                    <div className="manage-hubs">
                        {
                            this.state.branches.map((branch, index) => {
                                return <div className={"branch-wrapper"}>
                                    <div className={"branch-name"}>{branch.name}</div>
                                    <ul id={"sortable" + branch.id} className={"branch-hubs"}>
                                    {
                                        branch.ordered_branches.map((hub) => {
                                            return <li id={branch.id + "-" + hub.id} data-branch-id={branch.id} data-hub-id={hub.id}>
                                                <div className={"hub-name-wrapper"}><span className="arrows-icons"><i class="fas fa-sort-up"></i><i class="fas fa-sort-down"></i></span> {hub.name}</div>
                                                <div className="md-checkbox">
                                                    <input type="checkbox"  defaultChecked={hub.selected ? true:false}  title="Disable/enable hub" onChange={this.handleHubChange.bind(this,{hubId:hub.id,branchId:branch.id})}   name={branch.id + "-" + hub.id}  id={"section" + branch.id + hub.id}/>
                                                    <label htmlFor={"section" + branch.id + hub.id}></label>
                                                </div>
                                          </li>
                                        })
                                    }
                                    </ul>
                                    <div className={"branchesSelectAll"}>
                                        <label htmlFor={"branches-"+branch.id} className={"selectAllLable"} >Select/Deselect All</label>
                                        <div className="md-checkbox check-all">
                                            <input type="checkbox" checked={this.state['branches-'+branch.id] ? true:false} onChange={this.handleAllChecked.bind(this,{name:"branches",id: branch.id})} id={"branches-"+branch.id }/>
                                            <label htmlFor={"branches-"+branch.id}></label>
                                        </div>
                                    </div>
                                </div>
                            })
                        }
                    </div>
                    <div className={"save-hubs-button"} onClick={this.handleSaveClick.bind(this)}>Save</div>
                </div>
            </div>
        );
    }

    retrieveBranches()
    {
        axios.get("catalogue/branches")
            .then((response) => {
                if(response.data.success) {
                    this.setState({
                        branches: response.data.branches,
                        loading:false
                    }, () => {
                        this.makeSortable(this.state.branches);
                    })
                } else {
                    alert("Error: " + response.data.msg);
                }
            })
    }

    handleHubChange(propertys, event){
        let branchName = 'branches-'+propertys.branchId,
         checkAll = true,
         result = [],
         branches = this.state.branches,
         branch = _.find(branches, { id: propertys.branchId });
         if(branch.ordered_branches) {
            let hub = _.map(branch.ordered_branches,function(hub){
               if(hub.id == propertys.hubId)
                   hub.selected = event.target.checked;
               if(!hub.selected)
                 checkAll = false;
            })
            result[branches] = branches;
            result[branchName] = checkAll;
            this.setState(result);
        }
    }

    updateBranchHubs(branches)
    {
        this.setState({loading: true});
        axios.post("catalogue/updateBranchHubs", {
            branches: branches
        }).then((response) => {
            if(response.data.msg) {
                this.setState({loading: false});
                alertify.success(response.data.msg);
            } else {
                this.setState({loading: false});
                alertify.error("something went wrong");
            }
        });
    }

    defineHubsOrder()
    {
        let branches = [...this.state.branches];
        branches.forEach((branch) => {
            let sorted = $("#sortable" + branch.id).sortable("toArray");
            let valuesToRemove = [];
            sorted.forEach((name, index) => {
                if(!($("[name='"+name+"']").is(':checked'))) {
                    valuesToRemove.push(name);
                }
            });
            sorted = sorted.filter((element) => { return !valuesToRemove.includes(element); }).map((element) => { return element.split("-")[1] })
            branch.sortedHubs = sorted;
        })
        return branches;
    }

    makeSortable(branches)
    {
        let branchesAllArray = [];
        branches.forEach((branch) => {
            branchesAllArray['branches-'+branch.id] = true;
            if(branch.ordered_branches) {
                _.map(branch.ordered_branches,function(hub) {
                    if(!hub.selected){
                      branchesAllArray['branches-'+branch.id] = false;
                    }
                    return null;
                });
            }
            $( "#sortable" + branch.id ).sortable({
                placeholder: "ui-state-highlight"
            });
        });
        this.setState(branchesAllArray);
    }

    handleAllChecked(property,event) {
        let elementName = property.name + "-"+ property.id;
        let branches = this.state.branches || "";
        var branch = _.find(branches,{id: property.id});
        let result = [];
        if(branch &&  branch.ordered_branches) {
            branch = _.map(branch.ordered_branches,function(element){
                element.selected = event.target.checked;
                return element;
            });
            result[branches] = branches;
        }
        result[elementName] = event.target.checked;
        this.setState(result);
        $( "#sortable" + property.id + ' input').each(function(i,v){
            v.checked = event.target.checked;
        });
    }
}