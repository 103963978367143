import React, {useEffect, useState, Fragment} from "react";
import CustomButton from "admin/administrator/CustomButton";
import LoginsTable from 'admin/administrator/LoginsTable';
import CustomAddLoginModal from 'admin/administrator/CustomAddLoginModal';
import alertify from "alertifyjs";
import axios from "axios1";
import _ from "lodash";

const Logins = props => {
    const [accountId, setAccountId] = useState(false);
    const [logins, setLogins] = useState([]);
    const [cataloguePreferences, setCataloguePreferences] = useState({});
    const [form, setForm] = useState({
        approved: true,
    });
    const [loginId, setLoginId] = useState(null);
    const [loginIndex, setLoginIndex] = useState(null);
    const [editLoginModal, setEditLoginModal] = useState(false);
    const [addLoginModal, setAddLoginModal] = useState(false);
    const [errors, setErrors] = useState({
        nameError: '',
        emailError: '',
        passwordError: '',
        password_confirmationError: '',
        repeat_emailError: ''
    });
    const validateConf = {
        name: (value, update = null) => {
            if(value === "" || !value) {
                return "Name is required!";
            }

            let format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?]+/;
            if(format.test(value)) {
                return "Invalid Character(s)";
            }

            let nIndex = null;
            let currentIndex = update ? loginIndex : false;
            logins.map((login, nameIndex) => {
                if (login.name.trim().toLowerCase() == value.trim().toLowerCase()) {
                    nIndex = nameIndex;
                }
            });

            let nameExistsbolean = nIndex !== currentIndex ? (nIndex !== null ? true : false) : false;
    
            if (nameExistsbolean) {
                return "Name is already in use";
            }
        },
        email: (value, update = null) => {
            const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (value === "") {
                return "Email is required!";
            }
            
            if(!re.test(String(value).toLowerCase())) {
                return "The email address is not valid!";
            }

            let emIndex = null;
            let currentIndex = update ? loginIndex : false;
            logins.map((login, emailindex) => {
                if (login.email.trim().split("_deleted_")[0] == value.trim()) {
                    emIndex = emailindex;
                }
            });
            let emailExistsbolean = emIndex !== currentIndex ? (emIndex !== null ? true : false) : false;

            if (emailExistsbolean) {
                return "The Login email " + value + " has already been taken";
            }

            validateEmail(loginId).then( message => setErrors({...errors, emailError: message}));
        },
        password: value => {
            if (!value) {
                return "Password is required!";
            }

            if(value.length < 6) {
                return "Please choose a password with at least 6 \
                characters";
            }
        },
        password_confirmation: value => {
            if (form.password != form.password_confirmation) {
                return "Passwords does not match!";
            }
        }        
    }

    useEffect(() => {
        let account_id = props.account ? props.account.id : false;
        if (props.logins) {
            const newLogins = _.map(props.logins, function (element) {
                if (element.preferences) {
                    element.preferences.modalShow = false;
                } else {
                    element.preferences = {
                        modalShow: false
                    }
                }
                return element;
            });
            setLogins([...newLogins]);
        }
        setCataloguePreferences(props.cataloguePreferences);
        setAccountId(account_id)
    }, [props.logins, props.cataloguePreferences]);

    const restoreLogin = id => {
        axios.post("catalogue/users/restore/" + id)
        .then((response) => {
            if(response.data.success){
                if(response.data.status == "email_exists") {
                    alertify.error(response.data.message);
                } else {
                    alertify.success(response.data.message);
                    rerenderUsers(response.data.login);
                }
            }else{
                alertify.error("Error occured");
            }
        })
    }

    const rerenderUsers = login => {
        const newItems = _.map(logins, item => {
            if(item.id === login.id) {
                login.password_confirmation = login.password;
                item = login;
            }
            return item;
        });
        props.setLogins(newItems);
    }

    const handleDeleteLogin = (id, email) => {
        if(!id) {
            const newLogins = logins.filter((item, key) => { 
                return item.email !== email 
            })
            props.setLogins(newLogins);
            return;
        }

        axios.post("catalogue/users/delete/" + id)
        .then((response) => {
            if(response.data.success){
                alertify.success(response.data.message);
                rerenderUsers(response.data.login);
            }else{
                alertify.error("Error occured");
            }
        })
    }

    const handeEditLogin = (id, email) => {
        let index = _.findIndex(logins, {email: email});
        let login = logins[index];
        let form = {
            name: login.name,
            email: login.email,
            password: login.password,
            password_confirmation: login.password_confirmation,
            approved: login.approved
        };
        setForm(form);
        setLoginId(id);
        setLoginIndex(index);
        setEditLoginModal(true);
    }

    const handleSwitchChange = (property, key, checked) => {
        let newLogins = logins;
        key = _.findIndex(newLogins, {email: key});
        if(newLogins[key].suspend == true && checked) {
            newLogins[key].suspend = false;
        }

        newLogins[key].approved = checked;
        props.setLogins(newLogins);
    }

    const handleSuspendClick = (property, key, checked) => {
        let newLogins = logins;
        key = _.findIndex(newLogins, {email: key});
        newLogins[key].suspend = checked;
        newLogins[key].approved = false;
        props.setLogins(newLogins);
    }

    const handlePreferencesClick = (loginId, email) => {
        var newLogins = logins;
        let element = loginId ? _.find(newLogins, {id: loginId}) : _.find(newLogins, {email: email});
        if (element.form) {
            element.form.admin = element.admin;
        }
        element.preferences.modalShow = true;
        setLogins(newLogins);
    }

    const getPreferences = async (login_id, login) => {
        let newCataloguePreferences = login.preferences;
        let prefs = [];
        if (!newCataloguePreferences.form) {
            if (login_id) {
                await axios.get("catalogue/preferences?type=" + 'user' + '&id=' + login_id)
                    .then((response) => {
                        if (response.data.success) {
                            prefs = response.data.preferences;
                            prefs.form.admin = login.admin;
                            prefs.modalShow = true;
                            newCataloguePreferences = prefs;
                        }
                    })
            } else {
                prefs = {...cataloguePreferences};
                if (!_.isEmpty(prefs)) {
                    prefs.modalShow = true;
                    newCataloguePreferences = prefs;
                } else {
                    if (accountId) {
                        await axios.get("catalogue/preferences?type=" + 'account' + '&id=' + accountId)
                            .then((response) => {
                                if (response.data.success) {
                                    prefs = response.data.preferences;
                                    prefs.modalShow = true;
                                    newCataloguePreferences = prefs;
                                }
                            });
                    } else {
                        await axios.get("catalogue/preferences")
                            .then((response) => {
                                if (response.data.success) {
                                    prefs = response.data.preferences;
                                    prefs.modalShow = true;
                                    newCataloguePreferences = prefs;
                                }
                            });
                    }
                }
            }
        }
        return newCataloguePreferences;
    }


    const getSections = (user_id, group_id) => {
        let strGet = user_id ? ("type=user&id=" + user_id) : (accountId ? ("type=account&id=" + accountId) : '');
        return axios.get("catalogue/preferences/sections?groupId=" + group_id + "&" + strGet);
    }

    const getBrands = (user_id, section_id) => {
        let strGet = user_id ? ("type=user&id=" + user_id) : (accountId ? ("type=account&id=" + accountId) : '');
        return axios.get("catalogue/preferences/brands?sectionId=" + section_id + "&" + strGet)
    }

    const modalClose = (loginId, email) => {
        var newLogins = logins;
        let element = loginId ? _.find(newLogins, {id: loginId}) : _.find(newLogins, {email: email});
        element.preferences.modalShow = false;
        props.setLogins(newLogins);
    }

    const updatePreferencesValues = (login_id, index, preferences) => {
        let newLogins = logins;
        index = _.findIndex(logins, {email: index});
        preferences.modalShow = false;
        if (login_id) {
            let login = _.find(logins, {id: login_id});
            login.preferences = preferences;
            login.admin = preferences.form.admin;
        } else {
            newLogins[index]['preferences'] = preferences;
            newLogins[index]['admin'] = preferences.form.admin;
        }
        props.setLogins(newLogins);
    }

    const handleAddLogin = () => {
        let newLogins = logins;
        const isValid = validate();
        if (isValid) {
            let user = form;
            user.preferences = {
                modalShow: false
            }
            newLogins.push(user);            
            props.setLogins([...newLogins]);
            clearModal();
        }
    }

    const clearModal = () => {
        let form = {
            name: '',
            email: '',
            password: '',
            password_confirmation: '',
            approved: true
        };
        let errors = {
            nameError: '',
            emailError: '',
            passwordError: '',
            password_confirmationError: ''
        };
        setForm(form);
        setAddLoginModal(false);
        setEditLoginModal(false);
        setErrors(errors);
        setLoginId(null);
        setLoginIndex(null);
    }

    const handleFormChange = (property, value) => {
        let newForm = form;
        newForm[property] = value;
        setForm({...newForm});
    }

    const handleFromBlur = (isEdit, property, value) => {
        const newErrors = errors;
        if(property) {
            newErrors[property + "Error"] = validateConf[property](value, isEdit) ?? "";
        }
        setErrors({...newErrors});
    }

    const handlePasswordChange = (properties) => {
        let newForm = form;
        let newErrors = errors;
        _.map(properties, function(val, key) {
            form[key] = val;
            
            if(key) {
                newErrors[key + "Error"] = "";
            }
        })
        setErrors({...newErrors});
        setForm({...newForm});
    }

    const validateEmail = async (isEdit = false) => {
        return await axios.post("catalogue/validate-login", { login: { ...form, id: loginId}, isEdit: isEdit})
        .then((response) => {
            return response.data.success && response.data.errors.length > 0 ? (response.data.errors[0] ?? "") : "";
        });
    }

    const handleUpdateLogin = event => {
        let id = loginId;
        let index = loginIndex;
        let newLogins = logins;
        let newForm = form;
        newForm.id = id;
        const isValid = validate(true);

        if (isValid) {
            newLogins[index] = newForm;
            newLogins[index].preferences = {
                modalShow: false
            }
            newLogins[index].is_updated = true;
            props.setLogins([...newLogins]);
            clearModal();
        }
    }

    const validate = (update = null) => {
        let isValid = true;
        const newErrors = errors;
        _.map(validateConf, ( validateFunc, key ) => {
            let valid = validateFunc(form[key], update);
            if(valid) {
                isValid = false;
                newErrors[key + "Error"] = valid;
            }else {
                newErrors[key + "Error"] = "";
            }
        })
        
        setErrors({...newErrors});
        return isValid;
    }
    return (
        <Fragment>
            {
            <LoginsTable 
                    items={logins}
                    edit={props.edit}
                    branches={props.branches}
                    setLogins={setLogins}
                    restoreLogin={restoreLogin}
                    handleDeleteLogin={handleDeleteLogin}
                    handeEditLogin={handeEditLogin}
                    handleSwitchChange={handleSwitchChange}
                    handleSuspendClick={handleSuspendClick}
                    handlePreferencesClick={handlePreferencesClick}
                    getPreferences={getPreferences}
                    getSections={getSections}
                    getBrands={getBrands}
                    modalClose={modalClose}
                    isCustomer={props.isCustomer ?? false}
                    isBranch={props.isBranch}
                    updatePreferencesValues={updatePreferencesValues.bind(this)}
                /> 
            }
            <CustomButton
                title={"Add New User"}
                type={"blue"}
                margins={['mt-12']}
                onClick={() => setAddLoginModal(true)}
            />
            <CustomAddLoginModal
                title={"New User"}
                buttonTitle={"Save"}
                errors={errors}
                show={addLoginModal}
                form={form}
                handleSaveLogin={handleAddLogin}
                handleFormChange={handleFormChange}
                handleFromBlur={handleFromBlur.bind(this, false)}
                handlePasswordChange={handlePasswordChange}
                onHide={ clearModal }                
            />
            <CustomAddLoginModal
                title={"Edit User"}
                buttonTitle={"Update"}
                errors={errors}
                show={editLoginModal}
                form={form}
                handleSaveLogin={handleUpdateLogin}
                handleFormChange={handleFormChange}
                handleFromBlur={handleFromBlur.bind(this, true)}
                handlePasswordChange={handlePasswordChange}
                onHide={ clearModal }
            />
        </Fragment>
    )

}

export default Logins;