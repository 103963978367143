import React, {Component} from 'react';
import axios from 'axios1';
import "admin/administrator/PriceLevels/PriceLevelMainContainer.css";
import alertify from 'alertifyjs';
import _ from "lodash";
import {GridLoader} from 'react-spinners';
import CustomButton from "admin/administrator/CustomButton";
import CustomToggleRadioButton from "custom_components/CustomToggleRadioButton";
import Switch from "react-switch";
import PriceLevelProducts from './PriceLevelProducts';
import WarningModal from '../WarningModal/WarningModal';
import CustomDelete from "admin/administrator/CustomDelete";
import Notify from "components/Notify";
import Loading from "admin/administrator/LoadingComponent/Loading.component";


export default class PriceLevelMainContainer extends Component {
    constructor(props) {
        super(props);


        this.state = {
            loading: false,
            showdata: [],
            errors: [],
            form: {
                switch: 0,
                levels: [],
                deleted: []
            },
            namesToChange:[],
            showWarningModal: false,
            PriceLevelName: null,
            accounts: []
        }
    }

    componentDidMount() {
        this.getExistingLevels();
    }

    getExistingLevels() {
        this.setState({loading: true});
        axios.get("catalogue/price-levels")
            .then((response) => {
                this.setState({loading: false});
                let levels = [];
                let form = {...this.state.form};
                form['switch'] = (response.data[0]) ? (response.data[0].price_level_value_type_id == 1) ? 0 : 1 : 0;
                form['levels'] = [];
                this.setState({form: form});
                this.props.setStateFromChild("changesSaved", true);
                response.data.map((level) => {
                    this.pushData(level)
                })
            })
    }

    pushData(data = null) {
        let showdata = this.state.showdata;
        let levels = this.state.form.levels;
        let lastLevel = levels[levels.length - 1] ? levels[levels.length - 1].name : null;
        let v = (data) ? data.value : 0;

        var array = [0];
        var arrayPos = [0];
        let posId = 0;
        let namesToChange = [...this.state.namesToChange];
        posId = levels.length + 1;
        
        if(data){
                if(data.name.includes("Level")){
                    var val = data.name.split('Level ');
                    var isnum = /^\d+$/.test(val[1]);
                    if(isnum){ 
                        namesToChange.push({name:parseInt(val[1]),id:data.id, posId:posId});
                    }
                }
        } else {
            if(lastLevel != null && lastLevel.includes("Level")) {
                var val = lastLevel.split('Level ');
                if((val[1] - levels.length) == 1) {
                    posId = levels.length + 2;
                }
            }
        }
        // if(data){
        // }else{
        //     levels.map((level, i)=>{
        //         arrayPos.push(level.pos_id);
        //         // იმ შემთხვევაში თუ ასე გდაწყობა მოუნნდებთ არ წაშალოთ;
        //         // if(level.name.includes("Level")){
        //         //     var val = level.name.split('Level ');
        //         //     var isnum = /^\d+$/.test(val[1]);
        //         //     if(isnum){ 
        //         //         array.push(parseInt(val[1]));
        //         //     }
        //         // }
        //     });
        //      posId = Math.max.apply(null,arrayPos);
        // }
        let localId = Math.max.apply(null, array) + 1;
        let n = (data) ? data.name : "Level " + (posId);

        levels.push({
            id: (data) ? data.id : null,
            pos_id: posId,
            name: n,
            value: v
        });
        this.setState({levels: levels, namesToChange:namesToChange});
    }


    handleSaveClick() {
        // this.setState({loading: true});
        let form = {...this.state.form};
        form['switch'] = (form['switch'] == 1) ? 2 : 1;
        axios.post("catalogue/price-levels", {form: form})
            .then((response) => {
                // this.setState({loading: false});
                if (response.data.success) {
                    if (response.data.errors) {
                        this.setState({errors: response.data.errors}, () => {
                            _.map(this.state.errors, function (item) {
                                alertify.error(item.message);
                            })
                        });
                    } else {
                        this.setState({errors: []})
                        this.getExistingLevels();
                        this.props.setStateFromChild("changesSaved", true);
                        this.props.setStateFromChild("modalShow", false);
                        this.props.getPriceLevels();
                        this.setState({namesToChange:[]});
                    }
                }
            })
    }


    handleSwitchChange(property, event) {
        let form = {...this.state.form};
        form[property] = event.target.value;
        this.setState({form: form});
        this.props.setStateFromChild("changesSaved", false);

    }

    handleFormChange(localId, type, e) {
        let levels = [...this.state.form.levels];
        if (type == 'value') {
            if (e.target.value >= 100 && this.state.form.switch == 0) {
                return;
            } else {
                levels.find((level) => level.pos_id == localId).value = Math.abs(e.target.value);
            }
        }
        if (type == 'name') {
            levels[localId - 1].name = e.target.value;
            if (levels[localId - 1].name == '') {
                this.setState({errors: {}})
            }
        }
        this.setState({levels: levels});
        this.props.setStateFromChild("changesSaved", false);

    }

    newPriceLevel() {
        this.props.setStateFromChild("changesSaved", false);
        this.pushData();
    }

    handleDeleteClick(localId, id) {
        let form = {...this.state.form};
        axios.get("catalogue/accounts/price-level/" + id)
            .then((response) => {
                if(response.data.accounts.length == 0) {
                    let namesToChange = this.state.namesToChange;
                    if (id) {
                        let deleted = form['levels'].filter(x => x.pos_id == localId);
                        form['deleted'].push(deleted);
                    }
                    form['levels'] = form['levels'].filter(x => x.pos_id != localId);
                    // form['levels'].map((level, i) => {
                    //     if (localId < level.pos_id) {
                    //         form['levels'][i].pos_id = level.pos_id - 1;

                    //     if(level.name.includes("Level")){
                    //         var val = level.name.split('Level ');
                    //         var isnum = /^\d+$/.test(val[1]);
                    //         if(isnum){
                    //             form['levels'][i].name = "Level " + (val[1]-1);
                    //         }
                    //     }

                    //         // if (level.id == null) {
                    //         //     form['levels'][i].name = "Level " + (level.pos_id);
                    //         // }
                    //     }
                    // })
                    this.setState({form: form});
                    this.props.setStateFromChild("changesSaved", false);
                } else {
                    let PriceLevelName = form.levels.find(level => level.id === id).name;
                    this.setState({showWarningModal: true, PriceLevelName: PriceLevelName, accounts: response.data.accounts});
                }
            });
    }

    closeWarningModal() {
        this.setState({showWarningModal: false})
    }

    render() {
        console.log(this.state.accounts);
        let boldmarkUp = this.state.form.switch == 1 ? 'bold' : '';
        let boldMargin = this.state.form.switch == 0 ? 'bold' : '';
        return (
            <div className="page">
                {
                    this.state.showWarningModal ?
                        <Notify type={"danger"}
                                backdrop={false}
                                visible={this.state.showWarningModal}
                                close={this.closeWarningModal.bind(this)}
                                primaryText={this.state.PriceLevelName + " can not be deleted. The following accounts are using this level."}
                                secondaryText={""}
                                data={this.state.accounts}
                                actions={[
                                    {
                                        name: "close",
                                        action: this.closeWarningModal.bind(this),
                                        type: "blue"
                                    }
                                ]}/> : null
                }
                <Loading loading={this.state.loading}/>
                <div className={"account-wrapper row p-4"}>
                    <div className="col-sm-12 p-0">
                        <div className={"col-sm-6 p-0 mb-1"} style={{height: '35px'}}>
                            <CustomToggleRadioButton 
                                checked={parseInt(this.state.form.switch)} 
                                handleSwitchChange={this.handleSwitchChange.bind(this, "switch")} 
                                items={[{title: "Margin", value: 0 }, {title: "Mark Up", value: 1}]}
                            />
                        </div>
                    </div>
                    <div className="col-sm-12 priceLevelsTableHeaderInfoWrapper">
                        <div className="row" style={{display: "flex"}}>
                            
                            <div className={"priceLevelsTableHeaderInfo"}>
                                <p>Price Level</p>
                                <p>Price Level Name</p>
                                <p> {this.state.form.switch == 0 ? "Margin" : "Mark Up"}</p>
                                <p>Delete Price Level</p>
                            </div>
                            
                            <div className="LevelsWrapper pl-0">

                                {
                                    this.state.form.levels.map((level, i) => {
                                        return <div className={'priceLevelsTableLevels'}>
                                            <p style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>{++i}</p>

                                            <div class="input-container-z" style={{display: 'flex', alignItems: 'center'}}>
                                                <input
                                                    className={(this.state.errors[level.pos_id] && this.state.errors[level.pos_id]['name']) ? "price-level-name-error inp-z" : "inp-z"}
                                                    onChange={this.handleFormChange.bind(this, level.pos_id, 'name')}
                                                    value={level.name}/>
                                            </div>
                                            <div class="input-container-z amount">
                                                <p className='inline'><i className="fas fa-plus"></i>&nbsp;</p>
                                                <input
                                                    className={(this.state.errors[level.pos_id] && this.state.errors[level.pos_id]['value']) ? "price-level-name-error inp-z" : "inp-z"}
                                                    type="number" value={level.value}
                                                    onChange={this.handleFormChange.bind(this, level.pos_id, 'value')}/>
                                                <p className='inline'> &nbsp;% </p>
                                            </div>

                                            <p className="deleteLevel">
                                                <CustomDelete key={level.id} onClick={this.handleDeleteClick.bind(this, level.pos_id, level.id)}
                                                />
                                            </p>
                                        </div>
                                    })
                                }
                                <div className="newLevelWrapper">
                                    <div onClick={this.newPriceLevel.bind(this)} className="addBUttonWrapper">
                                        <CustomButton title={[<i class="fas fa-plus"></i>, " Add Pricing Level"]} type={'blue'} ></CustomButton>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className={'col-sm-12 p-0 productTitle'} style={{marginTop: '22px', marginBottom: '15px'}}>
                        <h1>Product Prices</h1>
                    </div>
                    <PriceLevelProducts levels={this.state.form.levels} ValueType={this.state.form.switch}
                                        switch={this.state.form.switch}></PriceLevelProducts>

                </div>

                <div className="modal__actions">
                    <CustomButton title={"Undo Changes"} type={"lightgray"} onClick={this.props.closeModal} margins={['mr-8']}/>
                    <CustomButton title={"Save Changes"} type={"green"} onClick={this.handleSaveClick.bind(this)}/>
                </div>
            </div>
        );
    }
}
