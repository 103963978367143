import React, {Fragment} from "react";
import AltProducts from "../AltProducts";
import "./Stock.css";

const Stock = props => (
    <Fragment>
        {
            props.stocks.length > 0 ?
                <AltProducts 
                    stockLoad={props.stockLoad}
                    stockVisibility = {props.stockVisibility}
                    title={props.title}
                    stocks={props.stocks}
                    section_lookup_id={props.section_lookup_id}
                    shoppingCart={props.shoppingCart}
                    lookup={props.lookup}
                    settings = {props.settings}
                    product = {props.product}
                    defaultStock = {props.defaultStock}>
                </AltProducts>
            : <p className="NoProducts">{props.title === "Supplier" ? "No more stock available in other suppliers" : "No more stock available in other branches"} </p>
        }
    </Fragment>
);

export default Stock;