import React, { Component } from "react";
import ReactDOM from 'react-dom';
import axios from "axios";
import store from "redux/store";
import CloseIcon from "images/close-icon.png";
import AlertIcon from "images/alert-icon.png";
import Notify from "components/Notify";

let options = {
    baseURL: process.env.REACT_APP_API_URL + "/",
    params: {token: ""}
};

setToken();

function setToken() {
    let state = store.getState();
    let token = state.auth.token;
    options.params.token = token;
}

function closeModal() {
    ReactDOM.unmountComponentAtNode(document.getElementById('modalWrapper'));
}

store.subscribe(setToken);

const instance = axios.create(options);

instance.interceptors.response.use(function (response) {
    if(response.data.hasOwnProperty('success') && !response.data.success) {
        // ReactDOM.render(
        //         <div className="err-wrapper">
        //             <div className="err-backdrop" onClick={closeModal.bind(this)}></div>
        //             <div className="err-popup">
        //                 <img className="err-close" src={CloseIcon} onClick={closeModal.bind(this)}/>
        //                 <div className="err-content">
        //                     <img src={AlertIcon}/>
        //                     <h1>An error has occurred and we apologise for the inconvenience.</h1>
        //                     <p>A report concerning the error has been received and we will investigate and resolve the issue as a matter of priority.</p>
        //                 </div>
        //             </div>
        //         </div>, document.getElementById('modalWrapper'));
        ReactDOM.render(
            <Notify type={"danger"}
                visible={true}
                close={closeModal.bind(this)}
                primaryText={"An error has occurred and we apologise for the inconvenience."}
                secondaryText={"A report concerning the error has been received and we will investigate and resolve the issue as a matter of priority."}
                actions={[
                    {
                        name: "close",
                        action: closeModal.bind(this),
                        type: "red"
                    }
                ]}/>, document.getElementById('modalWrapper'));
    }
    return response;
}, function (error) {
    if ((error.response && error.response.status === 401) && window.location.href.indexOf("/Login") == -1) { // Here we check if we are already on Login page to avoid infinite redirection to Login
        window.location = "/Login"
    } else {
        return Promise.reject(error);
    }
});

export default instance;