import React from 'react';
import FormInput from "admin/administrator/FormInput/FormInput.component";
import CustomButton from "admin/administrator/CustomButton";

function VinInput(props) {

    return (
        <div className={"flex"}>
            <FormInput
                name='vinNumber'
                type='text'
                placeholder='Enter VIN Number'
                error={""}
                value={props.vinNumber}
                onChange={props.handleVinNumberChange}
                onKeyDown={props._handleKeyDown}
            />
            <CustomButton title={"Go"} 
                        type={"blue"} 
                        onClick={props.handleFindByVinNumber}
                        style={{borderTopLeftRadius: "0", borderBottomLeftRadius: "0", textTransform: "uppercase"}}/>
        </div>
    );
}

export default VinInput;