import React, {useEffect, useState, Fragment} from "react";
import { useHistory } from "react-router-dom";
import CustomStockFeed from 'admin/administrator/CustomStockFeed.js';
import FormSelect from "admin/administrator/FormSelect/FormSelect.component";
import Loading from "admin/administrator/LoadingComponent/Loading.component";
import PriceLevelForm from "./PriceLevels/PriceLevelForm";
import CustomButton from "admin/administrator/CustomButton";
import SettingDetails from "admin/administrator/SettingDetails";
import CustomCatalogueDefaults from 'custom_components/CustomCatalogueDefaults.js';
import MainChargingOptionsContainer from "./ChargingOptions/MainChargingOptionsContainer";
import alertify from 'alertifyjs';
import axios from "axios1";
import _ from "lodash";
import Block from "components/Block";

let textFirst = "The stock file should be in .csv (comma seperated values) format and in the following structure: Part Number, Branch Name, Quantity Available."
let textSecond = "The Branch name column data should exactly match the Branch name as entered within the catalogue."

const Settings = props => {

    const history = useHistory();

    const [ loading, setLoading ] = useState(false);
    const [ errors, setErrors ] = useState([]);
    const [ owner, setOwner ] = useState([]);
    const [ systemFromAddress, setSystemFromAddress ] = useState('');
    const [ credentials, setCredentials ] = useState([]);
    const [ apiId, setApiId ] = useState(null);
    const [ apis, setApis ] = useState([]);
    const [ defaultPrice, setDefaultPrice ] = useState(null);
    const [ levels, setLevels ] = useState([]);
    const [ modalShow, setModalShow] = useState(false);
    const [ cataloguePreferences, setCataloguePreferences ] = useState({});
    const [ groups, setGroups ] = useState([]); // This one need check ( CustomCatalogueDefaults )
    const [ form, setForm ] = useState({
            registration_from_site: false,
            approve_users: false,
            encrypt_part_numbers: false,
            enable_shopping_cart: false,
            images_in_shopping_cart: false,
            show_brand_logos: false,
            show_prices: false,
            show_full_info: false,
            multi_select_groups: false,
            vrm_vehicle_confirmation: false,
            search_filter: false,
            show_xrefs: false,
            stockOption: 'hidden',
            stock_visibility: "quantity",
            search_type: "advanced",
            lost_sale: true,
            theme: true,
    });
    const [ pricingOpions, setPricingOpions ] = useState({
        branch_login_fee:'',
        branch_vrm_lookup_fee:'',
        costumer_login_fee:'',
        costumer_vrm_lookup_fee:'',
        login_fee:'',
        vrm_lookup_fee:''
    });
    
    const setOwnerFunc = event => {
        const { value, name } = event.target;
        setOwner({ ...owner, [name]: value });
    }

    const handlePricingOptionInputChange = event => {
        
        let re1 = /^\d*\.?\d*$/;
        const { value, name } = event.target;

        if( re1.test(value) ) {
            setPricingOpions({ ...pricingOpions, [name]: value });
        }
    }

    const getPreferences = async () => { // need refactoring ( when we start refactoring of CustomCatalogueDefaults check this one)
        let cataloguePreferencesObj = cataloguePreferences;
        if (!cataloguePreferencesObj.form) {
            await axios.get("catalogue/preferences")
                .then((response) => {
                    if (response.data.success) {
                        cataloguePreferencesObj = response.data.preferences;
                        return cataloguePreferencesObj;
                    }
                })
        }
        return cataloguePreferencesObj;
    }

    const getSettings = () => {
        setLoading(true);
        axios.get("catalogue/settings-data")
            .then((response) => {
                if(response.data.success) {
                    let pricingOpions = {...pricingOpions};
                    response.data.chargingOptions.map( ({ key, value }) => pricingOpions[key] = value );                
                    let apiId = response.data.api_id;
                    let apis = response.data.apis;
                    let currentApi = _.find( apis, {id: apiId} );
                    setPricingOpions(pricingOpions);
                    setOwner(response.data.owner || {});
                    setCredentials(currentApi && currentApi.credentails.length > 0 ? currentApi.credentails : []);
                    setApiId(apiId);
                    setApis(apis);
                    setSystemFromAddress(response.data.system_from_address);
                    setLoading(false);
                }
            })
    };

    const getPriceLevels = () => {
        axios.get("catalogue/price-levels")
            .then((response) => {
                let defaultPrice = _.find(response.data, {default: 1});
                defaultPrice = defaultPrice ? defaultPrice.id : null;
                setLevels(response.data);
                setDefaultPrice(defaultPrice)
            })
    }

    const filterObjectByChecked = (obj, attrName) => {
        let response = [];
        _.map(obj, ({ checked, ...otherAttributes }) => { if(checked) { response.push(otherAttributes[attrName]); } });
        return response;
    }

    const filtreForm = (form) => {
        if(form.additional_elements) {
            form.additional_elements = filterObjectByChecked(form.additional_elements, 'value');
        }
        if(form.search_types) {
            form.search_types = filterObjectByChecked(form.search_types, 'value');
        }
        if(form.manufacturers) {
            form.manufacturers = filterObjectByChecked(form.manufacturers, 'name');
        }
        return form;
    }

    const makeDefaultPrice = () => axios.put("/catalogue/price-levels/default/" + defaultPrice);

    const handleSaveClick = () => {
        let data = {};
        let cataloguePreferencesClonedObj = _.cloneDeep(cataloguePreferences);
        let groups = cataloguePreferencesClonedObj.groups || [];
        groups = _.map(groups, group => {
            if(group.sections) {
                _.map(group.sections, section => {
                    section.brands = filterObjectByChecked(section.brands, 'id');
                    return section;
                })
            }            
            return group;
        });

        let form = cataloguePreferencesClonedObj.form || [];
        if(form) {
            form = filtreForm(form);
        }

        data.form = form;
        data.groups = groups;
        setLoading(true);
        makeDefaultPrice();
        saveData(data);
    }

    const saveData = (data) => {
        data.type = "global";
        data.apiId = apiId;
        data.credentials = credentials;
        data.owner = owner;
        data.pricingOpions = pricingOpions;

        axios.post("/catalogue/settings", data)
        .then((response) => {
            window.scrollTo(0, 0)
            setLoading(false);
            if (response.data.success && !response.data.errors){
                setErrors([]);
                alertify.success(response.data.message);
            }else {
                setErrors(response.data.errors);
                alertify.error(response.data.message);
            }
        });
    }

    useEffect(() => {
        getSettings();
        getPriceLevels();
    }, []);
    
    return (
        <Fragment>
            <Loading loading={loading} />
            <div className={"dashboard-page-body"}>
                <div className={"page-content admin-wrapper"}>
                    <div className="grid">
                        <div className="grid__row">
                            <div className="grid__column grid__column--4">
                                <Block title="company details" type="1" style={{height: "100%"}}>
                                    <SettingDetails
                                            form={owner}
                                            handleChange={setOwnerFunc}
                                            errors={errors}
                                        />
                                </Block>
                            </div>
                            <div className="grid__column grid__column--4">
                                <Block title="company stock feed" type="1" style={{height: "100%"}}>
                                    <CustomStockFeed
                                            apiId={apiId}
                                            apis={apis}
                                            setApiId={setApiId}
                                            setCredentials={setCredentials}
                                            errors={errors}
                                            showFtp={true}
                                            showSendFtpCredentialsBtn={false}
                                            textFirst={textFirst}
                                            textSecond={textSecond}
                                        />
                                </Block>
                            </div>
                            <div className="grid__column grid__column--4">
                                <MainChargingOptionsContainer 
                                    pricingOpions={ pricingOpions }
                                    handleChange = { handlePricingOptionInputChange }
                                />
                            </div>
                        </div>
                        <div className="grid__row">
                            <div className="grid__column grid__column--4">
                                <Block title="default pricing level" type="3">
                                    <FormSelect 
                                            options={ levels }
                                            selectedId={ defaultPrice }
                                            defaultValue={ 'Select' }
                                            handleChange={ e => setDefaultPrice(e.target.value) }
                                        />
                                </Block>
                            </div>
                            <div className="grid__column grid__column--4">
                                <Block title="pricing levels" type="3">
                                    <PriceLevelForm defaultPrice={ setDefaultPrice }></PriceLevelForm>
                                </Block>
                            </div>
                            <div className="grid__column grid__column--4">
                                <Block title="bespoke pricing" type="3">
                                    <CustomButton type={"blue"} 
                                                title={"Manage"} 
                                                width={"91px"} 
                                                onClick={() => history.push("/admin/bespoke-prices")}></CustomButton>
                                </Block>
                            </div>
                        </div>
                        <div className="grid__row">
                            <div className="grid__column grid__column--4">
                                <Block title="catalogue image slider" type="3">
                                    <CustomButton type={"blue"} 
                                                title={"Manage"} 
                                                width={"91px"}
                                                onClick={() => history.push("/admin/image-slider")}></CustomButton>
                                </Block>
                            </div>
                            <div className="grid__column grid__column--4">
                                <Block title="catalogue preferences" type="3">
                                    <CustomButton type={"blue"} title={"Manage"} width={"91px"}
                                                onClick={ () => setModalShow(true) }> </CustomButton>
                                </Block>
                            </div>
                        </div>
                    </div>
                    <div style={{textAlign: 'right'}}>
                        <CustomButton title={"Save"} type={"blue"} width={"130px"} onClick={ handleSaveClick }/>
                    </div>
                </div>
            </div>
            <CustomCatalogueDefaults
                show={modalShow}
                form={form}
                groups={groups}
                updatePreferencesValues={setCataloguePreferences}
                preferences={getPreferences}
                onHide={() => setModalShow(false)}
            />
        </Fragment>
    );
}

export default Settings;