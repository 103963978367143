import React from "react";
import _ from "lodash";

function CustomAdditionalNotes(props) {

    return (
        <textarea name={"description"} 
                className={"additional-info"}
                placeholder={"Additional Notes"} 
                onChange={(e) => props.updateAdditionalNotesValue(e.target.value)} 
                value={props.notes} />
    ); 
}

export default CustomAdditionalNotes;