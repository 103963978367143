import React from 'react';
// import './ExtraFilters.css';
import _ from 'lodash';
import Block from "components/Block";

const ExtraFilters = ({
        showExtraFilter,
        selectedSections,
        closeExtraFilters,
        brands,
        hasAxles,
        positions,
        selectedAxles,
        chooseBrand,
        chooseAxle,
        selectedBrands
    }) => {
    return (
        <div className={"filters " + (showExtraFilter && selectedSections.length > 0 && "filters--active")}>
            <div className="filters__title">
                <span>Filters</span>
                <i class="fas fa-times" onClick={closeExtraFilters}></i>
            </div>
            <div className="filters__content">
                {
                    (brands && brands.length > 0) &&
                        <Block type="5" title="Brands">
                            <div className="filters__list">
                                {
                                    brands.map((brand) => {
                                        return <div className={"filters__item " + (selectedBrands.includes(brand.name) ? "filters__item--selected" : "")} 
                                                    key={brand.id} 
                                                    onClick={() => chooseBrand(brand.name)}>
                                            {brand.image ? <img src={brand.image}/> : <span>{brand.name}</span>}
                                        </div>;
                                    })
                                }
                            </div>
                        </Block>
                }
                {/* {
                    hasAxles &&
                        <Block type="5" title="Position" style={{marginTop: "24px"}}>
                            <div className="filters__list">
                                {
                                    _.map( positions, (val, key) => {
                                            return val && <div className={"filters__item " + (selectedAxles.includes(key) ? "filters__item--selected" : "")}
                                                onClick={() => chooseAxle(key)}>
                                                    <span>{key}</span>
                                                </div>;
                                    })
                                }
                            </div>
                        </Block>
                } */}
            </div>
        </div>
    );
}

export default ExtraFilters