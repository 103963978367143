import React from 'react';
// import "./Total.css";

function Total({total}) {
    return (
        <div className={"flex fw-700 mt-24 text-uppercase"}>
            <span>Total</span>
            <span>{total}</span>
        </div>
    );
}

export default Total;