import axios from 'axios1';
var store = require('store');

const userLoaded = user => ({ type: 'LOGGED_USER', payload: user });

export const getUser = (email, password) => (
    (dispatch) => {
        console.log('test');
      return axios.post('/login', {email, password})
          .then(response => {
                dispatch( userLoaded(response.data) );
                return response.data;
          })
    }
);

export const switchUser = (userId) => (
    (dispatch) => {
      return axios.post('switchUser', { userId })
          .then(response => {
                if(response.data.token) {
                    dispatch( { type: 'SWITCH_USER', payload: response.data } );
                }
                return response.data;
          })
    }
);


const userLoggedOut = () => ({ type: 'LOGGED_OUT_USER'});

function logoutAndDispatch(dispatch, getState){
        return axios.post("/logout", {
            "token": getState().auth.token,
            "email": getState().auth.user.email
        }).then(response => {
            store.remove("token");
            store.remove("user");
            store.remove("previousToken");
            store.remove("previousUser");
            store.remove("options");
            store.remove("regNumber");
            store.remove("partNumber");
            return dispatch( userLoggedOut() );
        })
}

const cartSetted = cart => ({ type: 'SET_CART_AFTER_LOGIN', payload: cart});

export const setCart = (cart) => (
    (dispatch) => {
        return axios.get("/catalogue/getCart")
                .then(response => {
                    if(response) {
                        dispatch( cartSetted(response.data) );
                        return response.data;
                    }
                });
    }
);

const compareSetted = compare => ({ type: 'SET_COMPARE_AFTER_LOGIN', payload: compare});

export const setCompare = (compare) => (
    (dispatch) => {
        return axios.get("catalogue/getCompare")
                .then(response => {
                    if(response) {
                        dispatch( compareSetted(response.data) );
                        return response.data;
                    }
                });
    }
);

const userDataCleared = () => ({ type: 'CLEAR_USER_DATA'});

export const LogoutUser = () => {
    return (dispatch, getState) => {
        // Todo. Cleaning cart and compare should not be happening here but in api/logout route
        // let cleanCart = axios.delete("/catalogue/clean-cart?token=" + store.get('token'));
        // let clearCompare = axios.delete("/catalogue/clear-compare-list?token=" + store.get('token'));
        // return Promise.all([cleanCart, clearCompare]).then(() => {
        //     dispatch(userDataCleared());
        //     return logoutAndDispatch(dispatch, getState);
        // })
        
        return Promise.all([]).then(() => {
            dispatch(userDataCleared());
            return logoutAndDispatch(dispatch, getState);
        })
    }
}

export const changeToken = (token) => ({
    type: "CHANGE_TOKEN",
    payload: token
})

export const resetSwitchUser = () => ({
    type: "RESET_SWITCH_USER"
})
