import React, { Component } from "react";
import DatePicker from "react-datepicker";
// import "admin/administrator/CustomDatePicker.css";
// import "admin/administrator/CustomElements.css";
import _ from "lodash";
var moment = require('moment');

export default class CustomDatePicker extends Component
{
    constructor(props){
        super(props);

        this.state = {
            date: '',
            selected: '',
            placeholder: "month",
            format: "DD-MM-YYYY",
            showMonthYearPicker: false,
        }
    }

    componentDidMount() {
        var format = this.props.format || this.state.format;
        var showMonthYearPicker = this.props.showMonthYearPicker ? true : false;
        this.setState({
            placeholder: this.props.placeholder,
            format: format,
            showMonthYearPicker: showMonthYearPicker
        })
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps !== this.props) {
            var format = this.state.format;
            var date = this.props.value? moment(this.props.value).format(format) : "";
            this.setState({
                date: date,
                selected: this.props.selected,
            });
        }
    }

    handleChangeDate(date) {
        this.props.onChange(date);
        var format = this.state.format;
        let formatedDate =  moment(date).format(format);
        this.setState({selected: formatedDate}); 
    }

    render()
        { 
            return (
                <div>
                    <DatePicker
                        dateFormat={this.state.format}
                        onChange={this.handleChangeDate.bind(this)}
                        selected={this.state.selected}
                        value={this.state.date}
                        placeholderText={this.state.placeholder}
                        showMonthYearPicker={this.state.showMonthYearPicker}
                    />
                </div>
            );  
        }
    }