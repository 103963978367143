import React, {useEffect, useState, Fragment} from "react";
import Loading from "admin/administrator/LoadingComponent/Loading.component";
import PartNumberInput from 'components/PartNumberInput';
import {connect} from "react-redux";
import alertify from 'alertifyjs';
import axios from "axios1";

const SearchByPartNumber = ({
        searchResult,
        clearPartNumber,
        setClearPartNumber,
        setClearRegNumber,
        setClearVehicle,
        nonAutoActive,
        saveSearchResult
    }) => {
    
    const [partNumber, setPartNumber] = useState("");
    const [loading, setLoading] = useState(false);
    const [isShowing, setIsShowing] = useState(false);
    const triggerFindProductsClick = React.createRef();

    useEffect(() => {
        testOutOfStockMinutes();
        if(searchResult.type === "partNumber") {
            setPartNumber(searchResult.value);
        }
    }, []);

    useEffect(() => {
        if(clearPartNumber) {
            setPartNumber("");
        }        
    }, [clearPartNumber])

    useEffect(() => {

        if(partNumber && searchResult.products.length === 0 && searchResult.filteredProducts.length === 0 && searchResult.sections.length === 0 && searchResult.groups.length === 0) {
            getGroupsByPartNumber();
            setClearRegNumber(true);
            setClearVehicle(true);
        }

    }, [searchResult.products, searchResult.filteredProducts, searchResult.sections, searchResult.groups]);

    const testOutOfStockMinutes = () => {
        axios.get("catalogue/out-of-stock-minutes");
    }

    //NSA041305
    const handlePartNumberChange = event => {
        if(partNumber.length === 1 && event.target.value === '') {
            setClearPartNumber(true);
        }else {
            setPartNumber(event.target.value);
            setClearPartNumber(false);
        }
    }

    const setSearchResultFunc = () => {
        saveSearchResult("products", []);
        saveSearchResult("filteredProducts", []);
        saveSearchResult("sections", []);
        saveSearchResult("groups", []);
        saveSearchResult("searchResult", null);
        saveSearchResult("selectedGroups", []);
        saveSearchResult("selectedSections", []);
    }

    const handleFindButtonClick = () => {
        if (partNumber == "") return;

        setLoading(true);
        setSearchResultFunc();
    }

    const getGroupsByPartNumber = () => {
        setLoading(true);
        axios.get("catalogue/search/by-part-number/" + partNumber)
            .then((response) => {
                // console.log(response.data.groups)
                if(response.data.success) {
                    let groups = response.data.groups;
                    let lookup_id = response.data.lookup_id;
                    if(!Object.keys(groups).length){
                        openModalHandler();
                        // console.log(!Object.keys(groups))
                    } else {                        
                        saveSearchResult("vehicleName", '');
                        saveSearchResult("productsQuantity", 0);
                        saveSearchResult("type", "partNumber");
                        saveSearchResult("value", partNumber);
                        saveSearchResult("groups", groups);
                        saveSearchResult("lookup_id", lookup_id);
                        // saveSearchResult("selectedGroups", []);
                        // saveSearchResult("selectedSections", []);
                        saveSearchResult("selectedBrands", []);
                        // saveSearchResult("products", []);
                        // saveSearchResult("filteredProducts", []);
                        saveSearchResult("productsQuantity", 0);
                        saveSearchResult("showProducts", false);
                        saveSearchResult("hideForPartNumber", true);
                        saveSearchResult("selectedAxlesSection", []);
                        
                        // props.setStateFromChild("lookup_id", lookup_id);
                        // props.setStateFromChild("groups", groups);
                        // props.setStateFromChild("resultSearchTitle", this.state.partNumber);
                    }
                    setLoading(false);
                }else{
                    openModalHandler();
                    alertify.error(response.data.warning?response.data.warning:"An error accured");
                }
            })
    }

    const _handlePartNumberKeyDown = e => {
        if (e.key === 'Enter') {
            handleFindButtonClick();
        }else if( (e.key === 'Backspace' || e.key === 'Delete') && partNumber === getText(document.activeElement)) {
            setClearPartNumber(true);
        }
    }

    const getText = elem => { 
        if((elem.tagName === "INPUT" && elem.type === "text")) {
            return elem.value.substring(elem.selectionStart,
                                        elem.selectionEnd);
        }
        return null;
    }

    const openModalHandler = value => {
        setIsShowing(true);
        // setNotificationModalMessage(value);
    }
    const closeModalHandler = value => {
        setIsShowing(false);
    }

    alertify.defaults = {
        notifier: {
            position: 'top-right',
            delay: 5
        }
    }

    return (
        <Fragment>
            <Loading loading={loading}/>
            <div className="block-content">
                <PartNumberInput
                    partNumber={ partNumber }
                    _handlePartNumberKeyDown={ _handlePartNumberKeyDown }
                    handlePartNumberChange={ handlePartNumberChange }
                    triggerFindProductsClick={ triggerFindProductsClick }
                    handleFindButtonClick= { handleFindButtonClick }                
                    placeholder={ 'Enter Part Number' }
                    isShowing={ isShowing }
                    isDisabled={ nonAutoActive}
                    closeModalHandler={ closeModalHandler }
                />
            </div>
        </Fragment>
    );
}

const mapStateToProps = (state) => ({
    options: state.products.options,
    searchResult: state.searchResult
})

const mapDispatchToProps = dispatch => {
    return {
        saveSearchResult: (key, value) => dispatch({
            type: 'SAVE_SEARCH_RESULTS',
            payload: {key, value}
        })
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SearchByPartNumber);