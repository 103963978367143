import React, {useEffect, useState, useRef} from 'react';
// import "./DropZone.css";
import alertify from 'alertifyjs';

const DropZone = (props) => {
    const [images, setImages] = useState([]);

    useEffect(() => {
        props.clearImages();
        props.processImages(images);
    }, [images]);

    const fileInputRef = useRef();

    const dragOver = (e) => {
        e.preventDefault();
    }
    
    const dragEnter = (e) => {
        e.preventDefault();
    }
    
    const dragLeave = (e) => {
        e.preventDefault();
    }
    
    const fileDrop = (e) => {
        e.preventDefault();
        const files = e.dataTransfer.files;
        if (files.length) {
            handleFiles(files);
        }
    }

    const validateFile = (file) => {
        const validTypes = ['image/jpeg', 'image/jpg', 'image/png'];
        if (validTypes.indexOf(file.type) === -1) {
            return false;
        }
        return true;
    }

    const fileSize = (size) => {
        if (size === 0) return '0 Bytes';
        const k = 1024;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
        const i = Math.floor(Math.log(size) / Math.log(k));
        return parseFloat((size / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
    }

    function parseFiles(file) {
		return new Promise((resolve, reject)=>{
			let reader = new FileReader();
			reader.onload = function(){
				if(validateFile(file)) {
                    if(file.size > 1000000) {
                        return resolve({imagePreview: reader.result, name: file.name, active: 0, is_visible: 1, size: file.size, sizeForHumans: fileSize(file.size), invalid: true, error: "An error has occured: The image is too large"});
                    } else {
                        return resolve({imagePreview: reader.result, name: file.name, active: 0, is_visible: 1, size: file.size, sizeForHumans: fileSize(file.size)});
                    }
                } else {
                    return resolve({imagePreview: reader.result, name: file.name, active: 0, is_visible: 1, size: file.size, sizeForHumans: fileSize(file.size), invalid: true, error: "An error has occured: The image type is invalid"});
                }
			}
			reader.readAsDataURL(file);
		})
    }

    const handleFiles = async (files) => {
        let imagesArr = [...props.images];

        let images = await Promise.all(Object.keys(files).map(i => {
            return parseFiles(files[i])
        }));

        for(let i = 0; i < images.length; i++) {
            imagesArr.push(images[i]);
        }

        setImages(imagesArr);
    }

    const fileInputClicked = () => {
        fileInputRef.current.click();
    }

    const filesSelected = () => {
        if (fileInputRef.current.files.length) {
            handleFiles(fileInputRef.current.files);
        }
    }

    return (
        <>
            <div className="dropzone" 
                onDragOver={dragOver}
                onDragEnter={dragEnter}
                onDragLeave={dragLeave}
                onDrop={fileDrop}
                >
                    <div className="dropzone__icon">
                        <i class="fas fa-upload fa-2x"></i>
                    </div>
                    <div className="dropzone__title">Drag and drop your files here</div>
                    <div className="dropzone__browse">Or <span onClick={fileInputClicked}>browse</span> to choose a file</div>
                    <div className="dropzone__formats">Accepted file formats: .jpg and .png</div>
                    <div className="dropzone__formats dropzone__formats--size">(At least 1250 x 703 px maximum size 1MB)</div>
                    <input
                        ref={fileInputRef}
                        className="dropzone__input"
                        type="file"
                        multiple={props.multiple}
                        onChange={filesSelected}
                    />
            </div>
        </>
    )
}
export default DropZone;