import React, {Component, Fragment} from "react";
import PageTitle from "admin/administrator/PageTitle";
import {BrowserRouter as Router, Route, Redirect} from "react-router-dom";
// import "admin/administrator/account/styles/SelectAccount.css";
import CustomContainer from "custom_components/CustomContainer";
import _ from "lodash";
import Block from "components/Block";

export default class SelectAccount extends Component {
    constructor(props) {
        super(props);

        this.state = {
            redirect: false,
            target: '',
            accountTypes: [
                {
                    id: null,
                    name: 'Select Account Type',
                    link: ''
                },
                {
                    id: 1,
                    name: 'Branch',
                    link: '/admin/addBranch'
                },
                {
                    id: 2,
                    name: 'Supplier',
                    link: '/admin/addSupplier'
                },
                {
                    id: 3,
                    name: 'Customer',
                    link: '/admin/addAccount'
                }
            ],
        }
    }

    handleInputChange(event) {
        this.setState({
            redirect: true,
            target: event.target.value
        })
    }

    renderRedirect = () => {
        if (this.state.redirect) {
            this.props.history.push("/admin/selectAccount");
            return <Redirect to={this.state.target}/>
        }
    }

    render() {
        return (
            <Fragment>
                {this.renderRedirect()}
                <div className={"page-content admin-wrapper"}>
                    <div className="grid">
                        <div className="grid__row">
                            <div className="grid__column grid__column--3">
                                <Block title={"account details"} type={"1"}>
                                    <div className="selector">
                                        <select onChange={this.handleInputChange.bind(this)} className={"selector__selector"}>
                                            {
                                                this.state.accountTypes.map((accountType) => {
                                                    return <option value={accountType.link}
                                                                key={accountType.id}>{accountType.name}</option>
                                                })
                                            }
                                        </select>
                                    </div>
                                </Block>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}