import React, { useState } from 'react'
import FormInput from "admin/administrator/FormInput/FormInput.component";

const CustomTableSearch = ({ search, setSearch }) => {
    return ( 
        <FormInput
            type='text'
            placeholder='Search'
            error={""}
            value={search}
            onChange={event => setSearch(event.target.value)}
        />
    );
}

export default CustomTableSearch;