import React from "react";
// import "admin/administrator/CustomRadioButton.css";
import { Fragment, useRef } from "react";

const CustomRadioButton = ({ checked, onChange, name, label, disabled }) => {
    const inputEl = useRef(null);

    const handleClick = () => {
        inputEl.current.click();
    };

    return (
        <div className={"radio " + (disabled ? 'radio--disabled' : '')}>
            <input 
                className="radio__input"
                type="radio"
                name={name}
                checked={checked}
                onChange={onChange || null}
                key={"element" + name}
                id={"element" + name}
                disabled={disabled ? true : false}             
                ref={inputEl}
            />
            <span className="radio__label" onClick={handleClick} >{label}</span>
        </div>
    )
}
export default CustomRadioButton;
