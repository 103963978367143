import React, {Component, useState, useEffect } from "react";
import NoResult from "images/no-result.png";
import SectionTable from "components/SectionTable";
import _ from 'lodash';

const Products = ({
        products,
        stockLoad,
        section_lookup_id,
        lookup,
        axlesArr
    }) => {
    
    const [showItems, setShowItems] = useState(false);

    const checkSaveNumber = products => {
        setShowItems(false);
        _.map(products, item => {
            if(item.length > 0)
                setShowItems(true);
        });
    }

    useEffect(() => {
        checkSaveNumber(products);
    }, [products]);
    
    return (
        <div className="products">
            {
                showItems ?
                    Object.keys(products).map(section => {
                        return products[section].length > 0 &&
                            <SectionTable stockLoad={stockLoad}
                                        key={section}
                                        section_lookup_id={section_lookup_id}
                                        lookup={lookup}
                                        axlesArr={axlesArr}
                                        section={section}
                                        products={products}/>
                    }) : <div className={"no-result-wrapper"} > </div>
            }  
            {/* div className={"no-result-wrapper"} > <img src={NoResult}/> </div> */}
        </div> 
    );
}
export default Products;

// export default class Products extends Component
// { 
//     constructor(props)
//     {
//         super(props);
//         this.state = {
//             showItems: false
//         }
//     }

//     checkSaveNumber = products => {
//         this.setState({showItems: false});
//         _.map(products, item => { 
//             if(item.length > 0)
//                 this.setState({showItems: true});
//         });
//     }

//     componentDidMount() {
//         this.checkSaveNumber(this.props.products);
//     }
    
//     componentWillReceiveProps (newProps) {
//         if( newProps.products !== this.props.products ){
//             this.checkSaveNumber(newProps.products);
//         }else {
//            return null;
//         }
//     }
//     render(){
//         return (
//             <div className="products">
//                 {
//                     this.state.showItems ? 
//                         Object.keys(this.props.products).map((section) => {
//                             {
//                                 return this.props.products[section].length > 0 &&
//                                     <SectionTable stockLoad={this.props.stockLoad} key={section} section_lookup_id={this.props.section_lookup_id} lookup={this.props.lookup} axlesArr={this.props.axlesArr} section={section} products={this.props.products}/>
//                             }
//                         }) 
//                     : <div className={"no-result-wrapper"} > <img src={NoResult}/> </div>
//                 }  
//             </div> 
//         );
//     }
// }