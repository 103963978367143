import React, {Component} from "react";
import _ from "lodash";
// import "admin/administrator/AccountDashboard/AccountDetails.css";
import CustomDatePicker from 'admin/administrator/CustomDatePicker';
import CustomerLoginsUsed from "../GeneralClientOrBranchStatistics/CustomerLoginsUsed";
import VrmLookups from "../GeneralClientOrBranchStatistics/VrmLookups";
import Cost from "../GeneralClientOrBranchStatistics/Cost";
import Orders from "../GeneralClientOrBranchStatistics/Orders";
import axios from "axios1";
import alertify from "alertifyjs";
import CustomContainer from 'custom_components/CustomContainer.js';
import {BrowserRouter as Router, Route, NavLink} from "react-router-dom";
import {setDate} from "redux/actions/dashboardDate";
import {connect} from "react-redux";
import Block from "components/Block";

var moment = require('moment');

class AccountDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedMonth: new Date(),
            metaStats: {
                branchesNumber: '',
                branchesLoginNumber: {
                    active: '',
                    all: ''
                },
                costumersLoginNumber: {
                    active: '',
                    all: ''
                },
                vrmLookups: '',
                totalFee: '',
                ordersAmount: '',
                branch: []
            }
        }
    }

    handleChangeDate(date) {
        this.props.setDate(date);
    }

    componentDidMount() {
        this.getMetaStats();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.date !== this.props.date) {
            this.getMetaStats();
        }
    }

    getMetaStats() {
        axios.get('stats/accounts/' + this.props.id + "?date=" + moment(this.props.date).format('YYYY-MM'))
            .then((response) => {
                if (response.data.success) {
                    let metaStats = response.data.data;
                    console.log('metaStats', response.data.data)
                    this.setState({metaStats: metaStats});
                } else {
                    alertify.error(response.data.msg);
                }
            });

    }

    render() {
        let account = this.state.metaStats.branch;
        let metaStats = this.state.metaStats;

        return (
            <Block type={"1"} title={account.name ? account.name.replace(`_deleted_${account.id}`, "") : account.name }>
                <CustomDatePicker value={this.props.date}
                                  selected={this.props.date}
                                  placeholder={"Month"}
                                  onChange={this.handleChangeDate.bind(this)}
                                  format={"MMMM YYYY"}
                                  showMonthYearPicker={true}>
                </CustomDatePicker>
                {
                    this.props.user ?
                        <NavLink to={this.props.user.account.business_type_id == 3 ? "/admin/branch/" + this.props.user.account.id + "/" + this.props.user.account.name + "/dashboard/" : "/admin/client/dashboard"}
                                exact className="flex mt-24">
                            <div className="flex" style={{color: "#333"}}>
                                <i className="fas fa-home fs-24"></i>
                                <div className="ml-8">Home</div>
                            </div>
                        </NavLink> : null
                }
                <CustomerLoginsUsed active={metaStats.costumersLoginNumber.active} all={metaStats.costumersLoginNumber.all}></CustomerLoginsUsed>
                <VrmLookups vrmLookups={metaStats.vrmLookups}></VrmLookups>
                <Cost cost={metaStats.totalFee}></Cost>
                <Orders orders={metaStats.ordersAmount}></Orders>
                <div className="mt-40">
                    <div className="mb-16">
                        <div className="fw-700 mb-8">Contact</div>
                        <div>{account.contact_name}</div>
                    </div>
                    <div className="mb-16">     
                        <div className="fw-700 mb-8">Address</div>     
                        <div>{account.address_1}<br/>{account.address_2}</div>
                    </div>
                    <div className="mb-16">
                        <div className="fw-700 mb-8">Tel</div>
                        <div>{account.phone}</div>
                    </div>
                    <div>
                        <div className="fw-700 mb-8">Email</div>
                        <div>{account.email}</div>
                    </div>
                </div>
            </Block>

        );
    }

}

const mapStateToProps = (state) => ({
    user: state.auth.user,
    date: state.dashboardDate.date
});

const mapDispatchToProps = dispatch => {
    return {
        setDate: (date) => dispatch(setDate(date)),
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(AccountDetails);