import React, {Fragment, useState, useEffect} from 'react';
// import "./UploadModal.css";
import CustomButton from "admin/administrator/CustomButton";
import alertify from 'alertifyjs';
import DropZone from "components/DropZone";
import Modal from "components/Modal";

function UploadModal(props) {
    const [images, setImages] = useState([]);

    const saveImage = () => {
        if(images.length == 0) {
            alertify.error("Please choose an image");
            return;
        }

        let imgs = images.filter((image) => {
            return !image.invalid;
        })

        props.setImagePreview(imgs);
        props.closeModal();
        setImages([]);
    }

    const cancelSaving = () => {
        clearImages();
        props.closeModal();
    }

    const clearImages = () => {
        setImages([]);
    }

    const removeImage = (index) => {
        let imgs = [...images];
        imgs.splice(index, 1);
        setImages(imgs);
    }

    return (
        <Fragment>
            <Modal size="sm" title={"Upload Your Attachment"} type="dark" active={props.showModal} closeModal={() => cancelSaving()}>
                <DropZone processImages={setImages} clearImages={() => clearImages()} images={images} multiple={props.multiple}/>
                {
                    images.length > 0 &&
                    <div className="images">
                        {
                            images.map((image, index) => {
                                return <div className={"images__item " + (image.invalid ? "images__item--invalid" : "")}>
                                            <div className="images__image" style={{backgroundImage: "url("+image.imagePreview+")"}}></div>
                                            <div className="images__content">
                                                <div class="images__name">
                                                    <span>{!image.invalid ? image.name + " " + "("+image.sizeForHumans+")" : image.error}</span>
                                                    <i class="fas fa-times" onClick={removeImage.bind(this, index)}></i>
                                                </div>
                                                <div className="images__progress"></div>
                                            </div>
                                        </div>
                            })
                        }
                    </div>
                }
                <div className="modal__actions">
                    <CustomButton type={"lightgray"} title={"Cancel"} onClick={cancelSaving}/>
                    <CustomButton type={"blue"} title={"Save"} onClick={saveImage} margins={['ml-8']}/>
                </div>
            </Modal>
            {/* <div className={"modal-wrapper " + (props.showModal ? "active" : "")}>
                <div className="modal-overlay" onClick={cancelSaving}></div>
                <div className="modal-block">
                    <div className="modal-top">
                        <h3>Upload Your Attachment</h3>
                        <i class="fas fa-times fa-2x" onClick={cancelSaving}></i>
                    </div>
                    <div className="modal-center">
                        <DropZone processImages={setImages} clearImages={() => clearImages()} images={images} multiple={props.multiple}/>
                        {
                            images.length > 0 ?
                            <div className="images-list">
                                {
                                    images.map((image, index) => {
                                        return <div className={"image-item " + (image.invalid ? "image-invalid" : "")}>
                                                    <div className="left" style={{backgroundImage: "url("+image.imagePreview+")"}}></div>
                                                    <div className="right">
                                                        <div class="image-name">
                                                            <span>{!image.invalid ? image.name + " " + "("+image.sizeForHumans+")" : image.error}</span>
                                                            <i class="fas fa-times" onClick={removeImage.bind(this, index)}></i>
                                                        </div>
                                                        <div className="image-progress"></div>
                                                    </div>
                                                </div>
                                    })
                                }
                            </div> : null
                        }
                    </div>
                    <div className="modal-bottom">
                        <CustomButton type={"lightgray"} title={"Cancel"} onClick={cancelSaving}/>
                        <CustomButton type={"blue"} title={"Save"} onClick={saveImage} margins={['ml-2']}/>
                    </div>
                </div>
            </div> */}
        </Fragment>
    )
}

export default UploadModal