import React, {useState, useEffect, Fragment} from "react";
import AccountDetails from "admin/administrator/AccountDetails";
import Logins from "admin/administrator/Logins";
import StockPreferences from "admin/administrator/StockPreferences";
import CustomButton from "admin/administrator/CustomButton";
import {BrowserRouter as Router, Route, NavLink} from "react-router-dom";
import UserPreferences from 'admin/administrator/UserPreferences.js';
import {withRouter} from 'react-router-dom';
import alertify from 'alertifyjs';
import axios from "axios1";
import PricingLevels from "admin/administrator/PricingLevels";
import _ from "lodash";
import ChangeSaveOrDiscardModal from 'admin/administrator/ChangeSaveOrDiscardModal/ChangeSaveOrDiscardModal';
import Notify from "components/Notify";
import Block from "components/Block";
import Loading from "admin/administrator/LoadingComponent/Loading.component";


const AddAccount = props => {
    const [account, setAccount] = useState({});
    const [logins, setLogins] = useState([]);
    const [branches, setBranches] = useState([]);
    const [currentBranch, setCurrentBranch] = useState({});
    const [brands, setBrands] = useState([]);
    const [priceLevels, setPriceLevels] = useState([]);
    const [errors, setErrors] = useState([]);
    const [price_level_id, setPrice_level_id] = useState(null);
    const [updatedBranches, setUpdatedBranches] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isSupplier, setIsSupplier] = useState(false);
    const [notificationStatus, setNotificationStatus] = useState(false);
    const [cataloguePreferences, setCataloguePreferences] = useState([]);
    const [catalogueModalShow, setCatalogueModalShow] = useState(false);
    const [shouldBlockNavigation, setShouldBlockNavigation] = useState(false);
    const [branchIds, setBranchIds] = useState([]);
    const [approveModal, setApproveModal] = useState({
        show: false,
        name: '',
        title: '',
        message: 'Would you like to send an email containing login credentials to new each login ?'
    });
    const [vrmCountries, setVrmCountries] = useState();
    
    useEffect(() => {
        axios.get("catalogue/get-vrm-countries")
            .then((response) => {
                if (response.statusText === "OK") {
                    setVrmCountries(response.data);
                }
            })
    }, []);

    var unblock = () => {};

    useEffect(() => {
        getBranches();
    }, [])

    useEffect(() => {
        unblock();
    })

    useEffect(() => {
        if (cataloguePreferences.hasOwnProperty('form') || cataloguePreferences.hasOwnProperty('groups')) {
            unblock = props.history.block(() => {
                setShouldBlockNavigation(true);
                return false;
            });
        }
    }, [cataloguePreferences])

    useEffect(() => {
        let loginsWithPreferences = logins.filter((login) => {
            return (login.preferences && login.preferences.groups && login.preferences.form) ? login : null;
        })

        if (loginsWithPreferences.length > 0) {
            unblock = props.history.block(() => {
                setShouldBlockNavigation(true)
                return false;
            });
        }
    }, [logins])

    useEffect(() => {
        let branchesIds = [];
        _.map(updatedBranches, function (element) {
            if (element.checked) {
                branchesIds.push(element.id)
            }
        })
        setBranchIds(branchesIds);
    }, [updatedBranches])

    const getBranches = () => {
        axios.get("catalogue/accounts/branches/customer")
            .then((response) => {
                if (response.data.success) {
                    setUpdatedBranches(response.data.branches);
                }
            })
    }

    const getPreferences = async () => {
        let collection = cataloguePreferences;
        if (!collection.form) {
            await axios.get("catalogue/preferences")
                .then((response) => {
                    if (response.data.success) {
                        collection = response.data.preferences;
                        return collection;
                    }
                })
        }
        return collection;
    }

    const getSections = group_id => {
        return axios.get("catalogue/preferences/sections?groupId=" + group_id);
    }

    const getBrands = section_id => {
        return axios.get("catalogue/preferences/brands?sectionId=" + section_id)
    }

    // const updatePreferencesValues = preferences => setCataloguePreferences({ ...preferences, type: "account" });

    const setPriceLevelsFromChild = priceLevel => {
        const newPriceLevels = priceLevels;

        let existingPriceLevel = _.find(newPriceLevels, function (element) {
            return (element.section_id == priceLevel.section_id && element.brand_id == priceLevel.brand_id) ? true : false;
        })

        if (existingPriceLevel) {
            existingPriceLevel.price_level_id = priceLevel.price_level_id;
            existingPriceLevel.price_level = priceLevel.price_level
        } else {
            newPriceLevels.push(priceLevel);
        }

        setPriceLevels([...newPriceLevels])
    }

    const setCurrentBranchFromChild = name => {
        let newBranches = updatedBranches;
        let index = _.findIndex(newBranches, {'live': 1});
        if (index > -1) {
            name === "" ? delete newBranches[index] : newBranches[index].name = name;
        } else {
            newBranches.unshift({name: name, active: 1, live: 1, id: 0});
        }
        setBranches(newBranches);
    }

    const setIsSupplierFromChild = accountType => {
        setIsSupplier((accountType == "Supplier" ? true : false));
    }

    const setSPriceLevelId = value => {
        let currentAccount = account;
        currentAccount['price_level_id'] = value;
        setAccount(currentAccount);
        setPrice_level_id(value);
    }

    const setBespokePrice = value => {
        let currentAccount = account;
        currentAccount['bespoke_price_id'] = value;
        setAccount(currentAccount);
    }

    const removePriceLevelException = priceLevel => {
        setLoading(true);
        let newPriceLevels = _.clone(priceLevels);
        _.remove(newPriceLevels, function (item) {
            return (item.brand_id == priceLevel.brand_id && item.section_id == priceLevel.section_id) ? true : false;
        });
        setLoading(false);
        setPriceLevels(newPriceLevels);
    }
    
    const handleSaveClick = () => {
        if (logins && logins.length > 0 && branchIds.length > 0) {
            let collection = approveModal;
            collection.show = true;
            collection.title = "You have created new logins for account " + account.name + ".";
            setShouldBlockNavigation(false);
            setApproveModal({...collection});
        }else {
            saveAccount(false);
        }
        props.history.block(() => true);
    }

    const saveAccount = (status = false) => {
        setNotificationStatus(status);
        const newAccount = account;
        newAccount.price_level_id = price_level_id;
        let newLogins = JSON.parse(JSON.stringify(logins));
        const newCataloguePreferences = JSON.parse(JSON.stringify(cataloguePreferences));
        if (newLogins && newLogins.length > 0) {
            newLogins = _.map(newLogins, function (login) {
                if(typeof login.deleted_at == 'undefined' || (typeof login.deleted_at != 'undefined' && login.deleted_at == null )) {
                    if (login.preferences) {
                        if (login.preferences.groups) {
                            let groups = login.preferences.groups;
                            login.preferences.groups = filtreGroups(groups);
                        }
                        if (login.preferences.form) {
                            if (login.preferences.form) {
                                login.preferences.form = filtreForm(login.preferences.form);
                            }
                        }
                    }
                    return login;
                }
            })
        }

        newCataloguePreferences.groups = filtreGroups(newCataloguePreferences.groups)

        if (newCataloguePreferences.form) {
            newCataloguePreferences.form = this.filtreForm(newCataloguePreferences.form);
        }

        if(branchIds.length == 0) {
            alertify.error("At least one branch should be checked.");
            return;
        }

        const newApproveModal = approveModal;
        newApproveModal.show = false;
        
        setLoading(true);
        setApproveModal(newApproveModal);
        setShouldBlockNavigation(false);
        axios.post("catalogue/accounts", {
            account: newAccount,
            logins: newLogins,
            cataloguePreferences: newCataloguePreferences,
            notificationStatus: notificationStatus,
            priceLevels: priceLevels,
            branchIds: branchIds
        }).then((response) => {
            let errors = response.data.errors ? response.data.errors : [];
            setLoading(false);
            setErrors(errors);
            if(!response.data.errors) {
                alertify.success(response.data.message);
                props.history.block(() => true);
                props.history.push("/admin/accounts");
            }else {
                let message = response.data && response.data.errors && response.data.errors.length > 0 ? response.data.errors.toString() : response.data.message;
                alertify.error(message);
            }
        })
    }

    const filtreForm = form => {
        if (form.additional_elements) {
            let additional_elements = _.filter({...form.additional_elements}, function (additional_element) {
                if (additional_element.checked) {
                    return additional_element.value;
                }
            })
            form.additional_elements = _.map(additional_elements, 'value');
        }
        if(form.search_types) {
            let search_types = _.filter({...form.search_types}, function (search_type) {
                if (search_type.checked) {
                    return search_type.value;
                }
            })
            form.search_types = _.map(search_types, 'value');
        }
        if (form.manufacturers) {
            let manufacturers = _.filter({...form.manufacturers}, function (manufacture) {
                if (manufacture.checked) {
                    return manufacture.name;
                }
            })
            form.manufacturers = _.map(manufacturers, 'name');
        }
        return form;
    }

    const filtreGroups = groups => {
        groups = _.map(groups, function (group) {
            let response = {
                id: group.id,
                sections: {}
            };
            if (group.sections) {
                response.sections = _.map(group.sections, function (section) {
                    let response = {
                        id: section.id,
                        checked: section.checked,
                    };
                    if (section.brands) {
                        let brands = _.filter(section.brands, function (brand) {
                            if (brand.checked) {
                                return brand.id;
                            }
                        })

                        response.brands = _.map(brands, 'id');
                    }

                    return response;
                })
            }
            return response;
        });
        return groups;
    }

    const closeNotifyApprove = () => {
        let collection = approveModal;
        collection.show = false;
        setApproveModal({...collection});
    }

    alertify.defaults = {
        notifier: {
            position: 'top-right',
            delay: 5
        }
    }
    
    return (
        <Fragment>
            <Loading loading={loading}/>
            {
                shouldBlockNavigation &&
                    <ChangeSaveOrDiscardModal
                        saveChanges={ handleSaveClick }
                        closeChangesModalDisregard={ () => {
                            logins.map((login) => {
                                delete login.preferences.groups;
                                delete login.preferences.form;
                            });
                            setShouldBlockNavigation(false)
                            props.history.block(() => true);
                        }}
                        modalShow={ shouldBlockNavigation }
                        closeChangesModal={ () => setShouldBlockNavigation(false) }
                    />
            }
            <div className={"page-content admin-wrapper"}>
                <div className="grid">
                    <div className="grid__row">
                        <div className="grid__column grid__column--3">
                            <Block type="1" title="customer details">
                                <AccountDetails account={ account }
                                        setAccount={ setAccount }
                                        setCurrentBranch={ setCurrentBranchFromChild }
                                        setPriceLevels={ setPriceLevelsFromChild }
                                        savedPriceLevels={ priceLevels }
                                        isSupplier={ isSupplier }
                                        setIsSupplier={ setIsSupplierFromChild }
                                        brands={ brands }
                                        errors={ errors }
                                        removePriceLevelException={ removePriceLevelException }
                                        setErrors={ setErrors}
                                        vrmCountries={vrmCountries}/>
                            </Block>
                            <Block type="6" title="pricing" style={{marginTop: "24px"}}>
                                <PricingLevels savedPriceLevels={ priceLevels }
                                    removePriceLevelException={ removePriceLevelException }
                                    save={ setPriceLevelsFromChild }
                                    setSPriceLevelId={ setSPriceLevelId }
                                    setBespokePrice={ setBespokePrice }
                                    errors={ errors }/>
                            </Block>
                        </div>
                        <div className="grid__column grid__column--6">
                            <Block type="1" title="users">
                                <Logins cataloguePreferences={ {...cataloguePreferences} }
                                        logins={ logins }
                                        setLogins={ setLogins }
                                        isCustomer={true}
                                        edit={false}/>
                            </Block>
                        </div>
                        <div className="grid__column grid__column--3">
                            <Block type="1" title="stock preferences">
                                <StockPreferences branches={ updatedBranches }
                                            setBranches={ setUpdatedBranches }
                                            currentBranch={ currentBranch }
                                            customer="true"/>
                            </Block>
                        </div>
                    </div>
                </div>
                <div className="page-actions">
                    <div className="page-actions__action">
                        <CustomButton title={"Account Preferences"} type={"gray"} onClick={() => setCatalogueModalShow(true)}/>
                    </div>
                    <div className="page-actions__action">
                        <NavLink to={"/admin/client/dashboard"}>
                            <CustomButton title={"Cancel"} type={"lightgray"}/>
                        </NavLink>
                    </div>
                    <div className="page-actions__action">
                        <CustomButton title={"Save"} type={"green"} onClick={handleSaveClick}/>
                    </div>
                </div>
            </div>

            <UserPreferences show={ catalogueModalShow }
                        preferences={ getPreferences }
                        getSections={ getSections }
                        getBrands={ getBrands }
                        currentElement={ {name: '', title: "Catalogue Preferences for Account"} }
                        onHide={ setCatalogueModalShow }
                        isCustomer={ true }
                        updatePreferencesValues={ preferences => setCataloguePreferences({ ...preferences, type: "account" }) }/>
        
            <Notify type={"danger"}
                visible={ approveModal.show }
                close={ closeNotifyApprove }
                primaryText={ approveModal.title }
                secondaryText={ approveModal.message }
                actions={[
                    {
                        name: "no",
                        action: saveAccount.bind(this, false),
                        type: "lightgray"
                    },
                    {
                        name: "yes",
                        action: saveAccount.bind(this, true),
                        type: "red"
                    }
                ]}/>

        </Fragment> 
    );
}

export default withRouter(AddAccount);

// class AddAccount extends Component {
//     constructor(props) {
//         super(props);

//         this.state = {
//             account: {},
//             logins: [],
//             branches: [],
//             priceLevels: [],
//             updatedBranches: [],
//             loading: false,
//             cataloguePreferences: [],

//             visible: false,
//             catalogueModalShow: false,
//             accountId: null,
//             currentBranch: "",
//             isSupplier: false,
//             brands: [],
//             apiId: null,
//             errors: [],
//             shouldBlockNavigation: false,
//             price_level_id: null,
//             approveModal: {
//                 show: false,
//                 name: '',
//                 title: '',
//                 message: 'Would you like to send an email containing login credentials to new each login ?'
//             }
//         }
//         this.updatePreferencesValues = this.updatePreferencesValues.bind(this);
//         this.unblock = () => {}
//     }

//     componentDidMount() {
//         this.getBranches();
//     }

//     componentWillUnmount() {
//         this.unblock();
//     }

//     async getPreferences() {
//         let cataloguePreferences = this.state.cataloguePreferences;
//         if (!cataloguePreferences.form) {
//             await axios.get("catalogue/preferences")
//                 .then((response) => {
//                     if (response.data.success) {
//                         cataloguePreferences = response.data.preferences;
//                         return cataloguePreferences;
//                     }
//                 })
//         }
//         return cataloguePreferences;
//     }

//     getSections(group_id) {
//         return axios.get("catalogue/preferences/sections?groupId=" + group_id);
//     }

//     getBrands(section_id) {
//         return axios.get("catalogue/preferences/brands?sectionId=" + section_id)
//     }

//     getBranches() {
//         axios.get("catalogue/accounts/branches/customer")
//             .then((response) => {
//                 if (response.data.success) {
//                     this.setState({updatedBranches: response.data.branches})
//                 }
//             })
//     }

//     updatePreferencesValues(preferences) {
//         preferences.type = "account";
//         this.setState({cataloguePreferences: preferences}, () => {
//             if (this.state.cataloguePreferences.hasOwnProperty('form') || this.state.cataloguePreferences.hasOwnProperty('groups')) {
//                 this.unblock = this.props.history.block(() => {
//                     this.setState({shouldBlockNavigation: true})
//                     return false;
//                 });
//             }
//         });
//     }

//     //---> Set Data From Child Begin  <---

//     setAccount(account) {
//         this.setState({account: account})
//     }

//     setPriceLevels(priceLevel) {
//         let priceLevels = this.state.priceLevels;
//         let existingPriceLevel = _.find(priceLevels, function (element) {
//             return (element.section_id == priceLevel.section_id && element.brand_id == priceLevel.brand_id) ? true : false;
//         })
//         if (existingPriceLevel) {
//             existingPriceLevel.price_level_id = priceLevel.price_level_id;
//             existingPriceLevel.price_level = priceLevel.price_level
//         } else {
//             priceLevels.push(priceLevel);
//         }

//         this.setState({priceLevels: priceLevels})
//     }

//     setBrands(brands) {
//         this.setState({brands: brands});
//     }

//     setCurrentBranch(name) {
//         let branches = this.state.updatedBranches;
//         let index = _.findIndex(branches, {'live': 1});
//         if (index > -1) {
//             name === "" ? delete branches[index] : branches[index].name = name;
//         } else {
//             branches.unshift({name: name, active: 1, live: 1, id: 0});
//         }
//         this.setState({branches: branches});
//     }

//     setIsSupplier(accountType) {
//         let isSupplier = accountType == "Supplier" ? true : false;
//         this.setState({isSupplier: isSupplier});
//     }

//     setLogins(logins) {
//         this.setState({logins: logins}, () => {
//             let logins = this.state.logins;
//             let loginsWithPreferences = logins.filter((login) => {
//                 return (login.preferences && login.preferences.groups && login.preferences.form) ? login : null;
//             })
//             if (loginsWithPreferences.length > 0) {
//                 this.unblock = this.props.history.block(() => {
//                     this.setState({shouldBlockNavigation: true})
//                     return false;
//                 });
//             }
//         });
//     }

//     setSPriceLevelId(value) {
//         let state = {...this.state};
//         state['account']['price_level_id'] = value;
//         state['price_level_id'] = value;
//         this.setState(state);
//     }

//     setBespokePrice(value) {
//         let state = {...this.state};
//         state['account']['bespoke_price_id'] = value;
//         this.setState(state);
//     }

//     setStateFromChild(key, value) {
//         let state = {};
//         state[key] = value;
//         this.setState(state);
//     }

//     //<--- Set Data From Child End --->

//     removePriceLevelException(priceLevel) {
//         this.setState({loading: true});
//         let priceLevels = this.state.priceLevels;
//         _.remove(priceLevels, function (item) {
//             return (item.brand_id == priceLevel.brand_id && item.section_id == priceLevel.section_id) ? true : false;
//         });
//         this.setState({loading: false, priceLevels: priceLevels});
//     }
    
//     handleSaveClick() {
//         let data = JSON.parse(JSON.stringify(this.state));
//         if (data.logins && data.logins.length > 0) {
//             let approveModal = this.state.approveModal;
//             approveModal.show = true;
//             approveModal.title = "You have created new logins for account " + data.account.name + ".";
//             this.setState({shouldBlockNavigation: false, approveModal : approveModal})
//         }else {
//             this.saveAccount(false);
//         }
//     }

//     saveAccount(status = false) {
//         let data = JSON.parse(JSON.stringify(this.state));
//         data.notificationStatus = status;
//         data.account.price_level_id = this.state.price_level_id;
//          let self = this;
        
//         if (data.logins && data.logins.length > 0) {
//             data.logins = _.map(data.logins, function (login) {
//                 if(typeof login.deleted_at == 'undefined' || (typeof login.deleted_at != 'undefined' && login.deleted_at == null )) {
//                     if (login.preferences) {
//                         if (login.preferences.groups) {
//                             let groups = login.preferences.groups;
//                             login.preferences.groups = self.filtreGroups(groups);
//                         }
//                         if (login.preferences.form) {
//                             if (login.preferences.form) {
//                                 login.preferences.form = self.filtreForm(login.preferences.form);
//                             }
//                         }
//                     }
//                     return login;
//                 }
//             })
//         }

//         data.cataloguePreferences.groups = this.filtreGroups(data.cataloguePreferences.groups)

//         if (data.cataloguePreferences.form) {
//             data.cataloguePreferences.form = this.filtreForm(data.cataloguePreferences.form);
//         }

//         // For stock preferences --->
//         let branchesIds = [];
//         _.map(data.updatedBranches, function (element) {
//             if (element.checked) {
//                 branchesIds.push(element.id)
//             }
//         })
//         data.branchIds = branchesIds;
//         // <---

//         let approveModal = this.state.approveModal;
//         approveModal.show = false;
//         this.setState({loading: true, approveModal: approveModal, shouldBlockNavigation: false});
//         axios.post("catalogue/accounts", data)
//             .then((response) => {
//                 let errors = response.data.errors ? response.data.errors : [];
//                 this.setState({loading: false, errors: errors});
//                 if(!response.data.errors) {
//                     this.unblock();
//                     this.props.history.push("/admin/accounts");
//                 }
//                 !response.data.errors ? alertify.success(response.data.message) : (response.data.errors ? alertify.error(response.data.errors.toString()) : alertify.error("Something went wrong"));
//             })
//     }

//     filtreForm(form) {
//         if (form.additional_elements) {
//             let additional_elements = _.filter({...form.additional_elements}, function (additional_element) {
//                 if (additional_element.checked) {
//                     return additional_element.value;
//                 }
//             })
//             form.additional_elements = _.map(additional_elements, 'value');
//         }
//         if(form.search_types) {
//             let search_types = _.filter({...form.search_types}, function (search_type) {
//                 if (search_type.checked) {
//                     return search_type.value;
//                 }
//             })
//             form.search_types = _.map(search_types, 'value');
//         }
//         if (form.manufacturers) {
//             let manufacturers = _.filter({...form.manufacturers}, function (manufacture) {
//                 if (manufacture.checked) {
//                     return manufacture.name;
//                 }
//             })
//             form.manufacturers = _.map(manufacturers, 'name');
//         }
//         return form;
//     }

//     filtreGroups(groups) {
//         groups = _.map(groups, function (group) {
//             let response = {
//                 id: group.id,
//                 sections: {}
//             };
//             if (group.sections) {
//                 response.sections = _.map(group.sections, function (section) {
//                     let response = {
//                         id: section.id,
//                         checked: section.checked,
//                         // brands: []\
//                     };
//                     if (section.brands) {
//                         let brands = _.filter(section.brands, function (brand) {
//                             if (brand.checked) {
//                                 return brand.id;
//                             }
//                         })

//                         response.brands = _.map(brands, 'id');
//                     }

//                     return response;
//                 })
//             }
//             return response;
//         });
//         return groups;
//     }

//     modalClose() {
//         this.setState({catalogueModalShow: false});
//     }

//     closeChangesModal() {
//         this.setState({shouldBlockNavigation: false});
//     }

//     closeChangesModalDisregard() {
//         this.setState({shouldBlockNavigation: false}, () => {
//             this.unblock();
//             this.state.logins.map((login) => {
//                 delete login.preferences.groups;
//                 delete login.preferences.form;
//             });
//         });
//     }

//     closeNotifyApprove() {
//         let approveModal = this.state.approveModal;
//         approveModal.show = false;
//         this.setState({approveModal : approveModal})
//     }

//     render() {
//         return (
//             <div>
//                 {
//                     this.state.shouldBlockNavigation &&
//                         <ChangeSaveOrDiscardModal
//                             saveChanges={this.handleSaveClick.bind(this)}
//                             closeChangesModalDisregard={this.closeChangesModalDisregard.bind(this)}
//                             modalShow={this.state.shouldBlockNavigation}
//                             closeChangesModal={this.closeChangesModal.bind(this)}
//                         />
//                 }
//                 {
//                     this.state.loading &&
//                         <div className='sweet-loading'>
//                             <GridLoader
//                                 sizeUnit={"px"}
//                                 size={15}
//                                 color={'#0066b8'}
//                                 loading={this.state.loading}
//                             />
//                         </div>
//                 }
//                 <div className={"page-content admin-wrapper"}>
//                     <PageTitle title={"add customer"}/>
//                     <div className={"account-wrapper"}>

//                             <CustomContainer type="basic" name="customer details" width={"28%"}>
//                                 <AccountDetails account={this.state.account}
//                                                 setAccount={this.setAccount.bind(this)}
//                                                 setCurrentBranch={this.setCurrentBranch.bind(this)}
//                                                 setPriceLevels={this.setPriceLevels.bind(this)}
//                                                 savedPriceLevels={this.state.priceLevels}
//                                                 isSupplier={this.state.isSupplier}
//                                                 setIsSupplier={this.setIsSupplier.bind(this)}
//                                                 brands={this.state.brands}
//                                                 // setStateFromChild={this.setStateFromChild.bind(this)}
//                                                 errors={this.state.errors}
//                                                 removePriceLevelException={this.removePriceLevelException.bind(this)}
//                                                 setStateFromChild={ collection => this.setState(collection)}
//                                 />
                                
//                         </CustomContainer>
                        
                        
//                         {
//                             !this.state.isSupplier ?
//                                 <Fragment>
//                                     <CustomContainer type="basic" name="logins" width={"calc(100% - 56% - 40px)"}>
//                                         <Logins cataloguePreferences={{...this.state.cataloguePreferences}}
//                                                 logins={this.state.logins}
//                                                 setLogins={this.setLogins.bind(this)} isCustomer={true}/>
//                                     </CustomContainer>
//                                     <CustomContainer type="basic" name="stock preferences" width={"28%"}>
//                                         <StockPreferences branches={this.state.updatedBranches}
//                                                           branches2={this.state.branches2}
//                                                           accountId={this.state.accountId}

//                                                           setBranches={(branches) => {
//                                                               this.setState({updatedBranches: branches});
//                                                           }}

//                                                           currentBranch={this.state.currentBranch}
//                                                           customer="true"
//                                         />
//                                     </CustomContainer>
//                                 </Fragment> : <CustomStockFeed accountId={""} setBrands={this.setBrands.bind(this)}
//                                                                setStateFromChild={this.setStateFromChild.bind(this)}
//                                                                errors={this.state.errors}
//                                 />
//                         }
//                     </div>
//                     <CustomContainer type="basic" name="pricing" width={"28%"}>
//                             <PricingLevels savedPriceLevels={this.state.priceLevels}
//                                     removePriceLevelException={this.removePriceLevelException.bind(this)}
//                                     save={this.setPriceLevels.bind(this)}
//                                     setSPriceLevelId={this.setSPriceLevelId.bind(this)}
//                                     setBespokePrice={this.setBespokePrice.bind(this)}
//                                     errors={this.state.errors}/>
//                     </CustomContainer>
//                     <div className="account-buttons-wrapper">
//                         <CustomButton title={"Account Preferences"} type={"gray"}
//                                       onClick={() => this.setState({catalogueModalShow: true})}/>
//                         <NavLink to={"/admin/client/dashboard"}>
//                             <CustomButton title={"Cancel"} type={"lightgray"} margins={['ml-2', 'mr-2']}/>
//                         </NavLink>
//                         <CustomButton title={"Save"} type={"green"} onClick={this.handleSaveClick.bind(this)}/>
//                     </div>
//                 </div>
//                 <UserPreferences show={this.state.catalogueModalShow} preferences={this.getPreferences.bind(this)}
//                                  getSections={this.getSections.bind(this)} getBrands={this.getBrands.bind(this)}
//                                  currentElement={{name: '', title: "Catalogue Preferences for Account"}}
//                                  onHide={this.modalClose.bind(this)}
//                                  isCustomer={true}
//                                  updatePreferencesValues={this.updatePreferencesValues}/>
//                 <Notify type={"alert"}
//                         visible={this.state.approveModal.show}
//                         close={this.closeNotifyApprove.bind(this)}
//                         primaryText={this.state.approveModal.title}
//                         secondaryText={this.state.approveModal.message}
//                         actions={[
//                             {
//                                 name: "no",
//                                 action: this.saveAccount.bind(this, false),
//                                 type: "lightgray"
//                             },
//                             {
//                                 name: "yes",
//                                 action: this.saveAccount.bind(this, true),
//                                 type: "red"
//                             }
//                         ]}/>
//             </div>
//         )
//     }
// }