import React, { useEffect, useState } from 'react';
import "./SearchFilterBtn.css";

function SearchFilterBtn(props) {

    return <button className="vehicle__filter" onClick={props.handleClick}>
                <i className="fas fa-filter"></i>Filter
                {
                    props.filtersCount > 0 &&
                        <span className="badge badge--danger">{props.filtersCount}</span>
                }
            </button>
}

export default SearchFilterBtn;