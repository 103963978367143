import React, { Component } from "react";
// import "admin/administrator/GeneralClientOrBranchStatistics/index.css";
import _ from "lodash";

export default class Orders extends Component
{
    constructor(props)
    {
        super(props);
     }

    render()
    {
        return (
            <div className="flex mt-16">
                <div className="flex">
                    <div>Orders</div>
                </div>
                <div className="fw-700">{this.props.orders}</div> 
            </div>
        );
    }

}