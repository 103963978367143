import React, {useEffect, useState, Fragment} from "react";
import DeleteAccountMOdal from 'admin/administrator/DeleteAccount/DeleteAccountModal.js';
import StockPreferences from "admin/administrator/StockPreferences";
import CustomSortable from "admin/administrator/CustomSortable";
import BranchDetails from "admin/administrator/account/BranchDetails";
import UserPreferences from 'admin/administrator/UserPreferences.js';
import Logins from "admin/administrator/Logins";
import CustomButton from "admin/administrator/CustomButton";
import alertify from 'alertifyjs';
import axios from "axios1";
import _ from "lodash";
import PricingLevels from "admin/administrator/PricingLevels";
import ChangeSaveOrDiscardModal from 'admin/administrator/ChangeSaveOrDiscardModal/ChangeSaveOrDiscardModal';
import Notify from "components/Notify";
import Block from "components/Block";
import Loading from "admin/administrator/LoadingComponent/Loading.component";
import FormInput from "admin/administrator/FormInput/FormInput.component";

const EditBranch = props => {
    const [errors, setErrors] = useState({
        name: '',
        contact_name: '',
        address_1: '',
        town: '',
        phone: '',
        email: ''
    });
    const [approveModal, setApproveModal] = useState({
        show: false,
        name: '',
        title: '',
        message: 'Would you like to send an email containing login credentials to each new/updated login ?'
    });
    const [branch, setBranch] = useState({});
    const [branches, setBranches] = useState([]);
    const [branches2, setBranches2] = useState({});
    const [suppliers, setSuppliers] = useState([]);
    const [branchApi, setBranchApi] = useState([]);
    const [loading, setLoading] = useState(true);
    const [accountPreferences, setAccountPreferences] = useState([]);
    const [cataloguePreferences, setCataloguePreferences] = useState({});
    const [stockPrefBranches, setStockPrefBranches] = useState([]);
    const [stockPrefSuppliers, setStockPrefSuppliers] = useState([]);
    const [priceLevels, setPriceLevels] = useState([]);
    const [branchIds, setBranchIds] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [logins, setLogins] = useState([]);
    const [visibleNotify, setVisibleNotify] = useState(false);
    const [catalogueModalShow, setCatalogueModalShow] = useState(false);
    const [shouldBlockNavigation, setShouldBlockNavigation] = useState(false);
    const [priceLevelId, setPriceLevelId] = useState(null);
    
    var unblock = () => {};

    useEffect(() => {
        getValues();
    }, [])

    useEffect(() => {
        unblock();
    })

    useEffect(() => {
        let newStockPrefSuppliers = [];
        let newStockPrefBranches = [];
        let newBranches = [];
        let newSuppliers = [];

        _.each(branches, (element) => { 
            if(element.business_type_id == 3 ) {
                newBranches.push(element);
            }else {
                newSuppliers.push(element);
            }
            });

        _.each(branches2, (element) => {
            element.active = 1;
            if(element.business_type_id == 3 ) {
                newStockPrefBranches.push(element);
            }else {
                newStockPrefSuppliers.push(element);
            }
        });
        
        newStockPrefBranches = concatStockPreferences(newStockPrefBranches, newBranches);
        newStockPrefSuppliers = concatStockPreferences(newStockPrefSuppliers, newSuppliers);
        setStockPrefBranches(newStockPrefBranches);
        setStockPrefSuppliers(newStockPrefSuppliers);
        setLoading(false);
    }, [branches, branches2])

    useEffect(() => {
        let loginsWithPreferences = logins.filter((login) => {
            return (login.preferences && login.preferences.groups && login.preferences.form) ? login : null;
        })

        if (loginsWithPreferences.length > 0) {
            unblock = props.history.block(() => {
                setShouldBlockNavigation(true)
                return false;
            });
        }
    }, [logins])

    useEffect(() => {
        if (cataloguePreferences.hasOwnProperty('form') || cataloguePreferences.hasOwnProperty('groups')) {
            unblock = props.history.block(() => {
                setShouldBlockNavigation(true);
                return false;
            });
        }
    }, [cataloguePreferences])

    const setSPriceLevelIdFunc = value => { setPriceLevelId(value); setBranch({...branch, price_level_id: value}) };
    const setBespokePrice = value => setBranch({...branch, bespoke_price_id: value});
    
    const getValues = () => {
        setLoading(true);
        axios.get("catalogue/api-branches/" + props.match.params.id + "/edit")
            .then((response) => {
                if (response.data.success) {
                    let newBranchApi;
                    _.map(response.data.suppliers, function (element) {
                        if (element.branch_code !== "") {
                            newBranchApi = {supplier_id: element.id, code: element.branch_code};
                        }
                    })

                    let newLogins = response.data.logins.map((login) => {
                        login['password_confirmation'] = login.password;
                        return login;
                    })
                    setSuppliers(response.data.suppliers)
                    setBranch(response.data.branch)
                    setBranchApi(newBranchApi);
                    setAccountPreferences(response.data.accountPreferences)
                    setLogins(newLogins);
                    setPriceLevels(response.data.priceLevels)
                    setBranches2(response.data.branches2)
                    setBranches(response.data.branches)
                    setLoading(false);
                }
            })
    }

    const concatStockPreferences = (stockActiveElements, allStockElements) => {
        let result = [];
        stockActiveElements.forEach((element) => {
            let index = allStockElements.findIndex((item) => {
                return element.id == item.id;
            })
            if (index > -1) {
                allStockElements[index].active = 1;
                allStockElements[index].checked = true;
                result.push(allStockElements[index]);
                allStockElements.splice(index, 1);
            }
        });
        return result.concat(allStockElements);
    }

    const setSupplier = supplier_id => setBranchApi({supplier_id: supplier_id, code: ""});
    const setCode = code => setBranchApi({supplier_id: branchApi.supplier_id, code: code});

    const handleSaveClick = () => {
        let filter = logins ? _.filter(logins, (e) => { return !e.id || e.is_updated ? true : false; }) : [];
        if (filter.length) {
            setShouldBlockNavigation(false)
            setApproveModal({...approveModal, show: true, title: "You have added or updated logins for branch " + branch.name + "." })
        }else {
            updateBranchFunc(false);
        }
        props.history.block(() => true);
    }

    const updateBranchFunc = (status = false) => {
        setApproveModal({...approveModal, show: false});
        setLoading(true); 
        const newCataloguePreferences = JSON.parse(JSON.stringify(cataloguePreferences));
        const newBranch = branch;
        newBranch.price_level_id = newBranch.price_level_id ? newBranch.price_level_id : priceLevelId;
        let newLogins = JSON.parse(JSON.stringify(logins));

        if (newLogins && newLogins.length > 0) {
            newLogins = _.filter(newLogins, function (login) {
                if(typeof login.deleted_at == 'undefined' || (typeof login.deleted_at != 'undefined' && login.deleted_at == null )) {
                    if (login.preferences) {
                        if (login.preferences.groups) {
                            let groups = login.preferences.groups;
                            login.preferences.groups = filtreGroups(groups);
                        }
                        if (login.preferences.form) {
                            login.preferences.form = filtreForm(login.preferences.form);
                        }
                    }
                    return login;
                }
            })
        }
        // <---
        // For catalogue preferences --->
        newCataloguePreferences.groups = filtreGroups(newCataloguePreferences.groups)
        if (newCataloguePreferences.form) {
            newCataloguePreferences.form = filtreForm(newCataloguePreferences.form);
        }
        // <---

        // For stock preferences --->
        let newBranchesIds = getIdsArray(stockPrefBranches);
        let suppliersIds = getIdsArray(stockPrefSuppliers);
        newBranchesIds = newBranchesIds.concat(suppliersIds);
         // <---
        setShouldBlockNavigation(false);
        axios.put("catalogue/api-branches/" + props.match.params.id, {
            logins: newLogins,
            branch: newBranch,
            cataloguePreferences: newCataloguePreferences,
            notificationStatus: status,
            priceLevels: priceLevels,
            branchIds: newBranchesIds,
            price_level_id: priceLevelId
        }).then((response) => {
                let newErrors = response.data.errors ? response.data.errors : [];
                setErrors(newErrors);
                setLoading(false);
                if (response.data.success && !response.data.errors) {
                    alertify.success(response.data.message);
                    props.history.block(() => true);
                    props.history.push("/admin/accounts");
                } else {
                    let message = response.data && response.data.errors && response.data.errors.length > 0 ? response.data.errors.toString() : response.data.message;
                    alertify.error(message);
                }
            })
    }

    const getIdsArray = (elements) => {
        let result = [];
        _.map(elements, function(element) { 
            if(element.checked) {
                result.push(element.id)
            }
        })
        return result;
    }
    //<--- Helpers for Components End    --->


    //---> Pricing Levels Component functions Begin  <---
    const setPriceLevelsFromChild = priceLevel => {
        const newPriceLevels = priceLevels;

        let existingPriceLevel = _.find(newPriceLevels, function (element) {
            return (element.section_id == priceLevel.section_id && element.brand_id == priceLevel.brand_id) ? true : false;
        })

        if (existingPriceLevel) {
            existingPriceLevel.price_level_id = priceLevel.price_level_id;
            existingPriceLevel.price_level = priceLevel.price_level
        } else {
            newPriceLevels.push(priceLevel);
        }
        setPriceLevels([...newPriceLevels]);
    }
    
    const removePriceLevelException = priceLevel => {
        setLoading(true);
        if (priceLevel.id) {
            axios.delete("catalogue/price-level-exceptions/" + priceLevel.id)
                .then((response) => {
                    let newPriceLevels = priceLevels.filter((item) => {
                        return item.id != priceLevel.id;
                    })
                    setPriceLevels(newPriceLevels);
                    setLoading(false);
                })
        } else {
            let newPriceLevels = _.clone(priceLevels);
            _.remove(newPriceLevels, function (item) {
                return (item.brand_id == priceLevel.brand_id && item.section_id == priceLevel.section_id) ? true : false;
            });
            setLoading(false);
            setPriceLevels(newPriceLevels);
        }
    }

    //<--- Pricing Levels functions End    --->
    const defaultChange = event => setBranch({...branch, default_branch: event.target.checked});
    //<--- Stock Preferences Component functions End    --->

    //---> Preferences Component functions Begin  <---
    const getPreferences = async () => {
        let newCataloguePreferences = cataloguePreferences;
        if (!newCataloguePreferences.form) {
            await axios.get("catalogue/preferences?type=" + 'account' + '&id=' + branch.id)
                .then((response) => {
                    if (response.data.success) {
                        newCataloguePreferences = response.data.preferences;
                    }
                })
        }
        return newCataloguePreferences;
    }

    const getSections = group_id => axios.get("catalogue/preferences/sections?groupId=" + group_id + "&type=account&id=" + branch.id);
    const getBrands = section_id => axios.get("catalogue/preferences/brands?sectionId=" + section_id + "&type=account&id=" + branch.id);
    const updateBranch = (name, event) => {
        let newBranch = branch
        newBranch[name] = event.target.value;
        setBranch({...newBranch});
    }

    const filtreForm = form => {
        if (form.additional_elements) {
            let additional_elements = _.filter(form.additional_elements, function (additional_element) {
                if (additional_element.checked) {
                    return additional_element.value;
                }
            })
            form.additional_elements = _.map(additional_elements, 'value');
        }
        if(form.search_types) {
            let search_types = _.filter({...form.search_types}, function (search_type) {
                if (search_type.checked) {
                    return search_type.value;
                }
            })
            form.search_types = _.map(search_types, 'value');
        }
        if (form.manufacturers) {
            let manufacturers = _.filter(form.manufacturers, function (manufacture) {
                if (manufacture.checked) {
                    return manufacture.name;
                }
            })
            form.manufacturers = _.map(manufacturers, 'name');
        }
        return form;
    }

    const filtreGroups = groups => {
        groups = _.map(groups, function (group) {
            let response = {
                id: group.id,
                sections: {}
            };
            if (group.sections) {
                response.sections = _.map(group.sections, function (section) {
                    let response = {
                        id: section.id,
                        checked: section.checked,
                        // brands: []   
                    };
                    if (section.brands) {
                        let brands = _.filter(section.brands, function (brand) {
                            if (brand.checked) {
                                return brand.id;
                            }
                        })

                        response.brands = _.map(brands, 'id');
                    }

                    return response;
                })
            }
            return response;
        });
        return groups;
    }

    const handleCheckStockSupplier = (suppliers, name, event) => {
        let element = _.find(suppliers, { name: name });
        element.checked = event.target.checked;
        setStockPrefSuppliers([...suppliers]);
    }

    const closeNotifyApprove = () => setApproveModal({...approveModal, show: false});
    const deleteAccount = () => {
        setLoading(true);
        axios.post("catalogue/accounts/" + props.match.params.id ,{_method: 'DELETE'})
        .then((response) => {
            setLoading(false);
            if(response.data.success){
                alertify.success(response.data.message);
                props.history.push(`/admin/accounts`)
            }else{
                alertify.error("Error occured");
            }
        })
    }

    alertify.defaults = {
        notifier: {
            position: 'top-right',
            delay: 5
        }
    }
    return (
        <Fragment>
            <Loading loading={loading}/>
            {
                shouldBlockNavigation &&
                    <ChangeSaveOrDiscardModal
                        saveChanges={handleSaveClick}
                        closeChangesModalDisregard={() => {
                            logins.map((login) => {
                                delete login.preferences.groups;
                                delete login.preferences.form;
                            });
                            setShouldBlockNavigation(false)
                            props.history.block(() => true);
                        }}
                        modalShow={shouldBlockNavigation}
                        closeChangesModal={() => setShouldBlockNavigation(false)}/>
            }
            <div className={"page-content admin-wrapper"}>
                <div className="grid">
                    <div className="grid__row">
                        <div className="grid__column grid__column--3">
                            <BranchDetails setBranch={setBranch}
                                            branch={branch}
                                            errors={errors}
                                            isEdit={true}
                                            setErrors={setErrors}/>
                            <Block type="1" title="order recipient" style={{marginTop: "24px"}}>
                                <FormInput
                                    name='orders_email'
                                    type='email'
                                    placeholder='Email Address'
                                    error={errors.orders_email}
                                    value={branch.orders_email}
                                    onChange={updateBranch.bind(this, "orders_email")}/>
                            </Block>
                            {/* <Block type="1" title="stock feed" style={{marginTop: "24px"}}>
                                <FormInput
                                    name='branch_code'
                                    type='text'
                                    placeholder='Branch Code'
                                    error={errors.orders_email}
                                    value={branch.branch_code}
                                    onChange={updateBranch.bind(this, "branch_code")}/>
                            </Block> */}
                            <Block type="6" title="pricing levels" style={{marginTop: "24px"}}>
                                    <PricingLevels savedPriceLevels={priceLevels}
                                                    removePriceLevelException={removePriceLevelException}
                                                    save={setPriceLevelsFromChild}
                                                    setSPriceLevelId={setSPriceLevelIdFunc}
                                                    priceLevelId = {branch.price_level_id}
                                                    setBespokePrice={setBespokePrice}
                                                    bespokePriceId={branch.bespoke_price_id}
                                                    errors={errors}/>
                            </Block>
                        </div>
                        <div className="grid__column grid__column--6">
                            <Block type="1" title="users">
                            <Logins cataloguePreferences={cataloguePreferences}
                                        account={branch.id}
                                        logins={logins}
                                        isBranch={true}
                                        branches={{ ...stockPrefBranches }}
                                        setLogins={setLogins}/>
                            </Block>
                        </div>
                        <div className="grid__column grid__column--3">
                            <Block type="1" title="stock preferences">
                                <StockPreferences
                                    branches={stockPrefBranches}
                                    setBranches={ branches => setStockPrefBranches(branches)}
                                    defaultChange={defaultChange}
                                    currentBranch={branch}
                                    isBranch={true}
                                />
                                {
                                    stockPrefSuppliers && stockPrefSuppliers.length > 0 &&
                                        <Block type="4" title="Suppliers" style={{marginTop: "24px"}}>
                                            <CustomSortable
                                                items={stockPrefSuppliers}
                                                disabled={false}
                                                callBack={setStockPrefSuppliers}
                                                onChangeCheckBox={handleCheckStockSupplier.bind(this, stockPrefSuppliers)}/> 
                                        </Block>
                                }
                            </Block>
                        </div>
                    </div>
                </div>
                <div className="page-actions">
                    <div className="page-actions__action">
                        <CustomButton title={"Account Preferences"} type={"gray"} onClick={() => setCatalogueModalShow(true)}/>
                    </div>
                    <div className="page-actions__action">
                        <CustomButton title={"Save"} type={"green"} onClick={handleSaveClick}/>
                    </div>
                    <div className="page-actions__action">
                        <CustomButton title={"Delete"} type={"red"} onClick={() => setVisibleNotify(true)}/>
                    </div>
                </div>                    
            </div>

            <UserPreferences show={catalogueModalShow}
                            preferences={getPreferences}
                            getSections={getSections}
                            getBrands={getBrands}
                            currentElement={{
                                name: branch.name,
                                title: "Catalogue Preferences for Account - "
                            }}
                            isBranch={true}
                            branches={{ ...stockPrefBranches }}
                            onHide={ () => setCatalogueModalShow(false)}
                            updatePreferencesValues={ setCataloguePreferences }/>
            <DeleteAccountMOdal setShowModal={setShowModal}
                                    id={props.match.params.id}
                                    show={showModal}/>
            <Notify type={"danger"}
                    visible={visibleNotify}
                    close={() => setVisibleNotify(false)}
                    primaryText={"Are you sure?"}
                    secondaryText={`The branch "${branch.name}" will be permanently deleted.`}
                    actions={[
                        {
                            name: "cancel",
                            action: () => setVisibleNotify(false),
                            type: "lightgray"
                        },
                        {
                            name: "delete",
                            action: deleteAccount.bind(this),
                            type: "red"
                        }
                    ]}/>
            <Notify type={"warning"}
                    visible={approveModal.show}
                    close={closeNotifyApprove.bind(this)}
                    primaryText={approveModal.title}
                    secondaryText={approveModal.message}
                    actions={[
                        {
                            name: "no",
                            action: updateBranchFunc.bind(this, false),
                            type: "lightgray"
                        },
                        {
                            name: "yes",
                            action: updateBranchFunc.bind(this, true),
                            type: "blue"
                        }
                    ]}/>
        </Fragment>    
    );
}

export default EditBranch;